import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const UserItemLoading = () => {
  return (
    <SkeletonTheme baseColor="#525277" highlightColor="#7c7c96">
      <div className='pl-[1.6875rem] h-[1rem] w-full flex justify-between'> 
        <Skeleton width={200} height="1rem" borderRadius="0.3125rem" />
        <Skeleton width={80} height="1rem" borderRadius="0.3125rem" />
      </div>
    </SkeletonTheme>
  )
}