import { PrivilegeUser } from "@/app/data/objects"
import { PrivilegeDropdown } from "./PrivilegeDropdown"
import { ObjectPrivilege } from "@/types/enums";
import { useSelector } from "react-redux";
import { selectCurrentUserEmail } from "@/store/slices/auth.slice";
import { Tooltip } from "@/app/components/tooltip";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { openYesNoDialog } from "@/app/components/confirmationDialog/confirmationDialog.utils";
import { useDeleteObjectMutation, useRemoveObjectPrivilegeMutation, useRemoveObjectPrivilegeOptimisticMutation, useUpdateObjectPrivilegeMutation } from "@/store/api/object.endpoints";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";
import clsx from "clsx";
import Loading from "@/app/loading";
import { FaXmark } from "react-icons/fa6";

export const UserItem = ({
  user,
  users,
  objectId,
}: {
  user: PrivilegeUser
  users: PrivilegeUser[]
  objectId: string
}) => {
  const [removeObjectPrivilegeOptimisticApi] = useRemoveObjectPrivilegeOptimisticMutation();
  const [updateObjectPrivilegeApi, { isLoading: isUpdateLoading }] = useUpdateObjectPrivilegeMutation();

  const currentUserEmail = useSelector(selectCurrentUserEmail);
  const isOwner = user.privilege === ObjectPrivilege.Owner;
  const hasOnlyOneOwner = users.filter(user => user.privilege === ObjectPrivilege.Owner).length < 2;
  const canEditPrivilegies = users.find(user => user.email === currentUserEmail)?.privilege === ObjectPrivilege.Owner; // user can edit privilegies when he is an owner
  const dropdownDisabled = (isOwner && hasOnlyOneOwner) || !canEditPrivilegies; // dropdown is disabled when user item is an owner item and there is only one owner or user can't edit privilegies
  const isCurrentUserPrivilege = user.email === currentUserEmail;

  const disabledReason = isOwner && hasOnlyOneOwner ? 'You are the only owner. Add a second owner to change your privileges' : 'You do not have permission to edit privileges';

  async function handleDelete() {
    openYesNoDialog({
      title: 'Remove user',
      content: (
        <div className="break-words w-full">
          Are you sure you want to remove <span className="text-[#FBFBFD]">{user.email}</span>?
        </div>
      ),
      onButtonClick: () => {
        removeObjectPrivilegeOptimisticApi({
          objectId,
          email: user.email,
          privilege_version: user.privilege_version
        }).unwrap();

      }
    })
  }

  async function handlePrivilegeChange(privilege: ObjectPrivilege) {
    try {
      await updateObjectPrivilegeApi({
        objectId,
        email: user.email,
        privilege,
        privilege_version: user.privilege_version
      }).unwrap();
    } catch (error) {
      captureException(error);
      toast.error('Failed to update privilege');
    }
  }

  return (
    <div className="relative pl-[1.6875rem] h-[1.25rem] leading-[1.25rem] w-full flex justify-between text-[0.875rem] font-medium text-[#FBFBFD]">
      {!isCurrentUserPrivilege && (
        <button type="button" className="absolute w-5 h-5 left-[0.125rem] top-0 cursor-pointer flex items-center justify-center text-[#A5A6C9] hover:text-[#FBFBFD]" onClick={handleDelete}>
          <FaXmark  />
        </button>
      )}

      <div className="flex-1 min-w-0 truncate">
        {user.email}
      </div>

      <div className="min-w-[5rem] flex justify-end">
        {!isCurrentUserPrivilege && (
          <Tooltip tooltip={disabledReason} maxWidthCss='max-w-[20.625rem]' hidden={!dropdownDisabled}>
            <div className="relative">
              {isUpdateLoading && (
                <Loader />
              )}

              <div className={clsx({
                'pointer-events-none': dropdownDisabled,
                'hidden': isUpdateLoading,
              })}>
                <PrivilegeDropdown
                  disabled={dropdownDisabled}
                  value={user.privilege}
                  onChange={handlePrivilegeChange}
                />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export const Loader = () => {
  return (
    <Loading
      // className="absolute flex items-center justify-center w-full h-full"
      spinnerClassName="w-4 h-4 border-[0.125rem] border-[#C9CADE_transparent_#C9CADE_transparent]"
    />
  )
}