import { isEmpty } from "lodash";
import { getAddInWorksheet, insertText } from "../excel.utils";
import { toast } from "react-toastify";

// Define the type for each cell's value, background color, and text color
export type CellData = {
  value: string;
  backgroundColor?: string;
  textColor?: string;
};

export async function insertTextsInCellsV1_1(startCell: string, cellData: CellData[][]): Promise<void> {
  const values = cellData.map(row => row.map(cell => cell.value));
  return insertTextsInCellsV1(startCell, values);
}

export async function insertTextsInCellsV1(startCell: string, values: string[][]): Promise<void> {
  await Excel.run(async (context) => {
    if (isEmpty(values)) {
      return;
    }

    const worksheet = await getAddInWorksheet(context)

    // Get the starting row and column from the startCell
    const startRow = getRowIndex(startCell) - 1; // 0-based index for rows
    const startColumn = getColumnIndex(startCell) - 1; // 0-based index for columns

    // Get the range that fits the data size (number of rows and 1 column)
    const numRows = values.length; // Number of rows to insert
    const numCols = values[0].length; // Assuming values have consistent column size

    const range = worksheet.getRangeByIndexes(startRow, startColumn, numRows, numCols);

    // Set the values into the selected range
    range.values = values;

    // Optionally: Autofit the rows and columns
    // range.format.autofitColumns();
    // range.format.autofitRows();

    await context.sync(); // Sync to Excel
    console.log("Values inserted successfully!");
  });
}

// Helper function to convert an Excel column letter (e.g., "A", "B", "AA") to a zero-based index
function getColumnIndex(cell: string): number {
  const column = cell.replace(/[0-9]/g, ''); // Remove row numbers
  let columnIndex = 0;
  for (let i = 0; i < column.length; i++) {
    columnIndex = columnIndex * 26 + (column.charCodeAt(i) - "A".charCodeAt(0) + 1);
  }
  return columnIndex; // This is a 1-based index
}

// Helper function to extract the row number from the startCell (e.g., "A1" -> 1)
function getRowIndex(cell: string): number {
  return parseInt(cell.replace(/[^\d]/g, ''), 10);
}


export async function insertTextsInCellsV2(
  startCell: string, 
  cellData: CellData[][], 
  /** id of rows that we want to skip '1': true etc */
  skipRows: Map<number, boolean> = new Map()
): Promise<void> {
  await Excel.run(async (context) => {
    if (cellData.length === 0) {
      return;
    }

    const worksheet = await getAddInWorksheet(context); // Assuming this gets the correct worksheet

    // Get the starting row and column from the startCell
    const startRow = getRowIndex(startCell) - 1; // 0-based index for rows
    const startColumn = getColumnIndex(startCell) - 1; // 0-based index for columns

    const numRows = cellData.length;
    const numCols = cellData[0].length;

    // Save the current column width before inserting values
    // const columnRange = worksheet.getRangeByIndexes(0, startColumn, 1, numCols);
    // columnRange.format.load("columnWidth");
    // await context.sync();
    // const originalColumnWidth = columnRange.format.columnWidth;

    // Prepare arrays to hold the values
    // const values = [];
    
    // for (let i = 0; i < numRows; i++) {
    //   const rowValues = [];

    //   for (let j = 0; j < numCols; j++) {
    //     const cell = cellData[i][j];

    //     // Push the value for this cell
    //     rowValues.push(cell.value || "");
    //   }

    //   values.push(rowValues);
    // }

    // // Insert values into the selected range
    // const range = worksheet.getRangeByIndexes(startRow, startColumn, numRows, numCols);
    // range.values = values;

    // // Apply the background and text colors cell by cell
    // for (let i = 0; i < numRows; i++) {
    //   for (let j = 0; j < numCols; j++) {
    //     const cell = cellData[i][j];
    //     const cellRange = worksheet.getCell(startRow + i, startColumn + j); // Get individual cell

    //     // Set the background color and text color (if provided)
    //     if (cell.backgroundColor) {
    //       cellRange.format.fill.color = cell.backgroundColor;
    //     }else {
    //       cellRange.format.fill.clear();
    //     }

    //     if (cell.textColor) {
    //       cellRange.format.font.color = cell.textColor;
    //     } else {
    //       cellRange.format.font.color = '#000000' // Reset to default text color
    //     }
    //   }
    // }

    // Insert values and apply formatting cell by cell
    for (let i = 0; i < numRows; i++) {
      const realRowIndex = startRow + i + 1;
      // Skip updating this row if it's in the skipRows map
      if (skipRows.get(realRowIndex)) {
        continue;
      }

      for (let j = 0; j < numCols; j++) {
        // const cellAddress = `${String.fromCharCode(startColumn + j + 65)}${startRow + i + 1}`; // Calculate cell address like "A1"

        const cell = cellData[i][j];
        const cellRange = worksheet.getCell(startRow + i, startColumn + j); // Get individual cell

        // Set the cell value
        cellRange.values = [[cell.value || ""]];

        // Set the background color (if provided), or reset to default if not provided
        if (cell.backgroundColor) {
          cellRange.format.fill.color = cell.backgroundColor;
        } else {
          cellRange.format.fill.clear(); // Reset to default background color
        }

        // Set the text color (if provided), or reset to default if not provided
        if (cell.textColor) {
          cellRange.format.font.color = cell.textColor;
        } else {
          cellRange.format.font.color = "#000000"; // Default to black if no textColor is provided
        }
      }
    }

    // Optionally: Autofit rows (if needed)
    // range.format.autofitRows();

    // Restore the original column width
    // columnRange.format.columnWidth = originalColumnWidth;

    await context.sync(); // Sync to Excel
    console.log("Values and custom formatting applied successfully!");
  });
}