import { Bond } from "@/app/data/bondIndex"
import { RunColumn } from "../run.constants"
import { RunBond } from "@/app/data/runs"
import { PriceType, UIMode } from "@/types/types"
import { Side } from "@/app/data/api"
import { InferenceResultData } from "@/hooks/data/useSimpleInferenceData"
import { formatAmountOutstanding, priceOrString } from "@/utils/number.utils"
import { ONE_MILLION } from "@/constants"
import { getPriceAsymptoticSpectrumBackground } from "@/app/components/spectrum"
import { BidOfferSpreadType } from "@/types/enums"
import { isCalculatedBoSpread } from "../run.utils"

type RB = RunBond & Bond

type Result = {
  [key in RunColumn]: {
    getValue: (b: RB) => string | number;
    getPrintValue?: (b: RB) => string | number;
    getBgColor?: (b: RB) => string;
  } | null
}

export const getColumnsValueFormatter = ({
  data,
  uiMode,
}: {
  data: InferenceResultData<RB>
  uiMode: UIMode;
}): Result => {
  const isIgUiMode = uiMode === UIMode.InvestmentGrade;

  // getPercentileValue
  const getPercentileValue = (side: Side) => (b: Bond & RunBond) => {
    const value = side === Side.bid ? b.bidPercentile : b.offerPercentile;

    const priceType = uiMode === UIMode.InvestmentGrade ? PriceType.Spread : PriceType.Price

    const boSpread = b.bidOfferSpreadV2[uiMode];
    const v = isCalculatedBoSpread(boSpread)
      ? data[b.figi][side][priceType].data?.maxProbability || 0
      : value

    return v;
  }

  // Result
  const result: Result = {
    [RunColumn.Link]: null,
    [RunColumn.Alert]: null,
    [RunColumn.BidPxTime]: null,
    [RunColumn.BidSpdTime]: {
      getValue: (b) => data[b.figi][Side.bid].spread.currentTime as string,
    },
    [RunColumn.BYTMPrevTime]: null,
    [RunColumn.BidPxPrevTime]: null,
    [RunColumn.BidSpdPrevTime]: null,
    [RunColumn.Remove]: null,

    [RunColumn.Cusip]: {
      getValue: b => b.cusip,
    },
    [RunColumn.Ticker]: {
      getValue: b => b.ticker,
    },
    [RunColumn.Coupon]: {
      getValue: b => b['coupon'].toFixed(3),
    },
    [RunColumn.Maturity]: {
      getValue: b => b.maturity,
    },
    [RunColumn.SAndPRating]: {
      getValue: b => b.rating,
    },
    [RunColumn.Series]: {
      getValue: b => b.series,
    },
    [RunColumn.Tenor]: {
      getValue: b => {
        const priceType = isIgUiMode ? PriceType.Spread : PriceType.Price;
        const tenor = data[b.figi][Side.bid][priceType].tenor;
        return typeof tenor === 'number' ? `${tenor}Y` : '-';
      }
    },
    [RunColumn.AmountOutstanding]: {
      getValue: b => formatAmountOutstanding(b.amountOutstanding),
    },
    [RunColumn.BOSpd]: {
      getValue: (b) => {
        return b.bidOfferSpreadV2[uiMode] as any;
      },
      getPrintValue: (b) => {
        const spread = b.bidOfferSpreadV2[uiMode];
        if (spread.type === BidOfferSpreadType.Fixed) {
          return spread.value;
        } else if (spread.type === BidOfferSpreadType.Range) {
          return `range (${spread.min} - ${spread.max})`;
        } else {
          return spread.type;
        }
      }
    },
    [RunColumn.BidPercentile]: {
      getValue: getPercentileValue(Side.bid),
    },
    [RunColumn.OfferPercentile]: {
      getValue: getPercentileValue(Side.offer),
    },
    [RunColumn.BidSpd]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.current),
      getValue: b => data[b.figi][Side.bid].spread.currentString,
    },
    [RunColumn.OfferSpd]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.offer].spread.current),
      getValue: b => data[b.figi][Side.offer].spread.currentString,
    },
    [RunColumn.BYTM]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.current),
      getValue: b => data[b.figi][Side.bid].ytm.currentString,
    },
    [RunColumn.OfferYTM]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.offer].ytm.current),
      getValue: b => data[b.figi][Side.offer].ytm.currentString,
    },
    [RunColumn.BidPx]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.current),
      getValue: b => data[b.figi][Side.bid].price.currentString,
    },
    [RunColumn.OfferPx]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.offer].price.current),
      getValue: b => data[b.figi][Side.offer].price.currentString,
    },
    [RunColumn.BidSize]: {
      getPrintValue: b => b.bidSize,
      getValue: b => `${b.bidSize / ONE_MILLION}`,
    },
    [RunColumn.OfferSize]: {
      getPrintValue: b => b.offerSize,
      getValue: b => `${b.offerSize / ONE_MILLION}`,
    },
    [RunColumn.BidSpdChange]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.diff),
      getValue: b => data[b.figi][Side.bid].spread.diffString,
      getBgColor: b => getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].spread.diff, 'lightHex', 'spread'),
    },
    [RunColumn.BYTMChange]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.diff),
      getValue: b => data[b.figi][Side.bid].ytm.diffString,
      getBgColor: b => getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].ytm.diff, 'lightHex', 'ytm'),
    },
    [RunColumn.BidPxChange]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.diff),
      getValue: b => data[b.figi][Side.bid].price.diffString,
      getBgColor: b => getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].price.diff, 'lightHex', 'price'),
    },
    [RunColumn.BidSpdPrev]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.prev),
      getValue: b => data[b.figi][Side.bid].spread.prevString,
    },
    [RunColumn.BYTMPrev]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.prev),
      getValue: b => data[b.figi][Side.bid].ytm.prevString,
    },
    [RunColumn.BidPxPrev]: {
      getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.prev),
      getValue: b => data[b.figi][Side.bid].price.prevString,
    },
  }

  return result
}

