import { Tabs } from "@/app/components/tabs/tabs";
import { TimePeriod } from "../historicalChart.consants";
import { Checkbox, CheckboxUI } from "@/app/components/checkbox";

export const PercentileToggle = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: (a: boolean) => void;
}) => {
  return (
    <Tabs
      variant="row"
      style="dark"
    >
      <Tabs.Header className="w-full sm:w-auto">
        <Tabs.Tab value='percentile' active className="capitalize flex items-center gap-[0.625rem]" onClick={() => setActive(!active)}>
          <CheckboxUI checked={active} ariaLabel="percentile" />
          Percentile
        </Tabs.Tab>
      </Tabs.Header>
    </Tabs>
  )
}