import { openConfirmationDialog } from "@/app/components/confirmationDialog/confirmationDialog.utils";
import { getAddInWorksheet } from "@/excelApp/excel.utils";
import { useSelector } from "@/store";
import { excelAddInActions, selectExcelInCellEditingMode } from "@/store/slices/excel.slice";
import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// show warning when in excel cell editing mode
// we can not sync data with excel as excel prevents scripts from modifying cells when user edits a cell
export const useExcelIsInEditModeWarning = () => {
  const dispatch  =useDispatch();
  const isInEditMode = useSelector(selectExcelInCellEditingMode);

  // effects checks when user changes cell selection which means that user is no longer in edit mode
  useEffect(() => {
    async function handleSelectionChange(event: Excel.WorksheetSelectionChangedEventArgs) {
      await Excel.run(async (context) => {
        await context.sync();
        dispatch(excelAddInActions.setExcelInCellEditingMode(false));
      });
    }

    let registerResult: OfficeExtension.EventHandlerResult<Excel.WorksheetSelectionChangedEventArgs> | null = null;

    // register event handler
    Excel.run(async (context) => {
      const worksheet = await getAddInWorksheet(context)

      // Subscribe to the selection changed event
      registerResult = worksheet.onSelectionChanged.add(handleSelectionChange);
      await context.sync();
    });

    return () => {
      async function remove() {
        if (!registerResult) {
          return;
        }

        // The `RequestContext` used to create the event handler is needed to remove it.
        // In this example, `eventContext` is being used to keep track of that context.
        await Excel.run(registerResult.context, async (context) => {
          registerResult?.remove();
          await context.sync();
          
          registerResult = null;
        });
      }

      remove();
    }

  }, [dispatch])

  // show toast notification when in edit mode
  useEffect(() => {
    if (!isInEditMode) {
      return;
    }

    toast.warn((
      <>
        You are in cell editing mode. <br /> Deepmm data is not synced until you stop editing
        <br />
        <br />
        pressing ENTER or TAB or selecting another cell will exit editing mode
      </>
    ), {
      toastId: 'excel-in-edit-mode-warning',
      autoClose: false,
      position: 'top-left',
    })

    return () => {
      toast.dismiss('excel-in-edit-mode-warning');
    }
  }, [isInEditMode])
}