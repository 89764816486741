import { CheckboxUI } from "@/app/components/checkbox"
import { Tabs } from "@/app/components/tabs/tabs"
import { Side } from "@/app/data/api"

export const SideSelector = ({
  sides,
  setSides,
}: {
  sides: Record<Side, boolean>;
  setSides: (s: Record<Side, boolean>) => void;
}) => {
  return (
    <Tabs
      variant="row"
      style="dark"
    >
      <Tabs.Header className="w-full sm:w-auto">
        {[Side.bid, Side.dealer, Side.offer].map((side) => {
          return (
            <Tabs.Tab key={side} value={side} active className="capitalize flex items-center gap-[0.625rem]" onClick={() => {
              setSides({
                ...sides,
                [side]: !sides[side],
              })
            }}>
              <CheckboxUI checked={sides[side]} ariaLabel={side} />
              {side}
            </Tabs.Tab>
          )
        })}
      </Tabs.Header>
    </Tabs>
  )
}