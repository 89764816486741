import React from 'react';
import * as ReactDOM from 'react-dom/client';
import Site from './site';
import reportWebVitals from './reportWebVitals';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import { intiSentry } from './config/sentry.config';
import ErrorBoundary from './app/errors/errorBoundary';
import { initExcelApp } from './excelApp/utils/initExcelApp';

intiSentry();
initExcelApp();

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  //   <Site />
  // </React.StrictMode>
  <ErrorBoundary>
    <Site />
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
