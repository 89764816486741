import { useEffect, useMemo, useState } from "react";
import { AtsIndicator } from "../data/dataProvider";
import useBond from '../data/useBond';
import useTrades from "../data/useTrades";
import Loading from "../loading";
import BondSummary from "./bondSummary";
import TradeChart from './components/tradeChart/tradeChart';
import StyledListBox from "../components/listbox/styledListbox";
import RecentTrades from './recentTrades';
import { PriceType } from "@/types/types";
import { useUiMode } from "@hooks/useUiMode";
import { PriceTypeLabel } from "./bond.constants";
import {CTFChart } from "./components/ctfChart/ctfChart";
import { useQuantityDropdown } from "@/hooks/useQuantityDropdown";
import { useBondObject } from "../data/bonds";
import { useCreateBondObjectMutation } from "@/store/api/bond.endpoints";
import { ChartType } from "./bond.types";
import { ChartTypeSwitch } from "./components/chartTypeSwitcher";
import { HistoricalChart } from "./components/historicalChart/historicalChart";


const tradeCountOptions = [
  '10',
  '20',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '90',
  '100'
] as const;



const priceTypeOptions = Object.values(PriceType);

const Bond = () => {
  const bond = useBond();

  const { isIgUiMode, uiMode } = useUiMode();
  const [atsIndicator] = useState<AtsIndicator>(AtsIndicator.N);
  const [chartType, setChartType] =  useState<ChartType>(ChartType.EvenlySpaced)
  const [tradeCount, setTradeCount] = useState<(typeof tradeCountOptions)[number]>('20');
  const { quantity, setQuantity, quantityOptions, quantityNumber } = useQuantityDropdown();
  const [priceType, setPriceType] = useState(isIgUiMode ? PriceType.Spread : PriceType.Price);
  const xAxisKey = chartType === ChartType.EvenlySpaced ? 'Evenly Spaced Trades' : 'Date';

  // get the max possible trades upfront (negligible server load difference and simplifies UI logic)
  const maxTrades = useTrades(bond?.figi, Math.max(...tradeCountOptions.map(v => +v)));
  const trades = useMemo(() => maxTrades.slice(-tradeCount), [maxTrades, tradeCount]);

  // bond object
  const bondObjectApiRes = useBondObject({ figi: bond?.figi })
  const [createBondObjectApi] = useCreateBondObjectMutation();

  useEffect(() => {
    if (!bondObjectApiRes.objectMissing) return;
    if (!bond?.figi) return;
    createBondObjectApi({ figi: bond.figi });
  }, [bondObjectApiRes.objectMissing, bond?.figi, createBondObjectApi])

  useEffect(() => {
    setPriceType(isIgUiMode ? PriceType.Spread : PriceType.Price);
  }, [isIgUiMode])

  if (!bond) {
    return <Loading className="mt-[2.5rem]" />;
  }

  const isTimeChart = [ChartType.EvenlySpaced, ChartType.Recent].includes(chartType);

  return (
    <div className="flex flex-col gap-[0.625rem] items-center pt-[0.625rem] px-[0.625rem] w-full">
      <BondSummary bond={bond} links={false} quantity={quantityNumber} bondObjectApiRes={bondObjectApiRes} />
      <div className="bg-[#0A0B11] gap-[0.625rem] grid grid-cols-1 grid-rows-[auto,auto,auto] pb-[0.625rem] pt-[1.25rem] px-[0.625rem] rounded-[0.625rem] w-full lg:grid-cols-[auto,1fr] lg:grid-rows-[auto,1fr] lg:h-[calc(100vh-26.1rem)] lg:px-[0.9375rem]">
        <div className="flex gap-[0.625rem] items-center flex-wrap w-full flex-row lg:col-start-2 lg:pl-[0.625rem] lg:row-start-1">
          <div className="min-w-[40%] sm:min-w-0 sm:w-[242px] flex-1 sm:flex-initial">
            <StyledListBox value={tradeCount} selectValue={setTradeCount} values={tradeCountOptions} disabled={!isTimeChart} />
          </div>
          <div className="min-w-[40%] sm:min-w-0 sm:w-[242px] flex-1 sm:flex-initial">
            <StyledListBox value={quantity} selectValue={setQuantity} values={quantityOptions} />
          </div>
          <div className="min-w-[40%] sm:min-w-0 sm:w-[242px] flex-1 sm:flex-initial">
            <StyledListBox
              value={priceType}
              selectValue={setPriceType}
              values={priceTypeOptions}
              optionContent={(v) => PriceTypeLabel[v]}
              buttonContent={({ value }) => <span className="text-[#FBFBFD] truncate w-full">{PriceTypeLabel[value]}</span>}
            />
          </div>
        </div>
        <div className=" -mr-[0.3125rem] overflow-hidden lg:col-start-2  lg:-mr-[0.625rem] lg:row-start-2">
          <div className="lg:pl-[0.625rem]">
            <ChartTypeSwitch
              chartType={chartType}
              setChartType={setChartType}
            />
          </div>

          <div className="lg:h-[calc(100vh-34rem)] h-[70vw] flex flex-col">
            {chartType === ChartType.CTF && (
              <CTFChart
                bond={bond}
                quantity={quantityNumber}
                priceType={priceType}
              />
            )}

            {isTimeChart && (
              <TradeChart
                bond={bond}
                atsIndicator={atsIndicator}
                quantity={quantityNumber}
                trades={trades}
                xAxisKey={xAxisKey}
                priceType={priceType} />
            )}

            {chartType === ChartType.Historical && (
              <HistoricalChart 
                bond={bond}
                atsIndicator={atsIndicator}
                quantity={quantityNumber}
                priceType={priceType}
              />
            )}
          </div>
        </div>
        <div className="w-full lg:col-start-1 lg:overflow-y-auto lg:row-start-1 lg:row-end-3">
          <RecentTrades trades={trades} priceType={priceType} />
        </div>
      </div>
    </div>
  );
}

export default Bond;
