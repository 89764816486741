import { FunctionComponent } from "react";
import { Side } from '../../data/dataProvider';
import StyledListBox from './styledListbox';
import { Percentile, percentileOptions } from "@/constants";
import clsx from "clsx";

const ButtonContent = (props: {disabled: boolean, open: boolean, value: Percentile}, sideOptions?: { side: Side, multiline: boolean }) =>
  sideOptions
    ? <div className={clsx(sideOptions?.multiline ? "flex-col sm:flex-row sm:gap-[0.3125rem]" : "flex-row gap-[0.3125rem]", "flex items-start text-[0.875rem]")}>
        <span>{sideOptions.side.charAt(0).toUpperCase() + sideOptions.side.slice(1)}:</span>
        <span className="text-[#FBFBFD]">{props.value}%</span>
      </div>
    : <span className="text-[#FBFBFD]">{props.value}%</span>;

const PercentileListBox: FunctionComponent<{
  selectValue: (v: Percentile) => void,
  sideOptions?: { side: Side, multiline: boolean },
  value: Percentile
}> = ({ selectValue, sideOptions, value }) => {
  return (
    <StyledListBox
      buttonContent={props => ButtonContent(props, sideOptions) }
      selectValue={v => selectValue(v)}
      value={value}
      values={percentileOptions}
    />
  );
};

export default PercentileListBox;
