import { useEffect, useMemo, useState } from 'react';
import { PriceType } from '@/types/types';
import { AtsIndicator, Bond, Inference, Side, createInferenceKey, useDataContext } from '@/app/data/dataProvider';
import { getTimeArray } from '../historicalChart.utils';
import { TimePeriod } from '../historicalChart.consants';
import { InferenceRequestItem } from '@/app/data/api';
import { isEmpty } from 'lodash';
import { useInterval } from 'react-use';
import { useIntervalCounter } from '@/hooks/useIntervalCounter';
import { ONE_MINUTE } from '@/constants';


const getCacheKey = (figi: string, quantity: number, atsIndicator: AtsIndicator, priceType: PriceType, side: Side, time: string) => {
  return `${figi}-${quantity}-${atsIndicator}-${priceType}-${side}-${time}`;
}

let lastFigi: string | undefined;
let dataCache: Record<string, Inference> = {}

// hook does few things:
// 1. requests inferences for given bond, atsIndicator, quantity, priceType, timePeriod and sides. We check if we already have data in cache and request only missing data
// 2. save inferences which were requested to cache and dataCache
// 3. get inferences for current times from cache and returns them
// 4. dataCache is reset per bond. So when user switches between charts on the bond page inferences will not be requested but rather used from cache. We reset data cache when user visits different bond
export const useHistoricalChartInferences = ({
  figi,
  atsIndicator,
  quantity,
  priceType,
  bond,
  timePeriod,
  sides,
  settlementDate,
  customPeriod,
}: {
  figi: string | undefined
  atsIndicator: AtsIndicator
  quantity: number
  priceType: PriceType
  bond: Bond
  timePeriod: TimePeriod
  sides: Record<Side, boolean>
  settlementDate: Date
  customPeriod?: [number, number]
}) => {
  const minuteCounter = useIntervalCounter(ONE_MINUTE); // used to refetch data every minute if needed
  const { inferences: allInferences, send, isFinraHoliday } = useDataContext();
  const [inferenceCache, setInferenceCache] = useState<Record<string, Inference>>({});

  const times = useMemo(() => getTimeArray({ timePeriod, settlementDate, customPeriod, isFinraHoliday }), [timePeriod, isFinraHoliday, settlementDate, minuteCounter, customPeriod]); // minuteCounter is used to refetch data every minute if needed


  // request inferences for times 
  useEffect(() => {
    if (!bond.figi || !send) {
      return;
    }


    const requests: InferenceRequestItem[] = [];
    const base = { figi: bond.figi, ats_indicator: atsIndicator, quantity, rfq_label: priceType };


    Object.entries(sides).forEach(([side, active]) => {
      if (!active) {
        return;
      }

      // filter out times for which we already have data
      const sideMissingTimes = times.filter(time => !dataCache[getCacheKey(bond.figi, quantity, atsIndicator, priceType, side as Side, time)]);

      if (!isEmpty(sideMissingTimes)) {
        requests.push({ ...base, side, timestamp: sideMissingTimes })
      }
    })

    if (requests.length) {
      send({ inference: requests });
    }
  }, [bond.figi, atsIndicator, quantity, send, priceType, times, sides]);

  useEffect(() => {
    // reset data cache if bond changes
    if (bond.figi !== lastFigi) {
      lastFigi = bond.figi;
      dataCache = {};
    }
  }, [bond.figi])


  // save infereces to cache
  useEffect(() => {
    // get data from inferences to cache
    const figi = bond.figi


    const tempInferences: Record<string, Inference> = {};
    times.forEach(time => {
      [Side.bid, Side.dealer, Side.offer].forEach(side => {
        const key = getCacheKey(figi, quantity, atsIndicator, priceType, side, time);

        let inference: Inference | undefined = dataCache[key];

        if (!inference) {
          const inferences = (figi && allInferences[createInferenceKey(figi, atsIndicator, quantity, side, priceType)]) || [];
          inference = inferences.find(i => i.date.toISOString() === time);

          if (inference) {
            dataCache[key] = inference;
          }
        }

        if (inference) {
          tempInferences[key] = inference;
        }
      })
    })

    setInferenceCache(prev => ({ ...prev, ...tempInferences }))
  }, [allInferences, atsIndicator, figi, quantity, priceType, bond.figi, times]);

  // gret current time inferences from the cache
  const {
    bid: bidInferences,
    offer: offerInferences,
    dealer: dealerInferences,
  } = useMemo(() => {
    const figi = bond.figi;

    const bid: Inference[] = times.map(time => inferenceCache[getCacheKey(figi, quantity, atsIndicator, priceType, Side.bid, time)]).filter(Boolean);
    const offer: Inference[] = times.map(time => inferenceCache[getCacheKey(figi, quantity, atsIndicator, priceType, Side.offer, time)]).filter(Boolean);
    const dealer: Inference[] = times.map(time => inferenceCache[getCacheKey(figi, quantity, atsIndicator, priceType, Side.dealer, time)]).filter(Boolean);

    return { bid, offer, dealer };
  }, [inferenceCache, bond.figi, quantity, atsIndicator, times, priceType]);



  return {
    times,
    bidInferences,
    offerInferences,
    dealerInferences,
  };
}
