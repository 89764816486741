import { ChartDataset, Tooltip, TimeSeriesScale, ScriptableContext } from "chart.js";
import { Chart as ChartJS, ChartData, ChartOptions, CategoryScale, LinearScale, PointElement, LineElement, Filler } from "chart.js";
import { Chart as ReactChart } from "react-chartjs-2";
import { TimePeriod } from "../../historicalChart.consants";
import { Inference, Side } from "@/app/data/api";
import { useMemo } from "react";
import { useInferencesLinesData } from "../../hooks/useInferenceLinesData";
import { PriceType } from "@/types/types";
import { externalTooltipHandler } from "./chart.tooltip";
import 'chartjs-adapter-luxon';
import 'chartjs-scale-timestack';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  TimeSeriesScale,
  // CrosshairPlugin,
);

// Interaction.modes.interpolate = Interpolate;


const colors = {
  yColor: '#2E65A0',
  xColor: '#5D5F9D',
  offer: '#FB275A',
  bid: '#3FDA54',
  dealer: '#C9CADE',
}



export const Chart = ({
  timePeriod,
  sides,
  percentileActive,
  bidInferences,
  offerInferences,
  dealerInferences,
  priceType,
}: {
  timePeriod: TimePeriod;
  sides: Record<Side, boolean>;
  percentileActive: boolean;
  bidInferences: Inference[];
  offerInferences: Inference[];
  dealerInferences: Inference[];
  priceType: PriceType;
}) => {

  // convert inference data to chart data
  const { bid, offer, dealer } = useInferencesLinesData({
    bidInferences,
    dealerInferences,
    offerInferences,
    percentileActive,
    priceType,
  })


  const options = useMemo<ChartOptions<"line">>(() => {
    const options: ChartOptions<'line'> = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
      scales: {
        y: {
          border: {
            display: false,
          },
          grid: {
            display: true,
            color: colors.yColor,
            drawTicks: true,
            drawOnChartArea: true,
          },
          ticks: {
            color: colors.yColor,
            padding: 10,
          }
        },
        x: {
          // type: 'timeseries',
          type: 'timestack' as any,
          time: {
            displayFormats: {
              hour: timePeriod === TimePeriod['5D'] ? 'ccc t' : 't',
            },
          },
          border: {
            display: false,
          },
          grid: {
            display: true,
            color: colors.xColor,
            drawTicks: false,
            drawOnChartArea: true,
          },
          ticks: {
            color: colors.xColor,
            padding: 10,

          }
        }
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20
        }
      },
      plugins: {
        filler: {
          propagate: true,
        },
        legend: {
          display: false,
        },
        tooltip: {
          caretPadding: 10,
          displayColors: false,
          position: 'nearest',
          enabled: false,
          external: externalTooltipHandler
        }
      }
    }
    return options;
  }, [timePeriod])

  const data: ChartData<"line", any, unknown> = useMemo(() => {
    const showOnlyBid = sides[Side.bid] && !sides[Side.dealer] && !sides[Side.offer];
    const showOnlyDealer = sides[Side.dealer] && !sides[Side.bid] && !sides[Side.offer];
    const showOnlyOffer = sides[Side.offer] && !sides[Side.bid] && !sides[Side.dealer];


    const lineDataCommon: Partial<ChartDataset<"line", any>> = {
      borderWidth: 2,
      pointRadius: 0,
      pointHoverBackgroundColor: '#5D5F9D',
      pointHoverBorderWidth: 3,
      pointHoverRadius: 6,
    }

    const dashedDataCommon = {
      ...lineDataCommon,
      borderDash: [5, 5],
    }

    // lines
    const bidDataset: ChartDataset<"line", any> = {
      ...lineDataCommon,
      label: 'bid',
      data: bid.data,
      borderColor: colors.bid,
      hidden: !sides[Side.bid],
    }


    const dealerDataset: ChartDataset<"line", any> = {
      ...lineDataCommon,
      label: 'dealer',
      data: dealer.data,
      borderColor: colors.dealer,
      hidden: !sides[Side.dealer],
    }

    const offerDataset: ChartDataset<"line", any> = {
      ...lineDataCommon,
      label: 'offer',
      data: offer.data,
      borderColor: colors.offer,
      hidden: !sides[Side.offer],
    }

    // bid percentiles
    const bid5thPercentile: ChartDataset<"line", any> = {
      ...dashedDataCommon,
      label: 'bid 5th',
      data: bid.fifthPercentile,
      borderColor: colors.bid,

      hidden: !percentileActive || !sides[Side.bid],
      fill: 0,
      backgroundColor: getGradientFunc("rgba(34, 116, 45, 0.10)", "rgba(63, 218, 84, 0.20)"),
    }

    const bid95thPercentile: ChartDataset<"line", any> = {
      ...dashedDataCommon,
      label: 'bid 95th',
      data: bid.ninetyFifthPercentile,
      borderColor: colors.bid,

      hidden: !showOnlyBid,
      fill: 0,
      backgroundColor: getGradientFunc("rgba(63, 218, 84, 0.20)", "rgba(34, 116, 45, 0.10)"),
    }

    // offer percentiles
    const offer5thPercentile: ChartDataset<"line", any> = {
      ...dashedDataCommon,
      label: 'offer 5th',
      data: offer.fifthPercentile,
      borderColor: colors.offer,
      hidden: !percentileActive || !sides[Side.offer],
      fill: 2,
      backgroundColor: getGradientFunc("rgba(149, 23, 53, 0.60)", "rgba(245, 38, 88, 0.10)"),
    }

    const offer95thPercentile: ChartDataset<"line", any> = {
      ...dashedDataCommon,
      label: 'offer 95th',
      data: offer.ninetyFifthPercentile,
      borderColor: colors.offer,
      hidden: !showOnlyOffer,
      fill: 2,
      backgroundColor: getGradientFunc("rgba(245, 38, 88, 0.10)", "rgba(149, 23, 53, 0.60)",),
    }

    // dealer percentiles
    const dealer5thPercentile: ChartDataset<"line", any> = {
      ...dashedDataCommon,
      label: 'dealer 5th',
      data: dealer.fifthPercentile,
      borderColor: colors.dealer,
      hidden: !showOnlyDealer,
      fill: 1,
      backgroundColor: getGradientFunc("rgba(201, 202, 222, 0.10)", "rgba(201, 202, 222, 0.60)"),
    }

    const dealer95thPercentile: ChartDataset<"line", any> = {
      ...dashedDataCommon,
      label: 'dealer 95th',
      data: dealer.ninetyFifthPercentile,
      borderColor: colors.dealer,
      hidden: !showOnlyDealer,
      fill: 1,
      backgroundColor: getGradientFunc("rgba(201, 202, 222, 0.60)", "rgba(201, 202, 222, 0.10)",),
    }


    const data: ChartData<"line", any, unknown> = {
      datasets: [
        bidDataset,
        dealerDataset,
        offerDataset,
        bid5thPercentile,
        bid95thPercentile,
        dealer5thPercentile,
        dealer95thPercentile,
        offer5thPercentile,
        offer95thPercentile,
      ]
    }

    return data;
  }, [sides, percentileActive, bid, offer, dealer])

  return (
    <ReactChart
      key="historical-chart"
      type="line"
      options={options}
      data={data}
    />
  )
}


const getGradientFunc = (start: string, stop: string) => (context: ScriptableContext<"line">) => {
  const chart = context.chart;
  const { ctx, chartArea } = chart;

  if (!chartArea) {
    // This case happens on initial chart load
    return;
  }
  const gradientFill = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  gradientFill.addColorStop(0, start);
  gradientFill.addColorStop(1, stop);

  return gradientFill
}