import { dispatcher } from ".."
import { ObjectType, shareModalActions } from "../slices/shareModal.slice"

export const openShareModal = ({
  objectId,
  objectName,
  objectType,
}: {
  objectId: string;
  objectName: string
  objectType: ObjectType
}) => {
  dispatcher(shareModalActions.openShareModal({
    objectId,
    objectName,
    objectType,
  }))
}

export const closeShareModal = () => {
  dispatcher(shareModalActions.closeShareModal())
}