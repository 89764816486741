import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import useWindowDimensions from '../../../useWindowDimensions';
import { AtsIndicator, Bond, DataContext, Inference, Side, Trade } from '@app/data/dataProvider';
import useAllInferences from "@app/data/useAllInferences";
import Loading, { LoadingOverlay } from '@/app/loading';

// for Chart.js tree shaking
import {
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  Title,
  Tooltip
} from 'chart.js';
import { PriceType } from '@/types/types';
import { PriceTypeLabel, PriceTypeToQuantileKey } from '../../bond.constants';
import { formatPrice } from '@/utils/number.utils';
import { InferenceRequestItem } from '@app/data/api';
import { getQuantileIndexV2 } from '@app/data/data.utils';
ChartJS.register(
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  Title,
  Tooltip
);

const INFERENCE_COUNT = 400;
const REFRESH_CHECK_INTERVAL = 5 * 60 * 1000; // check every 5 minutes
const REFRESH_INTERVAL = 60 * 60 * 1000; // refresh every hour

// use the browser locale settings for date formatting
const dateFormatter = new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' });
const timeFormatter = new Intl.DateTimeFormat('default', { timeStyle: 'short' });
const formatDateMilliseconds = (m: number) => {
  const d = new Date(m);
  return `${dateFormatter.format(d)}, ${timeFormatter.format(d)}`;
};

const midAlpha = 0.75;
const lightAlpha = 0.5;
const borderAlpha = 0.6;
const bidColor = (alpha: number) => `rgba(50, 215, 75, ${alpha})`;
const dealerColor = (alpha: number) => `rgba(251, 251, 253, ${alpha})`;
const offerColor = (alpha: number) => `rgba(255, 45, 85, ${alpha})`;

const generalConfig = {
  fill: false,
  xAxisID: 'xAxis',
  yAxisID: 'yAxis'
} as const;

const lineConfig = {
  ...generalConfig,
  borderWidth: 1.5,
  order: 100,
  pointHitRadius: 10,
  pointHoverRadius: 8,
  pointRadius: 0,
  tension: Number(localStorage.getItem('tension') || '0.3'),
  type: 'line'
} as const;

const tradeConfig = {
  ...generalConfig,
  borderWidth: 1,
  pointHoverBorderWidth: 1,
  pointStyle: 'circle',
  type: 'scatter' as any
} as const;

const tradeModelConfig = {
  ...generalConfig,
  borderWidth: 1,
  pointHoverBorderWidth: 3,
  pointStyle: 'line',
  type: 'scatter' as any
} as const;




const getPointHitRadius = (radiusList: number[]) => radiusList.map(r => r * 1.1 + 2);

const inferenceSort = (a: Inference, b: Inference) => a.date.getTime() - b.date.getTime();

// <TradeChart />
const TradeChart: FunctionComponent<{ 
  bond: Bond, 
  atsIndicator: AtsIndicator, 
  priceType: PriceType,
  quantity: number, 
  trades: Trade[], 
  xAxisKey: 'Evenly Spaced Trades' | 'Date' 
}> = ({ bond, atsIndicator, priceType, quantity, trades, xAxisKey }) => {

  const { bidPercentile, offerPercentile, dealerPercentile, dispatch, send } = useContext(DataContext);
  const bidInferences = useAllInferences(bond.figi, atsIndicator, quantity, Side.bid, priceType);
  const dealerInferences = useAllInferences(bond.figi, atsIndicator, quantity, Side.dealer, priceType);
  const offerInferences = useAllInferences(bond.figi, atsIndicator, quantity, Side.offer, priceType);

  const windowDimensions = useWindowDimensions();
  const getPointRadius = useMemo(() => {
      const smallerDimension = Math.min(windowDimensions.width, windowDimensions.height);
      const mobile = 320, mobileMaxRadius = 16, desktop = 2000, desktopMaxRadius = 50;
      // scale linearly from mobileMaxPointSize to desktopMaxPointSize across smallerDimension from mobile to desktop
      const maxRadius = (Math.max(Math.min(smallerDimension, desktop), mobile) - mobile) / (desktop - mobile) * (desktopMaxRadius - mobileMaxRadius) + mobileMaxRadius;
      // scale linearly from mobile -> 2.5px up to desktop -> 4px
      const minRadius = maxRadius / desktopMaxRadius * 1.5 + 2.5;
      // area of trade reflects trade size
      return (trades: { quantity: number }[]) => trades.map(t => Math.sqrt((t.quantity / 5000000) * maxRadius * maxRadius) + minRadius)
    },
    [windowDimensions.width, windowDimensions.height]
  );

  // use separate xAxisKey data field because the chart does not refresh properly if only the parsing.xAxisKey gets changed
  const derivedTradeData = useMemo(() => trades.map((t, i) => {
    const side = t.side === 'B' ? Side.bid : t.side === 'D' ? Side.dealer : Side.offer;
    const percentile = t.side === 'B' ? bidPercentile : t.side === 'D' ? dealerPercentile : offerPercentile;
    const quantileIndex = getQuantileIndexV2(percentile, side, priceType);
    const key = PriceTypeToQuantileKey[priceType];

    return {
      ...t,
      'inference': t[key][quantileIndex],
      'Date': t.report_date.getTime(),
      'Evenly Spaced Trades': i
    }
  }).map(t => ({...t, xAxisKey: t[xAxisKey]}))
  , [bidPercentile, offerPercentile, dealerPercentile, trades, xAxisKey, priceType]);

  const addSteps = (arr: number[], end: number, steps: number) => {
    const [start] = arr.slice(-1);
    if (end > start) {
      const msStep = (end - start) / steps;
      for (let i = 1; i < steps; i++) {
        arr.push(start + i * msStep);
      }
    }
    arr.push(end);
    return arr;
  }

  const [loadTime, setLoadTime] = useState<Date>(new Date());
  const setIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    const refreshCheck = () => {
      if ((new Date()).getTime() - loadTime.getTime() > REFRESH_INTERVAL) {
        // if the page is sitting open for a long time,
        // periodically clear the cache...
        [Side.bid, Side.dealer, Side.offer].forEach(side =>
          void dispatch({
            type: 'clearCachedInferences',
            payload: {
              figi: bond.figi,
              atsIndicator,
              quantity,
              side,
              rfq_label: priceType,
            }
          })
        );
        // ...and recalculate the inference points for the chart
        setLoadTime(new Date());
      }
    }
    setIntervalRef.current = setInterval(refreshCheck, REFRESH_CHECK_INTERVAL);
    return () => {
      clearInterval(setIntervalRef.current || undefined);
    }
  }, [atsIndicator, bond.figi, dispatch, loadTime, quantity, setLoadTime, priceType]);

  const [tradeTimes, setTradeTimes] = useState<number[]>([]);
  useEffect(() => {
    const newTimes = trades.map(t => t.report_date.getTime());
    setTradeTimes(prev => prev.length === newTimes.length && prev.every((v, i) => v === newTimes[i]) ? prev : newTimes);
  }, [trades]);

  const [staticInferenceTimes, setStaticInferenceTimes] = useState<{'Evenly Spaced Trades': number[]; 'Date': number[]; }>({ 'Evenly Spaced Trades': [], 'Date': [] });
  const [tradeCountUsedForStaticInferenceTimes, setTradeCountUsedForStaticInferenceTimes] = useState(0);
  useEffect(() => {
    if (tradeTimes.length) {
      const firstTradeTime = tradeTimes[0];
      const lastTradeTime = tradeTimes.slice(-1)[0];
      const loadTimeMs = loadTime.getTime();
      const averageSteps = tradeTimes.length > 1
        ? Math.ceil(INFERENCE_COUNT / (tradeTimes.length - 1))
        : INFERENCE_COUNT / 2;
      const preStartTime = firstTradeTime === lastTradeTime
        ? firstTradeTime - Math.abs(loadTimeMs - firstTradeTime) // same width as between the only trade time and the load time
        : firstTradeTime - Math.abs((lastTradeTime - firstTradeTime) / 30); // about 1/30th of the width between first and last trades
      const pre = addSteps([preStartTime], firstTradeTime, Math.ceil(averageSteps * 0.4));
      const interEven = tradeTimes.reduce((a, c) => a.length ? addSteps(a, c, averageSteps) : [c], [] as number[]);
      const postEven = addSteps([lastTradeTime], loadTimeMs, Math.ceil(averageSteps * 0.4));
      const cleanTimes = (times: number[]) => Array.from(new Set(times.map(Math.round))).sort((a, b) => a - b).filter(v => v <= loadTimeMs);
      setStaticInferenceTimes({
        'Evenly Spaced Trades': cleanTimes([...pre, ...interEven, ...postEven]),
        'Date': cleanTimes(addSteps([...pre], loadTimeMs, INFERENCE_COUNT))
      });
      setTradeCountUsedForStaticInferenceTimes(tradeTimes.length);
    }
  }, [loadTime, tradeTimes]);

  // bid
  const [missingBid, setMissingBid] = useState<Set<string>>(new Set());
  useEffect(() => {
    const retrieved = new Set(bidInferences.map(i => i.date.toISOString()));
    const missing = new Set(staticInferenceTimes[xAxisKey].map(ms => (new Date(ms)).toISOString()).filter(d => !retrieved.has(d)));
    setMissingBid(prev => missing.size === prev.size && Array.from(missing).every(v => prev.has(v)) ? prev : missing);
  }, [bidInferences, staticInferenceTimes, xAxisKey]);
  const staticBidInferences = useMemo(() => {
    const timeSet = new Set(staticInferenceTimes[xAxisKey]);
    return bidInferences.filter(i => timeSet.has(i.date.getTime())).sort(inferenceSort);
  }, [bidInferences, staticInferenceTimes, xAxisKey]);
  const subscriptionBidInferences = useMemo(() => bidInferences.filter(i => i.date > loadTime).sort(inferenceSort), [loadTime, bidInferences]);
  // dealer
  const [missingDealer, setMissingDealer] = useState<Set<string>>(new Set());
  useEffect(() => {
    const retrieved = new Set(dealerInferences.map(i => i.date.toISOString()));
    const missing = new Set(staticInferenceTimes[xAxisKey].map(ms => (new Date(ms)).toISOString()).filter(d => !retrieved.has(d)));
    setMissingDealer(prev => missing.size === prev.size && Array.from(missing).every(v => prev.has(v)) ? prev : missing);
  }, [dealerInferences, staticInferenceTimes, xAxisKey]);
  const staticDealerInferences = useMemo(() => {
    const timeSet = new Set(staticInferenceTimes[xAxisKey]);
    return dealerInferences.filter(i => timeSet.has(i.date.getTime())).sort(inferenceSort);
  }, [dealerInferences, staticInferenceTimes, xAxisKey]);
  const subscriptionDealerInferences = useMemo(() => dealerInferences.filter(i => i.date > loadTime).sort(inferenceSort), [loadTime, dealerInferences]);
  // offer
  const [missingOffer, setMissingOffer] = useState<Set<string>>(new Set());
  useEffect(() => {
    const retrieved = new Set(offerInferences.map(i => i.date.toISOString()));
    const missing = new Set(staticInferenceTimes[xAxisKey].map(ms => (new Date(ms)).toISOString()).filter(d => !retrieved.has(d)));
    setMissingOffer(prev => missing.size === prev.size && Array.from(missing).every(v => prev.has(v)) ? prev : missing);
  }, [offerInferences, staticInferenceTimes, xAxisKey]);
  const staticOfferInferences = useMemo(() => {
    const timeSet = new Set(staticInferenceTimes[xAxisKey]);
    return offerInferences.filter(i => timeSet.has(i.date.getTime())).sort(inferenceSort);
  }, [offerInferences, staticInferenceTimes, xAxisKey]);
  const subscriptionOfferInferences = useMemo(() => offerInferences.filter(i => i.date > loadTime).sort(inferenceSort), [loadTime, offerInferences]);

  // retrieve missing inferences
  useEffect(() => {
    if (bond.figi && send) {
      const requests: InferenceRequestItem[] = [];
      const base = { figi: bond.figi, ats_indicator: atsIndicator, quantity, rfq_label: priceType };
      if (missingBid.size) {
        requests.push({ ...base, side: Side.bid, timestamp: Array.from(missingBid) });
      }
      if (missingDealer.size) {
        requests.push({ ...base, side: Side.dealer, timestamp: Array.from(missingDealer) });
      }
      if (missingOffer.size) {
        requests.push({ ...base, side: Side.offer, timestamp: Array.from(missingOffer) });
      }
      if (requests.length) {
        send({ inference: requests });
      }
    }
  }, [bond.figi, atsIndicator, quantity, send, missingBid, missingDealer, missingOffer, priceType]);

  const evenlySpacedPoints = useMemo(() => {
    const points: { ms: number, key: number }[] = [];
    if (staticInferenceTimes['Evenly Spaced Trades'].length) {
      points.push({ ms: staticInferenceTimes['Evenly Spaced Trades'][0], key: -0.4 });
    }
    if (tradeTimes.length) {
      tradeTimes.forEach((t, i) => void points.push({ ms: t, key: i }));
    }
    const maxInferenceMs = Math.max(
      subscriptionBidInferences.length ? subscriptionBidInferences.slice(-1)[0].date.getTime() : Number.MIN_SAFE_INTEGER,
      subscriptionDealerInferences.length ? subscriptionDealerInferences.slice(-1)[0].date.getTime() : Number.MIN_SAFE_INTEGER,
      subscriptionOfferInferences.length ? subscriptionOfferInferences.slice(-1)[0].date.getTime() : Number.MIN_SAFE_INTEGER,
      staticInferenceTimes['Evenly Spaced Trades'].length ? staticInferenceTimes['Evenly Spaced Trades'].slice(-1)[0] : Number.MIN_SAFE_INTEGER
    );
    if (maxInferenceMs > Number.MIN_SAFE_INTEGER) {
      points.push(({ ms: maxInferenceMs, key: tradeTimes.length - 0.6 }));
    }
    return points;
  }, [staticInferenceTimes, subscriptionBidInferences, subscriptionDealerInferences, subscriptionOfferInferences, tradeTimes]);
  const getKeyFromMilliseconds = (ms: number) => {
    if (xAxisKey === 'Evenly Spaced Trades') {
      if (evenlySpacedPoints.length < 2) return 0;
      if (ms <= evenlySpacedPoints[0].ms) return evenlySpacedPoints[0].key;
      if (ms >= evenlySpacedPoints.slice(-1)[0].ms) return evenlySpacedPoints.slice(-1)[0].key;
      const lower = evenlySpacedPoints.findLast(p => p.ms <= ms) || evenlySpacedPoints[0];
      const upper = evenlySpacedPoints.find(p => p.ms > ms) || evenlySpacedPoints.slice(-1)[0];
      return lower.ms === upper.ms
        ? upper.key
        : lower.key + (ms - lower.ms) / (upper.ms - lower.ms) * (upper.key - lower.key);
    } else {
      return ms;
    }
  };
  const getMillisecondsFromKey = (key: number) => {
    if (xAxisKey === 'Evenly Spaced Trades') {
      if (evenlySpacedPoints.length < 2) return loadTime.getTime();
      if (key <= evenlySpacedPoints[0].key) return evenlySpacedPoints[0].ms;
      if (key >= evenlySpacedPoints.slice(-1)[0].key) return evenlySpacedPoints.slice(-1)[0].ms;
      const lower = evenlySpacedPoints.findLast(p => p.key <= key) || evenlySpacedPoints[0];
      const upper = evenlySpacedPoints.find(p => p.key > key) || evenlySpacedPoints.slice(-1)[0];
      return lower.key === upper.key
        ? upper.ms
        : lower.ms + (key - lower.key) / (upper.key - lower.key) * (upper.ms - lower.ms);
    } else {
      return key;
    }
  }

  
  const { bidQuantileIndex, dealerQuantileIndex, offerQuantileIndex } = useMemo(() => {
    return {
      bidQuantileIndex: getQuantileIndexV2(bidPercentile, Side.bid, priceType),
      dealerQuantileIndex: getQuantileIndexV2(dealerPercentile, Side.dealer, priceType),
      offerQuantileIndex: getQuantileIndexV2(offerPercentile, Side.offer, priceType)
    }
  }, [bidPercentile, offerPercentile, dealerPercentile, priceType]);

  // use separate xAxisKey data field because the chart does not refresh properly if only the parsing.xAxisKey gets changed
  const derivedBidInferenceData = [...staticBidInferences, ...subscriptionBidInferences]
    .map(i => ({ xAxisKey: getKeyFromMilliseconds(i.date.getTime()), value: i[priceType]?.[bidQuantileIndex] }));
  const derivedDealerInferenceData = [...staticDealerInferences, ...subscriptionDealerInferences]
    .map(i => ({ xAxisKey: getKeyFromMilliseconds(i.date.getTime()), value: i[priceType]?.[dealerQuantileIndex] }));
  const derivedOfferInferenceData = [...staticOfferInferences, ...subscriptionOfferInferences]
    .map(i => ({ xAxisKey: getKeyFromMilliseconds(i.date.getTime()), value: i[priceType]?.[offerQuantileIndex] }));

  const bid = derivedTradeData.filter(t => t.side === 'B');
  const bidPointRadius = getPointRadius(bid);
  const bidPointHitRadius = getPointHitRadius(bidPointRadius);
  const dealer = derivedTradeData.filter(t => t.side === 'D');
  const dealerPointRadius = getPointRadius(dealer);
  const dealerPointHitRadius = getPointHitRadius(dealerPointRadius);
  const offer = derivedTradeData.filter(t => t.side === 'S');
  const offerPointRadius = getPointRadius(offer);
  const offerPointHitRadius = getPointHitRadius(offerPointRadius);

  const data = {
    datasets: [
      {
        ...lineConfig,
        backgroundColor: bidColor(midAlpha),
        borderColor: bidColor(midAlpha),
        data: derivedBidInferenceData as any,
        label: 'Bid Inference',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: 'value'
        }
      },
      {
        ...lineConfig,
        backgroundColor: dealerColor(midAlpha),
        borderColor: dealerColor(midAlpha),
        data: derivedDealerInferenceData as any,
        label: 'Dealer Inference',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: 'value'
        }
      },
      {
        ...lineConfig,
        backgroundColor: offerColor(midAlpha),
        borderColor: offerColor(midAlpha),
        data: derivedOfferInferenceData as any,
        label: 'Offer Inference',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: 'value'
        }
      },
      {
        ...tradeConfig,
        backgroundColor: bidColor(lightAlpha),
        borderColor: bidColor(borderAlpha),
        data: bid,
        pointRadius: bidPointRadius,
        pointHoverRadius: bidPointHitRadius,
        pointHitRadius: bidPointHitRadius,
        label: 'Bid Trade',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: priceType
        }
      },
      {
        ...tradeConfig,
        backgroundColor: dealerColor(lightAlpha),
        borderColor: dealerColor(borderAlpha),
        data: dealer,
        pointRadius: dealerPointRadius,
        pointHoverRadius: dealerPointHitRadius,
        pointHitRadius: dealerPointHitRadius,
        label: 'Dealer Trade',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: priceType
        }
      },
      {
        ...tradeConfig,
        backgroundColor: offerColor(lightAlpha),
        borderColor: offerColor(borderAlpha),
        data: offer,
        pointRadius: offerPointRadius,
        pointHoverRadius: offerPointHitRadius,
        pointHitRadius: offerPointHitRadius,
        label: 'Offer Trade',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: priceType
        }
      },
      {
        ...tradeModelConfig,
        backgroundColor: bidColor(borderAlpha),
        borderColor: bidColor(borderAlpha),
        data: bid,
        pointRadius: bidPointRadius,
        pointHoverRadius: bidPointHitRadius,
        pointHitRadius: bidPointHitRadius,
        label: 'Bid Inference',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: 'inference'
        }
      },
      {
        ...tradeModelConfig,
        backgroundColor: dealerColor(borderAlpha),
        borderColor: dealerColor(borderAlpha),
        data: dealer,
        pointRadius: dealerPointRadius,
        pointHoverRadius: dealerPointHitRadius,
        pointHitRadius: dealerPointHitRadius,
        label: 'Dealer Inference',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: 'inference'
        }
      },
      {
        ...tradeModelConfig,
        backgroundColor: offerColor(borderAlpha),
        borderColor: offerColor(borderAlpha),
        data: offer,
        pointRadius: offerPointRadius,
        pointHoverRadius: offerPointHitRadius,
        pointHitRadius: offerPointHitRadius,
        label: 'Offer Inference',
        parsing: {
          xAxisKey: 'xAxisKey',
          yAxisKey: 'inference'
        }
      }
    ]
  };

  const options = useMemo(() => {
    const options = {
      animation: false,
      interaction: {
        axis: 'xy',
        mode: 'nearest'
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (c: any) => {
              if (c.raw.report_date !== undefined) {
                // Trade data
                const priceTitle = PriceTypeLabel[priceType];
                return [
                  `${c.dataset.label.split(' ')[0]} Trade`,
                  `Quantity: ${c.raw.quantity/ 1000000}MM`,
                  `${priceTitle}: ${formatPrice(c.raw[priceType], priceType)}`,
                  `Inference: ${formatPrice(c.raw.inference, priceType)}`
                ];
              } else {
                // Model data
                return [
                  `${c.dataset.label}: ${formatPrice(c.parsed.y, priceType)}`
                ]
              }
            }
          },
          caretPadding: 10,
          displayColors: false,
          position: 'nearest'
        }
      },
      scales: {
        xAxis: {
          axis: 'x',
          grid: {
            color: '#5D5F9D'
          },
          ticks: {
            color: '#5D5F9D'
          },
          type: 'linear'
        },
        yAxis: {
          axis: 'y',
          grid: {
            color: '#2E65A0'
          },
          ticks: {
            color: '#2E65A0'
          }
        }
      }
    } as const;
    return options;
  }, [priceType])

  const minXAxisKey = Math.min(
    derivedBidInferenceData.length ? derivedBidInferenceData[0].xAxisKey : Number.MAX_SAFE_INTEGER,
    derivedDealerInferenceData.length ? derivedDealerInferenceData[0].xAxisKey : Number.MAX_SAFE_INTEGER,
    derivedOfferInferenceData.length ? derivedOfferInferenceData[0].xAxisKey : Number.MAX_SAFE_INTEGER,
    derivedTradeData.length ? derivedTradeData[0].xAxisKey : Number.MAX_SAFE_INTEGER);
  const maxXAxisKey = Math.max(
    derivedBidInferenceData.length ? derivedBidInferenceData.slice(-1)[0].xAxisKey : Number.MIN_SAFE_INTEGER,
    derivedDealerInferenceData.length ? derivedDealerInferenceData.slice(-1)[0].xAxisKey : Number.MIN_SAFE_INTEGER,
    derivedOfferInferenceData.length ? derivedOfferInferenceData.slice(-1)[0].xAxisKey : Number.MIN_SAFE_INTEGER,
    derivedTradeData.length ? derivedTradeData.slice(-1)[0].xAxisKey : Number.MIN_SAFE_INTEGER);

  const inferencesReady = useMemo(() =>
    (inferences: Inference[]) => {
      if (!trades || !trades.length || tradeCountUsedForStaticInferenceTimes !== trades.length) {
        return false;
      }
      const earliestTradeReportDate = trades[0].report_date.getTime();
      const requiredTimes = staticInferenceTimes[xAxisKey].filter(v => v >= earliestTradeReportDate);
      if (!requiredTimes || !requiredTimes.length) {
        return false;
      }
      const retrievedSet = new Set(inferences.map(i => i.date.getTime()));
      return requiredTimes.every(t => retrievedSet.has(t))
    }
  , [staticInferenceTimes, tradeCountUsedForStaticInferenceTimes, trades, xAxisKey]);

  // wait to load until we have trades, a non-zero time range to show
  if (!derivedTradeData || !derivedTradeData.length || minXAxisKey >= maxXAxisKey ||
    !inferencesReady(bidInferences) ||
    !inferencesReady(dealerInferences) ||
    !inferencesReady(offerInferences)
  ) {
    return (
      <div className="relative h-full">
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Line data={data} options={{
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          ...options.plugins.tooltip,
          callbacks: {
            ...options.plugins.tooltip.callbacks,
            title: (c: any) => formatDateMilliseconds(getMillisecondsFromKey(c[0].raw.xAxisKey))
          }
        }
      },
      scales: {
        ...options.scales,
        xAxis: {
          ...options.scales.xAxis,
          min: minXAxisKey,
          max: maxXAxisKey,
          ticks: {
            ...options.scales.xAxis.ticks,
            callback: (v) => {
              return formatDateMilliseconds(getMillisecondsFromKey(+v))
            },
            // allow up to 100 ticks
            stepSize: (maxXAxisKey - minXAxisKey) / 100
          }
        }
      }
    }} />
  )
};

export default TradeChart;
