import { MouseEvent, MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import scrollIntoView from 'scroll-into-view'
import { waitForElement } from '../../alert/alert.utils';

import { TableSortPriority } from './tableSortPriority';
import { Sort, SortItem, useTableSort } from './hooks/useTableSort';
import { FaArrowDownShortWide, FaArrowDownWideShort } from 'react-icons/fa6';
import { SortableList } from '../dnd/sortableList';
import { DraggableItem } from '../dnd/draggableItem';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import Loading, { Overlay } from '@/app/loading';
import { isEmpty } from 'lodash';
import { useTablePagination } from './hooks/useTablePagination';
import { getInitialSort } from './table.utils';


export type TableRefData<T, Id = string> = {
  pageSize: number;
  sortedItems: T[];
  getPrintData: () => {
    headers: Id[];
    rows: {
      data: Record<string, string | number | undefined>; // { [Id]: value } -> Id is a column unique id
      item: T;
    }[];
  }
  goToItem: (item: T, scrollToItem?: boolean, navigateOpts?: NavigateOptions | undefined) => void;
  scrollToTop: () => void;
  resetSort: () => void;
  setSort: (sort: SortItem<Id>[]) => void;
}

export type PrintColumn<T, Id = string> = {
  id: Id,
  getValue: (item: T) => string | number | undefined;
}

export type CommonColumnProperties<T, Id = string> = {
  id: Id; // unique id for this column
  Header: (sort: Sort, visibleItems: T[]) => ReactNode; // renders header
  onCellClick?: (e: React.MouseEvent<HTMLTableCellElement>, a: T) => void; // optional onClick handler for cells in this column
  onHeaderClick?: (e: React.MouseEvent<HTMLTableCellElement>, visibleItems: T[]) => void; // optional onClick handler for column header
  sort?: (a: T, b: T) => number; // sort function for column, if specified column becomes sortable
  // TOOD: return object instead of `selected` - needs more refactoring
  tdCss?: (selected: boolean, data: { item: T }) => string; // optional css applied to each td
  thCss?: (sort: Sort) => string; // optional css applied to the th
  printColumns?: PrintColumn<T, Id>[]; // additional columns which will be printed if this column is printed
  printable?: boolean; // column is printable by default, unless specified otherwise
  draggable?: boolean; // column is draggable by default when `columnsOrder` is provided. Use `false` to disable dragging
};

export type Column<T, Id = string> = CommonColumnProperties<T, Id> & {
  getValue?: (item: T) => string | number; // gets cell value from item. Is used as fallback for print data if getPrintValue is not defined
  getPrintValue?: (item: T) => string | number | undefined; // gets cell value for print data
  Cell: (a: T, data: {
    selected: boolean;
    value: string | number; // value returned by `getValue` function
  }) => ReactNode; // renders cell for a particular item
}


export type TableProps<T, Id = string> = {
  columns: Column<T, Id>[];
  columnsOrder?: Id[];
  generateItemKey: (item: T) => string;
  getColumnFilterLabel?: (columnName: Id) => ReactNode;
  initialSortColumn?: Id;
  initialSortDirection?: 'ascending' | 'descending';
  items: T[];
  loading?: boolean;
  noDataMessage?: string;
  onColumnsOrderChange?: (items: Column<T, Id>[]) => void
  onRowClick?: (e: React.MouseEvent<HTMLTableRowElement>, item: T) => void; // optional onClick handler for rows
  scrollerCss?: string;
  scrollerRef?: MutableRefObject<HTMLDivElement | null>
  selectedItem?: T | null;
  sortPriorityClassName?: string,
  tableCss?: string; // optional css applied to table
  tableName: string; // name unique to the table on the page it is shown
  tableRef?: MutableRefObject<TableRefData<T, Id> | null>;
  theadCss?: string; // optional css applied to the thead
  trCss?: (a: T, selected: boolean) => string; // optional css applied to each tr
  useSearchPagination?: boolean;
  simplePagination?: boolean; // shows simplified pagination version with only next and previous buttons (no first/last page buttons)
  defaultPageSize?: number;
  onSortSave?: (sort: SortItem<Id>[]) => void;
  initialSort?: SortItem<Id>[];
};
const Table = <T, Id extends string = string>({ 
  columns, 
  columnsOrder, 
  generateItemKey, 
  getColumnFilterLabel, 
  initialSortColumn, 
  initialSortDirection, 
  items, 
  loading, 
  noDataMessage = 'No data',
  onColumnsOrderChange, 
  onRowClick, 
  scrollerCss, 
  scrollerRef, 
  selectedItem, 
  sortPriorityClassName,
  tableCss, 
  tableName, 
  tableRef, 
  theadCss, 
  trCss, 
  simplePagination,
  defaultPageSize,
  useSearchPagination,
  onSortSave,
  initialSort,
}: TableProps<T, Id>) => {
  const _initialSort = getInitialSort(initialSort, initialSortColumn, initialSortDirection);

  const {
    sort,
    sortedItems,
    toggleSort,
    removeColumnSort,
    resetSort,
    setSort,
  } = useTableSort<T, Id>({ 
    columns, 
    generateItemKey, 
    items,
    initialSort: _initialSort,
  });

  // columns sorting
  const sortedColumns  = useMemo(() => {
    if (!columnsOrder) {
      return columns;
    }

    const order = columnsOrder;

    return columns
      .filter(c => order.includes(c.id))
      .sort((a, b) => {
        const aIndex = order.indexOf(a.id);
        const bIndex = order.indexOf(b.id);

        return aIndex - bIndex;
      })
  }, [columnsOrder, columns])


  const localScrollerRef = useRef<HTMLDivElement | null>(null)

  const {
    start,
    pageSize,
    getStart,
    goToPage,
    goToNextPage,
    goToPrevPage,
  } = useTablePagination({
    itemsCount: items.length,
    tableName,
    useUrl: useSearchPagination,
    defaultPageSize,
  })
  
  
  
  const sortableColumns = useMemo(() => {
    return columns.filter(c => c.sort !== undefined);
  }, [columns])
  
  const visibleItems = sortedItems.slice(start, start + pageSize);
  
  async function goToItem(item: T, scrollToItem = false, navigateOpts?: NavigateOptions | undefined) {
    // finds on which page item is located and goes to that page
    const index = sortedItems.findIndex(i => generateItemKey(i) === generateItemKey(item));
    if (index >= 0) {
      const page = Math.floor(index / pageSize) * pageSize;

      if (page !== start) {
        goToPage(page, navigateOpts);
      }

      if (scrollToItem) {
        const key = generateItemKey(item);

        await waitForElement(`[data-row-id="${key}"]`, 5, 30); // it takes time for the table to open and render page with the item

        const row = document.querySelector(`[data-row-id="${key}"]`);
        if (!row) {
          return;
        }

        scrollIntoView(row as HTMLElement, {
          time: 300,
          align: {
            topOffset: -50,
            left: 0,
          }
        })
      }

    }
  }

  function scrollToTop() {
    const scroller = localScrollerRef?.current;
    if (scroller) {
      scroller.scrollTop = 0;
    }
  }

  function getPrintData() {
    const headers: Id[] = [];

    sortedColumns.forEach((c) => {
      // column is printable by default, unless specified otherwise
      if (c.printable === false) {
        return;
      }

      headers.push(c.id);

      // add additional columns if needed
      if (c.printColumns) {
        c.printColumns.forEach(pc => {
          headers.push(pc.id);
        });
      }

    });

    // prepare data rows
    const rows = sortedItems.map(i => {

      const valuesMap = sortedColumns.reduce((acc, c) => {
        if (c.printable === false) {
          return acc;
        }

        const value = c.getPrintValue ? c.getPrintValue(i) : c.getValue ? c.getValue(i) : '';
        acc[c.id] = value;

        if (c.printColumns) {
          c.printColumns.forEach(pc => {
            acc[pc.id] = pc.getValue(i);
          })
        }

        return acc;
      }, {} as Record<string, string | number | undefined>)

      return {
        item: i,
        data: valuesMap
      }
    })

    return {
      headers,
      rows,
    }
  }


  // expose table data to parent component
  if (tableRef) {
    tableRef.current = {
      pageSize,
      sortedItems,
      goToItem,
      scrollToTop,
      resetSort,
      setSort,
      getPrintData,
    };
  }

  return (
    <div className="flex flex-col gap-[0.875rem] max-h-full max-w-fit">
      {sortableColumns.length > 1 && (
        <TableSortPriority
          className={clsx('flex-1', sortPriorityClassName)}
          sort={sort}
          onRemove={removeColumnSort}
          onSort={setSort}
          getColumnFilterLabel={getColumnFilterLabel}
          onSortSave={onSortSave}
          initialSort={_initialSort}
        />
      )}
    
      <div
        className={clsx('max-h-full max-w-fit relative', scrollerCss, {
          'overflow-hidden': loading,
          'overflow-auto': !loading,
        })}
        ref={ref => {
          localScrollerRef.current = ref;
          if (scrollerRef) {
            scrollerRef.current = ref;
          }
        }}
      >
        {loading && (
          <div className='sticky left-0 h-0 z-[11] top-1/2'>
            <Loading spinnerClassName='border-[#C9CADE98_transparent_#C9CADE98_transparent]' />
          </div>
        )}
        <div className="max-h-full w-fit relative">
          {loading && <Overlay />}
          <table
            className={clsx(
              "h-full w-fit", // height must be specified to allow td contents to be h-full, w-fit keeps the table from stretching horizontally
              tableCss,
            )}
          >
            <thead className={theadCss || ''}>
              <SortableList
                items={sortedColumns}
                strategy={horizontalListSortingStrategy}
                renderItem={({ Header, id }) => {
                  const columnSortData = sort.find(s => s.column === id);
                  const sortDirection = columnSortData ? columnSortData.direction : 'none';
                  return Header(sortDirection, visibleItems)
                }}
                onChange={onColumnsOrderChange}
              >

                <tr className="pt-[1rem]">
                  {sortedColumns.map((c, idx) => {
                    const draggable = columnsOrder ? (c.draggable ?? true) : false;
                    const columnSortData = sort.find(s => s.column === c.id);
                    const sortDirection = columnSortData ? columnSortData.direction : 'none';
                    const thCss = clsx(
                      'h-full p-0' /* allow td contents to be h-full, remove any cell padding */,
                      c.thCss?.(sortDirection)
                    )

                    const handleClick = (e: MouseEvent<HTMLTableCellElement>) => {
                      if (c.sort) {
                        toggleSort(c.id);
                      }
                      if (c.onHeaderClick) {
                        c.onHeaderClick(e, visibleItems);
                      }
                    }

                    const thProps = {
                      className: thCss,
                      onClick: handleClick,
                      children: c.Header(sortDirection, visibleItems),
                    }
                    

                    if (!draggable) {
                      return (
                        <th key={c.id} {...thProps} />
                      )
                    }

                    return (
                      <DraggableItem key={c.id} id={c.id}>
                        {({ setNodeRef, listeners, style, attributes }) => (
                          <th
                            ref={setNodeRef}
                            style={style} 
                            {...listeners} 
                            {...attributes}
                            {...thProps}
                          />
                        )}
                      </DraggableItem>
                    )
                  })}
                </tr>
              </SortableList>
            </thead>
            <tbody>
              {visibleItems.map((item) => {
                const trStyles = clsx("group group/tr", trCss?.(item, item === selectedItem))
                return (
                  <tr
                    className={trStyles}
                    key={generateItemKey(item)}
                    onClick={onRowClick ? e => onRowClick(e, item) : undefined}
                  >
                    {sortedColumns.map(c =>
                      <td
                        key={c.id}
                        className={clsx('h-full p-0' /* allow td contents to be h-full, remove any cell padding */, c.tdCss ? c.tdCss(item === selectedItem, { item }) : '')}
                        onClick={e => {
                          if (c.onCellClick) {
                            c.onCellClick(e, item);
                          }
                        }}
                      >{
                          c.Cell(item, {
                            selected: item === selectedItem,
                            value: c.getValue ? c.getValue(item) : (undefined as any),
                          })
                        }</td>
                    )}
                  </tr>
                )
              })}

              {isEmpty(visibleItems) && (
                <tr>
                  <td colSpan={columns.length} className="text-center p-8 text-[#8183B3]">{noDataMessage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {sortedItems.length > pageSize && (
        <div className="flex flex-row gap-[0.625rem] items-center justify-center w-full whitespace-nowrap">
          {!simplePagination && (
            <button
              className={clsx("p-[0.625rem] rounded-full", start <= 0 ? 'bg-[#8183B3]/[0.1]' : 'bg-[#5D5F9D]')}
              disabled={start <= 0}
              onClick={() => goToPage(0)}
            >
              <ChevronDoubleLeftIcon className={clsx("h-[1rem] w-[1rem]", start <= 0 ? 'text-[#7D7D82]' : '')} />
            </button>
          )}
          <button
            className={clsx("p-[0.625rem] rounded-full", start <= 0 ? 'bg-[#8183B3]/[0.1]' : 'bg-[#5D5F9D]')}
            disabled={start <= 0}
            onClick={goToPrevPage}
          >
            <ChevronLeftIcon className={clsx("h-[1rem] w-[1rem]", start <= 0 ? 'text-[#7D7D82]' : '')} />
          </button>
          <div>
            {start + 1} - {Math.min(start + pageSize, sortedItems.length)} of {sortedItems.length}
          </div>
          <button
            className={clsx("p-[0.625rem] rounded-full", start + pageSize >= sortedItems.length ? 'bg-[#8183B3]/[0.1]' : 'bg-[#5D5F9D]')}
            disabled={start + pageSize >= sortedItems.length}
            onClick={goToNextPage}
          >
            <ChevronRightIcon className={clsx("h-[1rem] w-[1rem]", start + pageSize >= sortedItems.length ? 'text-[#7D7D82]' : '')} />
          </button>
          {!simplePagination && (
            <button
              className={clsx("p-[0.625rem] rounded-full", start + pageSize >= sortedItems.length ? 'bg-[#8183B3]/[0.1]' : 'bg-[#5D5F9D]')}
              disabled={start + pageSize >= sortedItems.length}
              onClick={() => goToPage(getStart(sortedItems.length))}
            >
              <ChevronDoubleRightIcon className={clsx("h-[1rem] w-[1rem]", start + pageSize >= sortedItems.length ? 'text-[#7D7D82]' : '')} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export const TableSortIcon = ({ sort }: { sort?: Sort }) => {
  if (!sort) {
    return;
  }

  const DirectionIcon = sort === 'ascending' ? FaArrowDownShortWide : FaArrowDownWideShort

  return (
    <DirectionIcon
      className={clsx(" text-[#FBFBFD] text-[0.8125rem]", {
        'invisible': sort === 'none',
      })}
    />
  )
}

export { type Sort } from './hooks/useTableSort';

export default Table;
