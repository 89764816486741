import { OnValueChange } from "react-number-format"
import { useEffect, useState } from "react"
import StyledTableNumberInput from "@/app/components/styledTableNumberInput";
import { ONE_MILLION, POSITION_SIZE_FORMAT } from "@/constants";
import { MIN_POSITION_SIZE } from "../portfolio.constants";

export const PositionSizeInput = ({
  positionSize,
  displayType,
  onChange,
}: {
  positionSize: number;
  displayType?: "input" | "text" | undefined
  onChange: (positionSize: number) => void;
}) => {
  const [localValue, setLocalValue] = useState<number | undefined>(positionSize);

  useEffect(() => {
    // TODO: we need to update this logic as we have min value 1000 and we want to save values which are more or equal 1000
    // when position size is 0 and input value is empty we don't want to reset value as we want to allow user to edit empty field. 
    // We will show `0` when user removes focus from the input, see `handleBlur` callback
    const isEmptyOrZero = positionSize === 0 && localValue === undefined; 
    if (positionSize && positionSize !== localValue && !isEmptyOrZero) {
      setLocalValue(positionSize)
    }
  }, [positionSize, setLocalValue])
  

  const handleChange: OnValueChange = (values) => {
    const v = typeof values.floatValue === 'number' ? values.floatValue * ONE_MILLION : values.floatValue;
      
    // update local value
    setLocalValue(v)


    const newPositionSize = typeof v === 'undefined' ? 0 : v;
    onChange(newPositionSize);
  }

  const handleBlur = () => {
    if (Math.abs(positionSize || 0) < 1000) {
      // position is too small, reset value to something bigger
      const newValue = positionSize < 0 ? -1 * MIN_POSITION_SIZE : MIN_POSITION_SIZE;
      onChange(newValue)
      setLocalValue(newValue)
      return;
    }


    // reset value to last valid number if it's not valid
    if (localValue === undefined) {
      setLocalValue(1000)
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    // detect key up/down and move to the next/prev input with attribute data-position-size-input. It should go to the first element afte the last one, and to the last from first one
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      const inputs = document.querySelectorAll<HTMLInputElement>('table [data-position-size-input]');
      const currentInput = e.target as HTMLInputElement;
      const currentIndex = Array.from(inputs).indexOf(currentInput);
      const nextIndex = e.key === 'ArrowDown' ? currentIndex + 1 : currentIndex - 1;
      const nextInput = inputs[nextIndex] || inputs[e.key === 'ArrowDown' ? 0 : inputs.length - 1];
      nextInput.focus();
    }
    
  }

  const inputValue = typeof localValue === 'number' ? localValue / ONE_MILLION : localValue; // input value is in millions but we save it in nominal value

  return (
    <div className="relative">
      <StyledTableNumberInput
        value={inputValue}
        data-position-size-input
        className='max-w-[100px] min-w-[100px]'
        onValueChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        displayType={displayType}
        onFocus={(e) => {
          const input = e.target;
          setTimeout(() => {
            input.setSelectionRange(0, input.value.length);
          }, 0)
        }}
        {...POSITION_SIZE_FORMAT}
      />
    </div>
  )
}