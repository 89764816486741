import { excelAddInActions, selectExcelSavedState, selectExcelStateLoaded, selectIsExcelAddIn } from "@/store/slices/excel.slice";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";
import { getFromLocalStorage, setInLocalStorage } from "../utils/localStorage.excel";
import { LocalStorageKey } from "../excel.constants";
import { useLocation } from "react-router-dom";
import useAppNavigate from "@/hooks/useAppNavigate";

// saves excel state to hidden sheet
export const ExcelStateManager = memo(() => {
  const isExcel = useSelector(selectIsExcelAddIn);

  if (!isExcel) {
    return null;
  }

  return (
    <>
      <StateSync />
      <RouteSync />
      <OutdatedExcelWarning />
    </>
  )
})

// loads/saves excel state to localstorage
const StateSync = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectExcelSavedState);
  const stateLoaded = useSelector(selectExcelStateLoaded);
  const navigate = useAppNavigate();

  // load excel app state from localstorage on initial load
  useEffect(() => {
    try {
      const stateJson = getFromLocalStorage(LocalStorageKey.State);
      if (!stateJson) {
        dispatch(excelAddInActions.setExcelStateLoaded(true));
        return;
      }

      const state = JSON.parse(stateJson);

      if (state && state.pathname) {
        navigate(state.pathname); // navigate to last saved route when add-in is initialized
      }

      dispatch(excelAddInActions.loadState(state));
      dispatch(excelAddInActions.setExcelStateLoaded(true));
      return;
    } catch (error) {
      toast.error('Failed to load deepmm state.', { toastId: 'load-excel-state-failed' })
      captureException(error);
      dispatch(excelAddInActions.setExcelStateLoaded(true));
    }

  }, [dispatch])

  // save state to localStorage when state changes
  useEffect(() => {
    if (!stateLoaded) {
      // we need to wait for initial state to be loaded from excel state before we start saving new state
      return;
    }

    if (!state) {
      return;
    }

    try {
      const stateJson = JSON.stringify(state);
      setInLocalStorage(LocalStorageKey.State, stateJson);
    } catch (error) {
      toast.error('Failed to save deepmm state.', { toastId: 'save-excel-state-failed' })
      captureException(error);
    }
  }, [state])

  return null;
}

export const RouteSync = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const stateLoaded = useSelector(selectExcelStateLoaded);

  useEffect(() => {
    if (!stateLoaded) {
      return;
    }

    // we want to get rid of the first part of the pathname which is currently `app` or `app_dev`  -> so we remove
    const normalizedPathanme = location.pathname.replace(/^(\/(app|app_dev))+\//, ''); // replaces `/app/run/1` with `run/1` or `/app_dev/app_dev/run/1` with `run/1`

    dispatch(excelAddInActions.setPathname(normalizedPathanme));
  }, [location.pathname, dispatch])

  return null;
})


export const OutdatedExcelWarning = memo(() => {
  useEffect(() => {
    if ((navigator.userAgent.indexOf("Trident") !== -1) || (navigator.userAgent.indexOf("Edge") !== -1)) {
      toast.warn(`
        This add-in might not run in your version of Office. Please upgrade either to perpetual Office 2021 (or later) or to a Microsoft 365 account.
      `)
    }
  }, [])

  return null;
})