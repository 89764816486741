import { DateTime } from "luxon";
import { FinraHolidays } from "../app/data/dataProvider";
import { roundToDecimals, toFixed } from "./number.utils";

export function getFinraEarlyCloseSet(finraHolidays: FinraHolidays) {
  return finraHolidays 
    ? new Set(Object.getOwnPropertyNames(finraHolidays.early_close).map(d => DateTime.fromISO(d, { zone: 'America/New_York' }).toMillis())) 
    : new Set<number>()
}

export function getFinraHolidaySet(finraHolidays: FinraHolidays) {
  return finraHolidays 
    ? new Set(finraHolidays.holiday.map(d => DateTime.fromISO(d, { zone: 'America/New_York' }).toMillis())) 
    : new Set<number>()
}

export function getEndOfDayMs(dateMs: number, finraHolidays: FinraHolidays, earlyCloseSet: Set<number>) {
  return earlyCloseSet.has(dateMs)
      ? DateTime.fromISO(finraHolidays!.early_close[DateTime.fromMillis(dateMs, { zone: 'America/New_York' }).toISODate()!]).plus({ hour: 1 }).toMillis()
      : DateTime.fromMillis(dateMs, { zone: 'America/New_York' }).set({ hour: 18 }).toMillis()
}
export function getFormattedTimeToMaturity(maturity: string) {
  // diff in years
  const diffInYears = DateTime.fromJSDate(new Date(maturity)).diffNow('years').years
  if (diffInYears >= 1) {
    return roundToDecimals(diffInYears, 1) + 'y';
  }

  // diff in months if less than 1 year
  const diffInMonths = diffInYears * 12;
  if (diffInMonths >= 1) {
    return roundToDecimals(diffInMonths, 0) + 'm';
  }

  // diff in days if less than 1 month
  const diffInDays = DateTime.fromJSDate(new Date(maturity)).diffNow('days').days
  return roundToDecimals(Math.max(0, diffInDays), 0) + 'd';
}

export const isWeekend = (date: Date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
}

export const formatDateLong = (m: number) => {
  const d = new Date(m);
  return d.toLocaleString(undefined, { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit',  });
};