import { isEmpty } from "lodash";
import { getAddInWorksheet, getColumnValuesWithPositions, getSkipRowsMap } from "../excel.utils";
import { getFigiUniverseAsync } from "@/utils/getFigiUniverse";
import { selecteReferenceColumn } from "@/store/slices/excel.slice";
import { getAppState } from "@/store";

export async function clearColumn(columnId: string, config: { 
  skipIfIsOutsideOurUniverse?: boolean
} = {}): Promise<void> {
  let skipRowsMap: Map<number, boolean> | null = null;

  if (config.skipIfIsOutsideOurUniverse) {
    const figiUniverse = await getFigiUniverseAsync();

    if (!figiUniverse) {
      // we don't have figi universe so we can't check if data is outside our universe. Prevent column clearing in this case;
      return;
    }

    const referenceColumn = selecteReferenceColumn(getAppState())
    const referenceCellData = referenceColumn.columnId ? await getColumnValuesWithPositions(referenceColumn.columnId) : []
    skipRowsMap = await getSkipRowsMap({
      referenceColumn,
      referenceCellData,
    });
  }
  

  if (isEmpty(skipRowsMap)) {
    await clearColumnSimple(columnId);
  } else {
    await clearColumnWithSkip(columnId, skipRowsMap!);
  }
}

const clearColumnSimple = async (columnId: string) => {
  await Excel.run(async (context) => {
    const sheet = await getAddInWorksheet(context)

    // Get the used range of the worksheet to determine how many rows are in use
    const usedRange = sheet.getUsedRange();
    usedRange.load("rowCount"); // Load the number of rows in use

    await context.sync();

    const rowCount = usedRange.rowCount; // Number of rows in the used range

    // Construct the range for the entire column, from row 1 to the last used row
    const columnRange = sheet.getRange(`${columnId}1:${columnId}${rowCount}`);

    // Clear the values in the specified column
    columnRange.clear(Excel.ClearApplyTo.contents); // this solution also changes column width so it's not ideal
    // const emptyValues = Array(rowCount).fill(Array(1).fill(""));
    // columnRange.values = emptyValues;

    await context.sync();
  });
}





const clearColumnWithSkip = async (columnId: string, skipRowsMap: Map<number, boolean>) => {
  await Excel.run(async (context) => {
    const sheet = await getAddInWorksheet(context);

    // Get the used range of the worksheet to determine the number of rows in use
    const usedRange = sheet.getUsedRange();
    usedRange.load("rowCount"); // Load the number of rows in use

    await context.sync();

    const rowCount = usedRange.rowCount; // Number of rows in the used range

    // Iterate through each row in the specified column and clear only non-skipped rows
    for (let row = 1; row <= rowCount; row++) {
      // If the row is in skipRowsMap, skip it
      if (skipRowsMap.get(row)) {
        continue;
      }

      // Get the cell in the specified column and row
      const cell = sheet.getRange(`${columnId}${row}`);

      // Clear the cell's content without affecting the column width
      cell.values = [[""]]; // Set the cell value to an empty string
    }

    await context.sync();
  });
}