import { isEmpty } from "lodash";
import AlertsSummary from "./alertsSummary";
import AlertsTableSection from "./alertsTableSection";
import { NoAlerts } from "./noAlerts";
import Loading from "../loading";
import AlertTable, { AlertTableItem } from "./alertsTable";
import { MutableRefObject, useEffect, useRef } from "react";
import { useDataContext } from "../data/dataProvider";
import { useAlertObject } from "./hooks/useAlertObject";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, selectAlertsByType, selectHighlightedAlertId, selectInferenceData } from "../../store/slices/alert.slice";
import { useSyncAlerts } from "./alertsController/hooks/useSyncAlerts";
import { TableRefData } from "../components/table/table";
import { useOpenPage } from "../../hooks/useOpenPage";
import { AlertItem, AlertType } from "../data/alerts";
import { Tabs } from "../components/tabs/tabs";
import { TabsHeader } from "../components/tabs/tabsHeader";
import { Tab } from "../components/tabs/tab";
import { useParams } from "react-router-dom";
import { useLinkedAlerts } from "./hooks/useLinkedAlerts";
import { ProfilerLog } from "../components/profilerLog";
import { AlertColumn } from "./alert.constants";
import { useFilteredAlerts } from "./hooks/useFilteredAlerts";

const Alert = () => {
  const { getBond } = useDataContext();
  const openPage = useOpenPage();
  const { alertType } = useParams<{ alertType: AlertType }>();

  const {
    alertObjectDetails,
    loading,
  } = useAlertObject();

  const hasAlertObjectDetails = !isEmpty(alertObjectDetails);
  const alertsLoading = loading && !hasAlertObjectDetails;

  const alerts = useSelector(selectAlertsByType(alertType!))
  const inferenceData = useSelector(selectInferenceData);
  const { updateAlert, deleteAlert, deleteAlerts } = useSyncAlerts();
  const tableRef = useRef<TableRefData<AlertTableItem, AlertColumn> | null>(null);
  const { highlightAlertId } = useShowHighlightedAlertOnLoad(tableRef);
  const [linkedAlerts, setLinkedAlerts] = useLinkedAlerts([alertType]);

  // filter alerts
  const filteredAlerts = useFilteredAlerts(alerts);


  function handleTabChange(alertType: AlertType) {
    openPage.alerts(alertType, { keepQuery: true });
    tableRef.current?.scrollToTop();
    tableRef.current?.resetSort()
  }

  async function handleDelete(id: string) {
    if (linkedAlerts.has(id)) {
      await deleteAlerts(linkedAlerts);
      setLinkedAlerts(new Set());
    } else {
      return deleteAlert(id)
    }
  }

  function handleAlertChange(a: Partial<AlertItem> & { id: string }) {
    updateAlert(a, linkedAlerts.has(a.id) ? linkedAlerts : undefined);
  }


  if (alertsLoading || !getBond) {
    return <Loading className="mt-[2.5rem]" />;
  }

  if (!hasAlertObjectDetails || isEmpty(alertObjectDetails?.value?.alerts)) {
    return <NoAlerts />
  }

  const hasAlerts = !isEmpty(alerts);
  return (
    <>
      <AlertsSummary alerts={alertObjectDetails?.value?.alerts} />

      <div className="mt-[1.25rem] mx-[0.625rem] lg:mx-[1.25rem]">
        <Tabs active={alertType!} onChange={handleTabChange}>
          <TabsHeader>
            <Tab value={AlertType.Target}>Target</Tab>
            <Tab value={AlertType.Volatility}>Volatility</Tab>
          </TabsHeader>
        </Tabs>

        <ProfilerLog id="AlertsTable">
          <AlertsTableSection>
            {!hasAlerts
              ? <NoAlerts text={`Search for a bond to add ${alertType} alert`} /> : (
                <AlertTable
                  alerts={filteredAlerts}
                  onAlertChange={handleAlertChange}
                  data={inferenceData}
                  onDelete={handleDelete}
                  scrollerCss="!h-[calc(100vh-31rem)] min-h-[34rem]"
                  onRowClick={(_, alertItem) => void openPage.bond(alertItem.bond)}
                  tableRef={tableRef}
                  isRowHighlighted={(alertItem) => alertItem.alert.id === highlightAlertId}
                  alertType={alertType!}
                  linkedAlerts={linkedAlerts}
                  setLinkedAlerts={setLinkedAlerts}
                />
              )}
          </AlertsTableSection>
        </ProfilerLog>
      </div>
    </>
  )
}


// controls logic for showing highlighted alert on page load. Alert is highglihgted based on data from redux store 
const useShowHighlightedAlertOnLoad = (
  tableRef: MutableRefObject<TableRefData<AlertTableItem, AlertColumn> | null>,
) => {
  const dispatch = useDispatch();
  const highlightedAlertId = useSelector(selectHighlightedAlertId);
  const highlightAlertIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (highlightAlertIdRef.current || !tableRef.current) return;
    
    if (highlightedAlertId) {
      highlightAlertIdRef.current = highlightedAlertId;
      dispatch(alertActions.setHighlightedAlertId(null));
    }

    // switch to the page with the highlighted alert and scroll to it
    const alert = tableRef.current.sortedItems.find(a => a.alert.id === highlightAlertIdRef.current);
    if (!alert) return;
    tableRef.current.goToItem(alert!, true, { replace: true });

    // remove highlight after 3 seconds
    const timerId = setTimeout(() => {
      highlightAlertIdRef.current = null;
    }, 3000);

    return () => clearTimeout(timerId);
  }, [tableRef.current, highlightedAlertId, highlightAlertIdRef.current])

  return { highlightAlertId: highlightAlertIdRef.current }
}


export default Alert;
