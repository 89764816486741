import { useEffect } from "react"

export const usePasteEventContent = (active: boolean = true, onPaste: (text: string) => void) => {
  useEffect(() => {
    const handlePasteEvent = (event: ClipboardEvent) => {
      event.preventDefault()

      const pasteStr = event.clipboardData?.getData('text')
      if (!pasteStr) {
        return
      }

      onPaste(pasteStr)
    }
    window.addEventListener('paste', handlePasteEvent)
    return () => {
      window.removeEventListener('paste', handlePasteEvent)
    }
  }, [active])
}