
import { Side } from "@app/data/api";
import { roundToDecimals, roundToEighth, toFixed } from "../number.utils";
import { isEmpty } from "lodash";
import { PriceType } from "@/types/types";

// accepts only 5...95 values with step 5
export const getPercentileValue = (probability: number, percentiles: number[]) => {
  if (probability % 5 !== 0) {
    throw new Error('Probability must be a multiple of 5')
  }

  if (probability < 5 || probability > 95) {
    return 0;
  }

  const index = probability / 5 - 1;
  return percentiles[index];
}

export const getFormattedPctFromValue = (value: number, percentiles: number[], side: Side, priceType?: PriceType): string => {
  if (isNaN(value)) {
    return  '';
  }

  const isSpread = priceType === PriceType.Spread;

  let tempResult  = ''
  if (side === Side.bid) {
    if (value < percentiles[0]) {
      tempResult = '< 5';
    } else if (value > percentiles[percentiles.length - 1]) {
      tempResult = '> 95';
    }
  } else if (side === Side.offer) {
    if (value < percentiles[0]) {
      tempResult = '> 95';
    } else if (value > percentiles[percentiles.length - 1]) {
      tempResult = '< 5';
    }
  }

  if (tempResult) {
    if (isSpread) {
      return tempResult === '< 5' ? '> 95' : '< 5';
    } else {
      return tempResult;
    }
  }

  const probability = interpolatePercentileByValue(value, percentiles);
  const p = side === Side.bid ? probability : 1 - probability;

  return `${toFixed(p * 100, 0)}`;
}

// probabiliy 0 - 1
export const interpolatePercentile = (probability: number, percentiles: number[]) => {
  probability = probability * 100;

  if (probability < 5 || probability > 95) {
    return 0;
  }

  const leftIndex = Math.floor(probability / 5) - 1;
  const rightIndex = leftIndex + 1;
  const leftPct = (leftIndex + 1) * 5;
  const rightPct = (rightIndex + 1) * 5;
  const leftPercentile = percentiles[leftIndex];
  const rightPercentile = percentiles[rightIndex];
  return leftPercentile + (rightPercentile - leftPercentile) * (probability - leftPct) / (rightPct - leftPct);
}

export const interpolatePercentileByValue = (value: number, percentiles: number[]) => {
  if (value < percentiles[0]) {
    return 0;
  } else if (value > percentiles[percentiles.length - 1]) {
    return 1;
  }

  let leftIndex = 0;
  while (value > percentiles[leftIndex + 1]) {
    leftIndex += 1;
  }

  const rightIndex = leftIndex + 1;
  const leftPercentile = percentiles[leftIndex];
  const rightPercentile = percentiles[rightIndex];
  return interpolatePercentileByValues(leftPercentile, (leftIndex + 1) * 5 / 100, rightPercentile, (rightIndex + 1) * 5 / 100, value);
  // return leftIndex * 5 + (rightPercentile - value) * 5 / (rightPercentile - leftPercentile);

}

// This function performs linear interpolation to find the percentile probability in between two know percentiles
export const interpolatePercentileByValues = (
  leftPercentile: number,
  leftPercentileProbability: number,
  rightPercentile: number,
  rightPercentileProbability: number,
  value: number
) => {
  if (leftPercentile > value || value > rightPercentile) {
    throw new Error('Value is outside of the percentiles')
  }
  return leftPercentileProbability + (rightPercentileProbability - leftPercentileProbability) * (value - leftPercentile) / (rightPercentile - leftPercentile)
}



// We want to find the price level that maximizes the probability of getting a trade (from the perspective of the
// market maker). We will slide a window from left to right, incrementing by the rounding increment each time.
// We use the prevIndex to save time and start where we left off previously
export const percentileIndex = (value: number, percentiles: number[], prevIndex = 0) => {
  while (prevIndex < percentiles.length - 1 && value > percentiles[prevIndex + 1]) {
    prevIndex += 1
  }
  return prevIndex
}

export type ProbabilityItem = {
  percentile: number;
  probability: number;
}

export const getProbabilityArray = (percentiles: number[], side: Side, priceType: PriceType): ProbabilityItem[] => {
  if (isEmpty(percentiles)) {
    return [] as ProbabilityItem[];
  }

  let INCREMENT: number;
  let current: number;
  const first = percentiles[0];
  const last = percentiles[percentiles.length - 1];

  switch(priceType) {
    case PriceType.Spread:
      INCREMENT = 1;
      current = roundToDecimals(first, 1);
      break;
    case PriceType.Ytm:
      INCREMENT = 0.01;
      current = roundToDecimals(first, 2);
      break;
    case PriceType.Price:
      INCREMENT = 0.125;
      current = roundToEighth(first);
      break;
    default:
      throw new Error('Unknown price type');
  }


  if (current < first) {
    current += INCREMENT;
  }

  function getProbability (p: number): number {
    if (priceType === PriceType.Price) {
      return side === Side.offer ? 1 - p : p;
    } else {
      return side === Side.bid ? 1 - p : p;
    }
  }

  const probabilities: ProbabilityItem[] = [];
  while (current < last) {
    const probability = interpolatePercentileByValue(current, percentiles);

    probabilities.push({
      percentile: current,
      probability: getProbability(probability)
    })

    current += INCREMENT;
  }

  if (probabilities[0].percentile !== first) {
    probabilities.unshift({
      percentile: first,
      probability: getProbability(0.05)
    })
  }

  if (probabilities[probabilities.length - 1].percentile !== last) {
    probabilities.push({
      percentile: last,
      probability: getProbability(0.95)
    })
  }
  
  return probabilities;
}