import { InputHTMLAttributes, forwardRef } from 'react'
import clsx from 'clsx'
import styles from './AdaptiveInput.module.scss'

// input which changes width based on the width of the contnet
// it's implemented in the way that input has position absolute and underneeath it there is a span with the same content so when span resizes parent element when content of the span changes
export const AdaptiveInput = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (value: string) => void
  }
>(function AdaptiveInput(props, ref) {
  const inputStyles = clsx(styles.input, styles.defaultStyles, props.className)
  const valueStyles = clsx(styles.value, styles.defaultStyles, props.className)

  function removeMultipleSpaces(str: string) {
    return str.replace(/\s+/g, ' ')
  }

  return (
    <div className={styles.container}>
      <span className={valueStyles}>{props.value}</span>
      <input
        ref={ref}
        {...props}
        className={inputStyles}
        onChange={(e) => props.onChange(removeMultipleSpaces(e.target.value))}
      />
    </div>
  )
})
