import { Tabs } from "@/app/components/tabs/tabs";
import { SELECTABLE_TIME_PERIODS, TIME_PERIOD_CONFIG, TimePeriod } from "../historicalChart.consants";
import { CustomTimePeriodDrawer } from "./CustomTimePeriodDrawer/CustomTimePeriodDrawer";
import { memo, useMemo, useState } from "react";
import { Bond } from "@/app/data/bondIndex";
import { DateTime } from "luxon";
import { MIN_INFERENCES_DATE } from "@/constants";

export const TimePeriodSwitcher = memo(({ 
  timePeriod, 
  bond,
  setTimePeriod, 
  onCustomPeriodApply 
}: {
  timePeriod: TimePeriod;
  bond: Bond;
  setTimePeriod: (t: TimePeriod) => void;
  onCustomPeriodApply: (startDate: number, endDate: number) => void;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const tabStyles = "min-w-[2.5rem] text-center"; // flex-1 sm:flex-initial text-center whitespace-nowrap 

  function handlePeriodChange(newPeriod: TimePeriod) {
    if (newPeriod === TimePeriod.Custom) {
      setDrawerOpen(true);
      return;
    }

    setTimePeriod(newPeriod);
  }

  const settlementDate = useMemo(() => {
    return DateTime.fromISO(bond.settlementDate).toJSDate();
  }, [bond.settlementDate]);

  const isMaxVisible = useMemo(() => {
    const { getInitialTime, minus } = TIME_PERIOD_CONFIG[TimePeriod["5Y"]]!
    const maxYearAgo = getInitialTime().minus(minus)
    return settlementDate.getTime() > maxYearAgo.toMillis(); // show `max` option only if settlement date is less than 5 years option
  }, [settlementDate])


  const maxDate = useMemo(() => {
    const max = Math.min(Date.now(), new Date(bond.maturity).getTime());
    return DateTime.fromMillis(max, { zone: 'America/New_York' }).endOf('day').toMillis();
  }, [bond.maturity])

  const minDate = useMemo(() => {
    return settlementDate.getTime() > MIN_INFERENCES_DATE.getTime()
      ? DateTime.fromMillis(settlementDate.getTime(), { zone: 'America/New_York' }).startOf('day').toMillis()
      : MIN_INFERENCES_DATE.getTime()
  }, [settlementDate])

  return (
    <>

      <Tabs
        active={timePeriod}
        onChange={handlePeriodChange}
        variant="row"
        style="dark"
      >
        <Tabs.Header className="w-full sm:w-auto bottom-0 min-[1600px]:left-1/2 min-[1600px]:-translate-x-1/2  min-[1600px]:absolute flex-wrap">
          {SELECTABLE_TIME_PERIODS.map((t) => {
            if (t === TimePeriod.Max && !isMaxVisible) {
              return null;
            }

            const config = TIME_PERIOD_CONFIG[t]
            let isVisible = false;
            if (!config) {
              isVisible = true; // show option if there is no config (it's a special option)
            } else {
              const periodStartDateMs = config.getInitialTime().minus(config.minus).toMillis();
              isVisible = settlementDate.getTime() < periodStartDateMs;
            }

            if (!isVisible) {
              return null;
            }


            return (
              <Tabs.Tab key={t} value={t} className={tabStyles}>
                {t}
              </Tabs.Tab>
            )
          })}
        </Tabs.Header>
      </Tabs>


      {/* Custom period drawer */}

      <CustomTimePeriodDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        maxDate={maxDate}
        minDate={minDate}
        onApply={(startDate, endDate) => {
          setTimePeriod(TimePeriod.Custom);
          setDrawerOpen(false);
          onCustomPeriodApply(startDate, endDate);
        }}
      />
    </>
  )
})