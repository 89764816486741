/**
 * Retries a function up to a maximum number of times with a delay between tries.
 * @param fn - The function to retry.
 * @param maxTries - The maximum number of times to retry the function.
 * @param delay - The delay between retries in milliseconds.
 * @returns A promise that resolves to true if the function succeeded, or false if it failed after the maximum number of tries.
 */
export const retry = async (
  fn: () => boolean | Promise<boolean>,
  maxTries: number,
  delay: number
): Promise<boolean> => {
  let tries = 0;
  while (tries < maxTries) {
    if (await fn()) {
      return true;
    }
    tries++;
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
  return false;
};

