import { baseApi } from ".";
import { captureException } from "@sentry/core";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { BondMetadata, BondNotParsedResponse, BondResponse, BondValue } from "@/app/data/bonds";

const transformBond = (bondResponse: BondNotParsedResponse): BondResponse => {
  if (!bondResponse) {
    return bondResponse;
  }

  return {
    ...bondResponse,
    metadata: JSON.parse(bondResponse.metadata),
    value: {
      ...bondResponse.value,
    }
  }
}

export const bondEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getBondObject: builder.query<BondResponse, string>({
      query: (id) => `/objects/${id}`,
      // DO NOT CACHE RESULTS. Always set `keepUnusedDataFor: 0` 
      // There is an issue with cache. Steps to reproduce. 
      // 1. open bond page
      // 2. update existing note
      // 3. go to different page
      // 4. go back to bond page
      // 5. update same note
      // 6. see an error in console -> because of the cache we keep not only old data but also old version of the object and we're not able to update aws object because version does not match
      keepUnusedDataFor: 0, 
      providesTags: ['BondObject'],
      transformResponse: transformBond,
    }),
    getBondObjectList: builder.query<BondResponse[], void>({
      query: () => `/objects?type=bond`,
      providesTags: ['BondObjectList'],
      keepUnusedDataFor: 0,
      transformResponse: (response: BondNotParsedResponse[]): BondResponse[] => {
        return response.map(transformBond);
      }
    }),
    createBondObject: builder.mutation<BondResponse, { figi: string }>({
      query: ({ figi }) => {
        const value: BondValue = {
          figi,
          notes: [],
        }

        const metadata: BondMetadata =  {
          figi,
        }

        return {
          url: '/objects',
          method: 'POST',
          body: {
            type: 'bond',
            metadata: JSON.stringify(metadata),
            value,
          },
        }
      },
      invalidatesTags: r => r ? ['BondObjectList'] : [], // invalidate after success only
      async onQueryStarted( _, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          try {
            dispatch(
              bondEndpoints.util.updateQueryData('getBondObjectList', undefined, (prevData) => {
                const bond =  transformBond(result.data as any);
                if (!prevData) return [bond];
                return [...prevData, bond];
              })
            );
          } catch (e) {
            captureException(e);
            console.error(e);
          }
        } catch {}
      },
    }),
    updateBondObject: builder.mutation<BondResponse, {
      id: string,
      version: string,
      data?: BondValue,
      metadata?: BondMetadata
      optimistic?: boolean
    }>({
      query: ({ id, version, data, metadata }) => {
        return {
          url: '/objects',
          method: 'PATCH',
          body: {
            id,
            version,
            ...(metadata && { metadata: JSON.stringify(metadata) }),
            ...(data ? { value: data } : {}),
          },
        }
      },
      async onQueryStarted( { id, data, metadata, optimistic }, { dispatch, queryFulfilled }) {
        const _optimistic = optimistic ?? true;
        let patchResult: PatchCollection | undefined = undefined;
        
        const updateBondObject = (p: BondResponse) => {
          return {
            ...p,
            ...(metadata && { metadata }),  // api works in a way that if metadata or positions are not provided it return those keys as empty strings but actually there is a value on the server
            value: {
              ...p.value,
              notes: data?.notes || (p.value ? (p.value.notes || []) : []),
            }
          }
        }

        if (_optimistic) { 
          // optimistic update
          patchResult = dispatch(
            bondEndpoints.util.updateQueryData('getBondObject', id, (bondObject) => {
              return updateBondObject(bondObject)
            })
          );

          // TODO: implement optimistic update for the list
        }


        // sync data in store after query fullfilled, no need for refetch
        try {
          await queryFulfilled;

          if (_optimistic) {
            // optimistic update - we already have latest data. We need to sync only aws generated data and object `value` we have already up to date
            dispatch(
              bondEndpoints.util.updateQueryData('getBondObject', id, (r: BondResponse) => ({
                ...r,
                value: r.value,
              }))
            );

          } else {
            try {
              // update bond item
              dispatch(
                bondEndpoints.util.updateQueryData('getBondObject', id, updateBondObject)
              );
  
              // update bond item in the list
              dispatch(
                bondEndpoints.util.updateQueryData('getBondObjectList', undefined, (prevData) => {
                  return prevData.map(p => {
                    return p.id === id ?  updateBondObject(p) : p
                  })
                })
              );
            } catch (e) {
              captureException(e);
              console.error(e);
            }
          }

          

        } catch {
          if (_optimistic) {
            // revert state back if it failed
            patchResult?.undo?.();
          }
        };
      },
    }),

  }),
});

export const {
  useGetBondObjectQuery,
  useGetBondObjectListQuery,
  useCreateBondObjectMutation,
  useUpdateBondObjectMutation,
} = bondEndpoints;


