import { Bond } from "@data/bondIndex";
import { filterByExactValue, filterByMaturity, filterByRange, useFilters } from "./useFilters";
import { useMemo } from "react";
import { InferenceResult } from "@/hooks/data/useSimpleInferenceData";

export const useFilteredItems = <T extends Bond>(bonds: T[], inferenceResult: InferenceResult<T>) => {
  const filters = useFilters(bonds);
  const filteredItems = useMemo(() => {
    return bonds.filter(p => {
      // ticker
      if (!filterByExactValue(filters.values.ticker, p.ticker)) {
        return false;
      }

      // coupon
      if (!filterByExactValue(filters.values.coupon, p.coupon)) {
        return false;
      }

      // series
      if (!filterByExactValue(filters.values.series, p.series || '-')) {
        return false;
      }

      // rating
      if (!filterByExactValue(filters.values.rating, p.rating || '-')) {
        return false;
      }

      // bid
      if (!filterByRange(filters.values.bid, inferenceResult.data[p.figi]?.bid?.price.current)) {
        return false;
      }

      // offer
      if (!filterByRange(filters.values.offer, inferenceResult.data[p.figi]?.offer?.price.current)) {
        return false;
      }

      // bid spread
      if (!filterByRange(filters.values.bidSpread, inferenceResult.data[p.figi]?.bid?.spread.current)) {
        return false;
      }

      // offer spread
      if (!filterByRange(filters.values.offerSpread, inferenceResult.data[p.figi]?.offer?.spread.current)) {
        return false;
      }

      // bid ytm
      if (!filterByRange(filters.values.bidYtm, inferenceResult.data[p.figi]?.bid?.ytm.current)) {
        return false;
      }

      // offer ytm
      if (!filterByRange(filters.values.offerYtm, inferenceResult.data[p.figi]?.offer?.ytm.current)) {
        return false;
      }

      // tenor
      if (!filterByMaturity(filters.values.tenor, p.maturity)) {
        return false;
      }

      return true;
    });
  }, [filters.values, inferenceResult.data, bonds])

  return filteredItems;
}