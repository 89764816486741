import { PriceType } from "@/types/types";
import { NumericFormatProps } from "react-number-format";

export const getFormatConfig = (priceType: PriceType, delta: boolean) => {
  const formatConfig: NumericFormatProps = {}
  if (priceType === PriceType.Spread) {
    formatConfig.decimalScale = 1
  } else if (priceType === PriceType.Ytm) {
    formatConfig.decimalScale = delta ? 3 : 2
  }

  return formatConfig;
}