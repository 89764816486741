import { createSlice } from "@reduxjs/toolkit";
import { TAppState } from "..";
import { UIPermission } from "../../app/auth/auth.types";
import { PA } from "../../types/utility.types";

export type TAuthReducerState = {
  apiUrl: string; // using this for now -> later on we will have only one api
  token: string | null;
  email: string;
  uiPermission: UIPermission;
  expiration: number;
  hasCusipAccess: boolean;
  uiAccess: boolean;
  uiTrialExpiration: number;
  signOut: () => void;
  refreshToken: () => Promise<{ token: string }>;
  getToken: () => Promise<{ token: string }>;
};

const initialState: TAuthReducerState = {
  apiUrl: "",
  token: null,
  email: '',
  uiPermission: "none",
  expiration: 0,
  hasCusipAccess: false,
  uiAccess: false,
  uiTrialExpiration: 0,
  signOut: () => {},
  refreshToken: async () => Promise.resolve({ token: '' }),
  getToken: async () => Promise.resolve({ token: '' }),
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    reset: () => initialState,
    setAuthData: (state, action: PA<AuthData>) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.uiPermission = action.payload.uiPermission;
      state.expiration = action.payload.expiration;
      state.hasCusipAccess = action.payload.hasCusipAccess;
      state.uiAccess = action.payload.uiAccess;
      state.uiTrialExpiration = action.payload.uiTrialExpiration;
      state.signOut = action.payload.signOut;
    },
    setToken: (state, action: PA<string>) => {
      state.token = action.payload;
    },
    setApiUrl: (state, action: PA<string>) => {
      state.apiUrl = action.payload;
    },
    setRefreshTokenFn: (state, action: PA<() => Promise<{ token: string }>>) => {
      state.refreshToken = action.payload;
    },
    setGetTokenFn: (state, action: PA<() => Promise<{ token: string }>>) => {
      state.getToken = action.payload;
    }
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;

export const selectToken = (state: TAppState) => state.auth.token;
export const selectiIsLoggedIn = (state: TAppState) => !!state.auth.token;
export const selectUiPermission = (state: TAppState) => state.auth.uiPermission;
export const selectExpiration = (state: TAppState) => state.auth.expiration;
export const selectHasCusipAccess = (state: TAppState) => state.auth.hasCusipAccess;
export const selectSignOut = (state: TAppState) => state.auth.signOut;
export const selectRefreshTokenFn = (state: TAppState) => state.auth.refreshToken;
export const selectGetTokenFn = (state: TAppState) => state.auth.getToken;
export const selectApiUrl = (state: TAppState) => state.auth.apiUrl;
export const selectUiAccess = (state: TAppState) => state.auth.uiAccess;
export const selectCurrentUserEmail = (state: TAppState) => state.auth.email;
export const selectUiTrialExpiration = (state: TAppState) => state.auth.uiTrialExpiration;
export const selectIsUiTrialExpired = (state: TAppState) => {
  const { uiAccess, uiTrialExpiration } = state.auth;
  return !uiAccess && uiTrialExpiration > 0 && uiTrialExpiration * 1000 < Date.now();
}
export const selectIsUiTrialActive = (state: TAppState) => {
  const { uiAccess, uiTrialExpiration } = state.auth;
  return !uiAccess && uiTrialExpiration > 0 && uiTrialExpiration * 1000 >= Date.now();
}
export const selectCanAccessUi = (state: TAppState) => {
  return state.auth.uiAccess || selectIsUiTrialActive(state);
}


type AuthData = {
  token: string;
  uiPermission: UIPermission;
  expiration: number;
  hasCusipAccess: boolean;
  uiAccess: boolean;
  uiTrialExpiration: number;
  email: string;
  signOut: () => void;
}