import { getFigiUniverseAsync } from "@/utils/getFigiUniverse";
import { captureException } from "@sentry/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const useFigiUniverse = () => {
  const [loaded, setLoaded] = useState(false);
  const [figiUniverse, setFigiUniverse] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    getFigiUniverseAsync()
      .then((map) => {
        if (map) {
          setFigiUniverse(map);
          setLoaded(true);
        }
      }).catch((error) => {
        captureException(error);
        toast.error('Failed to load required data, please refresh the page to try again.')
      })
  }, []);


  return {
    figiUniverse,
    figiUniverseLoaded: loaded,
  }
}