import StyledMenu from "@/app/components/styledMenu";
import { usePortfolioPrivileges } from "@/contexts/privileges/components/PortfolioPrivilegesProvider";
import { openShareModal } from "@/store/actions/shareModal.actions";
import { selectIsExcelAddIn } from "@/store/slices/excel.slice";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";

export const PortfolioActionsMenu = ({
  portfolioId,
  portfolioName,
  onCopy,
  onImport,
  onDownload,
  onEdit,
  onDelete,
}: {
  portfolioId: string;
  portfolioName: string;
  onCopy: (type: 'default' | 'bid' | 'offer') => void;
  onImport: () => void;
  onDownload: (type?: 'noDates') => void;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const isExcelAddIn = useSelector(selectIsExcelAddIn);
  const { canEdit } = usePortfolioPrivileges();
  const editDisabled = !canEdit();

  return (
    <StyledMenu
      ariaLabel='Portfolio Menu'
      Button={() => <EllipsisVerticalIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />}
      buttonCss='rounded-full'
      menuItems={[
        {
          ariaLabel: 'Copy',
          Item: () => 'Copy',
          key: 'copy',
          onClick: () => onCopy('default'),
          subMenu: [
            {
              ariaLabel: 'Copy to Clipboard',
              Item: () => 'Copy to Clipboard',
              key: 'copy',
              onClick: () => onCopy('default'),
            },
            {
              ariaLabel: 'Copy Bids',
              Item: () => 'Copy Bids',
              key: 'copy-bids',
              onClick: () => onCopy('bid'),
            },
            {
              ariaLabel: 'Copy Offers',
              Item: () => 'Copy Offers',
              key: 'copy-offers',
              onClick: () => onCopy('offer'),
            }
          ]
        },
        {
          ariaLabel: 'Import',
          Item: () => 'Import',
          key: 'import',
          disabled: editDisabled || isExcelAddIn,
          onClick: () => onImport(),
        },
        {
          ariaLabel: 'Download',
          Item: () => 'Download',
          key: 'download',
          onClick: () => onDownload(),
          disabled: isExcelAddIn,
          subMenu: [
            {
              ariaLabel: 'Download',
              Item: () => 'Download',
              key: 'download',
              disabled: isExcelAddIn,
              onClick: () => onDownload(),
            },
            {
              ariaLabel: 'Download without dates',
              Item: () => 'Download without dates',
              key: 'download-no-dates',
              disabled: isExcelAddIn,
              onClick: () => onDownload('noDates'),
            },
          ]
        },
        {
          ariaLabel: 'Edit',
          Item: () => 'Edit',
          key: 'edit',
          disabled: editDisabled,
          onClick: () => onEdit(),
        },
        {
          ariaLabel: 'Share',
          Item: () => 'Share',
          key: 'share',
          disabled: editDisabled,
          onClick: () => {
            openShareModal({
              objectId: portfolioId,
              objectType: 'portfolio',
              objectName: portfolioName,
            })
          }
        },
        {
          ariaLabel: editDisabled ? 'Remove me from shared list' : 'Delete',
          Item: () => editDisabled ? 'Remove me from share list' : 'Delete',
          key: 'delete',
          // disabled: editDisabled,
          onClick: () => onDelete()
        }
      ]}
    />
  )
}