import clsx from 'clsx';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { DEFAULT_PRICE_FORMAT } from '../../constants';

type StyledTableNumberInputProps = NumericFormatProps & {
  invalid?: boolean;
}

const StyledTableNumberInput = ({className, invalid, disabled, displayType, ...rest}: StyledTableNumberInputProps) => {
  const isText = displayType === 'text'

  return (
    <NumericFormat 
      {...DEFAULT_PRICE_FORMAT}
      data-invalid={invalid}
      disabled={disabled}
      displayType={displayType}
      {...rest}
      className={clsx(className, 'bg-transparent rounded-[0.3125rem] text-[#FBFBFD] text-[0.875rem] w-full font-[500]', {
        'border-[#8183B3]': !invalid && !isText,
        'p-[0.3125rem] h-[1.625rem] border-[0.0625rem]': !isText,
        'border-[#E3342F] focus:border-[#E3342F]': invalid,
        'opacity-50': disabled,
      })}
    />
  )
}

export default StyledTableNumberInput;