import { Note } from "@/types/types";
import { ObjectResponse } from "./objects";
import { useGetBondObjectListQuery, useGetBondObjectQuery } from "@/store/api/bond.endpoints";
import { useEffect, useState } from "react";

export type BondValue = {
  figi: string;
  notes: Note[];
}

export type BondMetadata = {
  figi: string;
}

export type BondResponse = ObjectResponse<BondValue, BondMetadata>
export type BondNotParsedResponse = ObjectResponse<BondValue, string>

export type UseBondObjectResult = {
  objectMissing: boolean;
  isFetching: boolean;
  isError: boolean;
  data: BondResponse | undefined;
}

export const useBondObject = ({ figi }: { figi: string | undefined | null }): UseBondObjectResult => {
  const listData = useGetBondObjectListQuery()
  const [objectMissing, setObjectMissing] = useState(false)
  const [objectId, setObjectId] = useState<string | null>(null)
  const bondObjectData = useGetBondObjectQuery(objectId as string, {
    skip: !objectId
  })

  useEffect(() => {
    if (!Array.isArray(listData.data) || !figi) {
      // data is missing for now
      return;
    }

    const bondObject = listData.data.find((item) => item.metadata.figi === figi)

    
    if (!bondObject) {
      // object is missing we need to create it
      setObjectMissing(true)
      setObjectId(null);
    } else {
      setObjectMissing(false)
      setObjectId(bondObject.id)
    }
  }, [listData.data, figi])

  const isFetching = listData.isFetching || bondObjectData.isFetching
  const isError = listData.isError || bondObjectData.isError

  return {
    objectMissing,
    isFetching,
    isError,
    data: bondObjectData.data,
  }
}