import { selectiIsLoggedIn } from "@/store/slices/auth.slice";
import { selectIsExcelAddIn } from "@/store/slices/excel.slice"
import { useSelector } from "react-redux"

export const ExcelLoginScreenMessage = () => {
  const isExcelAddIn = useSelector(selectIsExcelAddIn);
  const isLoggedIn = useSelector(selectiIsLoggedIn);

  if (!isExcelAddIn || isLoggedIn) {
    return null;
  }

  return (
    <div className="px-4 mt-10 mx-auto max-w-[500px] text-center">
      <p>Deep MM has the most accurate US corporate bond pricing model in the world, and we make it available through this Office Add In which allows you to manage your portfolio and runs right in Excel, syncing real-time information from our models with your worksheet. </p>

      <p>Add-in allows to connect your Deep MM account right into your excel worksheet. </p>

      <div className="text-center   max-w-[90%] w-[350px] mx-auto mt-[4.25rem]">
        <p>Contact us in order to get an account and get access to the Excel Add-In - <a href="mailto:support@deepmm.com">support@deepmm.com</a></p>
      </div>
    </div>
  )
} 