import { openYesNoDialog } from "@/app/components/confirmationDialog/confirmationDialog.utils";
import { getAppState } from "@/store"
import { selectWorksheetId } from "@/store/slices/excel.slice"
import { ReferenceColumn } from "./excel.types";
import { isEmpty } from "lodash";
import { figiUniverse, getFigiUniverseAsync } from "@/utils/getFigiUniverse";
import { captureException } from "@sentry/react";

// write text to a cell 
// example  -> insertText('text', 'A1')
export async function insertText(text: string | number, address: string) {
  try {
    await Excel.run(async (context) => {
      const sheet = await getAddInWorksheet(context)
      const range = sheet.getRange(address);
      range.values = [[text]];
      range.format.autofitColumns();
      await context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
}

// returns selected column id
export async function getSelectedColumnId(): Promise<string> {
  let selectedRange = await getSelectedRange();
  const columnId = getColumnIdFromRangeAddress(selectedRange);
  return columnId;
}

export async function checkSelectedCellAndReturnColumnId(): Promise<string | null> {
  let selectedRange = await getSelectedRange();

  if (!selectedRange) {
    await showAlert("Please select a cell.");
    await waitForSelection();
    selectedRange = await getSelectedRange();
  }

  const columnId = getColumnIdFromRangeAddress(selectedRange);
  return columnId; // Return the column letter
}

// Get the currently selected range
async function getSelectedRange(): Promise<string | null> {
  return await Excel.run(async (context) => {
    const selection = context.workbook.getSelectedRange();
    selection.load("address"); // Load the address of the selected range
    await context.sync();

    // If the address is empty, no cell is selected
    if (selection.address === "") {
      return null;
    }

    return selection.address;
  });
}

// Convert range address to column id
function getColumnIdFromRangeAddress(rangeAddress: string | null): string {
  if (!rangeAddress) {
    return "";
  }
  const address = rangeAddress.split("!")[1]; // Remove sheet name if present
  const columns = address.replace(/[0-9]/g, ''); // Remove row numbers
  const column = columns.split(":")[0]; // Use only the first column if multiple are selected
  return column;
}

// Simulate an alert that the user needs to select a cell
async function showAlert(message: string): Promise<void> {
  return new Promise((resolve) => {
    openYesNoDialog({
      title: message,
    })
    resolve();
  });
}

// Wait for the user to select a cell
async function waitForSelection(): Promise<void> {
  return new Promise((resolve) => {
    let previousAddress: string | null = null;

    const interval = setInterval(async () => {
      const selectedRange = await getSelectedRange();
      if (selectedRange && selectedRange !== previousAddress) {
        clearInterval(interval); // Stop checking once selection changes
        resolve();
      }
      previousAddress = selectedRange;
    }, 500); // Check every 500ms
  });
}

export async function subscribeToSelectionChange(callback: (args: any) => Promise<any>): Promise<void> {
  await Excel.run(async (context) => {
    const worksheet = await getAddInWorksheet(context)

    // Add an event handler for selection changes
    worksheet.onSelectionChanged.add(callback);
    await context.sync();
  });
}

export async function unsubscribeFromSelectionChange(callback: (args: any) => Promise<any>): Promise<void> {
  await Excel.run(async (context) => {
    const worksheet = await getAddInWorksheet(context)
    worksheet.onSelectionChanged.remove(callback);
    await context.sync();
  });
}

export interface CellData {
  row: number;
  value: string;
}

export async function getColumnValuesWithPositions(columnId: string): Promise<CellData[]> {
  return Excel.run(async (context) => {
    const worksheet = await getAddInWorksheet(context)

    // Get the used range to dynamically determine the last row with data in the column
    const usedRange = worksheet.getUsedRange();
    usedRange.load("rowCount");

    await context.sync();

     // Extract the total number of rows from the used range
     const totalRows = usedRange.rowCount;

    // Define the range for the entire column, assuming a reasonable row limit
    const columnRange = worksheet.getRange(`${columnId}1:${columnId}${totalRows}`);
    columnRange.load("values"); // Load all the values in the column

    await context.sync();

    // Check if 'values' is available and not null
    const values = columnRange.values as (string | null)[][];

    // If values are empty or null, return an empty array
    if (!values || values.length === 0) {
      console.log(`No data found in column ${columnId}`);
      return [];
    }
    const results: CellData[] = [];

    let consecutiveEmptyRows = 0; // Track consecutive empty rows

    // Iterate through the values, stop when three consecutive empty rows are found
    for (let rowIndex = 0; rowIndex < values.length; rowIndex++) {
      const value = values[rowIndex][0]; // Get the value in the current row of the column

      if (!value) {
        // If the value is empty, increment the counter
        consecutiveEmptyRows++;
        if (consecutiveEmptyRows >= 3) {
          break; // Stop if there are 3 consecutive empty rows
        }
      } else {
        // If the value is not empty, reset the counter and add the result
        consecutiveEmptyRows = 0;
        results.push({
          row: rowIndex + 1, // Row numbers in Excel are 1-based
          value: value
        });
      }
    }

    return results;
  });
}

export const convertCellDataToMap = (cellData: CellData[]): Map<string, number> => {
  const map = new Map<string, number>();
  cellData.forEach((cell) => {
    map.set(`${cell.value}`, cell.row); // cell value can be numbers and strings so we need to convert it to string
  });
  return map;
}


/**
 * 
 * Function checks if figi is part of our universe. If it's not part of our universe we need to skip that row when we insert data in the column
 * @returns map of figis that we need to skip when we insert data into excel column. 
 */
export const getSkipRowsMap = async ({
  referenceColumn,
  referenceCellData,
}: {
  referenceColumn: ReferenceColumn
  referenceCellData: CellData[];
}): Promise<Map<number, boolean>> => {
  const skipRowsMap = new Map<number, boolean>();

  if (!referenceColumn.columnId) {
    return skipRowsMap;
  }

  if (!figiUniverse.data) {
    const result = await getFigiUniverseAsync();

    debugger;
    if (!result) {
      captureException(new Error('Failed to load figi universe getSkipRowsMap'));
      throw new Error('Failed to load required data, please refresh the page to try again.');
    }
  }
  

  if (!figiUniverse.data) {
    return skipRowsMap;
  }

  const mapToCheck = referenceColumn.type === 'figi'
    ? figiUniverse.data
    : referenceColumn.type === 'cusip'
      ? figiUniverse.cusips
      : referenceColumn.type === 'isin' 
        ? figiUniverse.isins
        : null;

  referenceCellData.forEach(({ value, row }) => {
    if (isEmpty(value)) {
      return;
    }

    if (!mapToCheck) {
      // we don't know reference type so we need to check all of them
      if (!figiUniverse.cusips.has(value) && !figiUniverse.isins.has(value) && (!figiUniverse.data || !figiUniverse.data.has(value))) {
        skipRowsMap.set(row, true);
      }
      return;
    }

    if (!mapToCheck.has(value)) {
      skipRowsMap.set(row, true);
    }
  });

  return skipRowsMap;
}

export async function checkIfCellIsEmpty(cell: string): Promise<boolean> {
  return Excel.run(async (context) => {
      const sheet = await getAddInWorksheet(context)

      // Get the specified range (the single cell) by address
      const range = sheet.getRange(cell);
      range.load("values"); // Load the value of the cell

      await context.sync(); // Sync to retrieve the value

      const cellValue = range.values[0][0]; // Get the value of the cell

      // Check if the value is null, undefined, or an empty string (i.e., the cell is empty)
      return cellValue === null || cellValue === "" || cellValue === undefined;
  });
}

export async function checkIfColumnIsEmpty(column: string): Promise<boolean> {
  return Excel.run(async (context) => {
      const sheet = await getAddInWorksheet(context)

      // Get the used range of the worksheet to limit the check to the rows that are in use
      const usedRange = sheet.getUsedRange();
      usedRange.load("rowCount"); // Load the number of rows in use

      await context.sync();

      // Get the range for the entire column (from row 1 to the last used row)
      const columnRange = sheet.getRange(`${column}1:${column}${usedRange.rowCount}`);
      columnRange.load("values"); // Load all values in that column

      await context.sync();

      // Check if all cells in the column are empty
      const isEmpty = columnRange.values.every(row => row[0] === null || row[0] === "" || row[0] === undefined);

      return isEmpty;
  });
}

export async function getActiveWorksheetId(): Promise<string> {
  return Excel.run(async (context) => {
    const workbook = context.workbook;

    // Get the active worksheet
    const activeSheet = workbook.worksheets.getActiveWorksheet();

    // Load the worksheet's unique ID
    activeSheet.load("id");
    await context.sync();

    // Return the active worksheet's unique ID
    return activeSheet.id;
  });
}

export async function getAddInWorksheet(context:  Excel.RequestContext): Promise<Excel.Worksheet> {
  const workbook = context.workbook;

  const id = selectWorksheetId(getAppState());
  const sheet = workbook.worksheets.getItem(id);

  await context.sync();
  
  return sheet;
}