'use client'

import { createContext, useContext } from "react"

type PortfolioPrivilegesState = {
  canEdit: () => boolean;
}

export const PortfolioPrivilegesContext = createContext<PortfolioPrivilegesState>({} as any)
export const PortfolioPrivilegesProvider = PortfolioPrivilegesContext.Provider

export const usePortfolioPrivileges = () => useContext(PortfolioPrivilegesContext)
