import { createSlice } from "@reduxjs/toolkit";
import { PA } from "@/types/utility.types";
import { TAppState } from "..";
import { ColumnConnection, ExcelAddInSavedState, ReferenceColumn } from "@/excelApp/excel.types";
import { isEqual, set } from "lodash";

type  ExcelAddInState = {
  /** `true` when app is running inside of excel add-in */
  isExcelAddIn: boolean;
  /** `true` when we loaded state from local storage, after this we can do other state updates */
  stateLoaded: boolean;
  /** we save pathname in the store so next time add-in is refreshed or excel closed/open we can restore previous page */
  pathname: string;
  /** When excel is in cell editing mode it's not possible to update other cells or you'll get an error. It happens when user edits some cells manually */
  excelInCellEditingMode: boolean; 
  /** Reference column is column with unique id which we use to conect our data to excel data (for example figi/cusip/isin) */
  referenceColumn: ReferenceColumn; 
  /** Unique identifier of add in generated by us */
  addInId: string;
  worksheetId: string; 
  /** Columns that user connects manually. It has data which deepmm table column is connected to which excel column */
  connectedColumns: {
    run: ColumnConnection[];
    portfolio: ColumnConnection[];
  },
}

const initialState: ExcelAddInState = {
  isExcelAddIn: false,
  stateLoaded: false,
  pathname: '',
  excelInCellEditingMode: false,
  addInId: '',
  worksheetId: '',
  referenceColumn: {
    columnId: '',
    type: ''
  },
  connectedColumns: {
    run: [],
    portfolio: []
  }
};

const excelAddIn = createSlice({
  name: "excelAddIn",
  initialState,
  reducers: {
    setExcelStateLoaded: (state, action: PA<boolean>) => {
      state.stateLoaded = action.payload;
    },
    setPathname: (state, action: PA<string>) => {
      state.pathname = action.payload;
    },
    setWorksheetId: (state, action: PA<string>) => {
      state.worksheetId = action.payload;
    },
    setAddInId: (state, action: PA<string>) => {
      state.addInId = action.payload;
    },
    setIsExcelAddIn: (state, action: PA<boolean>) => {
      state.isExcelAddIn = action.payload;
    },
    setReferenceColumn: (state, action: PA<ReferenceColumn>) => {
      state.referenceColumn = action.payload;
    },
    setConnectedColumn: (state, action: PA<{ type: 'run' | 'portfolio', columnId: any, excelColumnId: string }>) => {
      const { type, columnId, excelColumnId } = action.payload;

      state.connectedColumns[type] = state.connectedColumns[type].filter(
        (connection) => connection.columnId !== columnId && connection.excelColumnId !== excelColumnId
      );

      state.connectedColumns[type].push({ columnId, excelColumnId });
    },
    disconnectAllColumnsByType: (state, action: PA<'run' | 'portfolio'>) => {
      const type = action.payload;
      state.connectedColumns[type] = [];
    },
    disconnectColumn: (state, action: PA<{ type: 'run' | 'portfolio', columnId: any }>) => {
      const { type, columnId } = action.payload;

      state.connectedColumns[type] = state.connectedColumns[type].filter(
        (connection) => connection.columnId !== columnId
      );
    },
    disconnectColumnByExcelId: (state, action: PA<{ type: 'run' | 'portfolio', excelColumnId: any }>) => {
      const { type, excelColumnId } = action.payload;

      state.connectedColumns[type] = state.connectedColumns[type].filter(
        (connection) => connection.excelColumnId !== excelColumnId
      );
    },
    setExcelInCellEditingMode: (state, action: PA<boolean>) => {
      state.excelInCellEditingMode = action.payload;
    },
    loadState: (state, action: PA<ExcelAddInSavedState>) => {
      state.referenceColumn = action.payload.referenceColumn;
      state.connectedColumns = action.payload.connectedColumns;
      state.worksheetId = action.payload.worksheetId;
      state.pathname = action.payload.pathname;
    }
  },
});

export const excelAddInActions = excelAddIn.actions;
export const excelAddInReducer = excelAddIn.reducer;

export const selectIsExcelAddIn = (state: TAppState) => state.excelAddIn.isExcelAddIn;
export const selectExcelStateLoaded = (state: TAppState) => state.excelAddIn.stateLoaded;
export const selectExcelPathname = (state: TAppState) => state.excelAddIn.pathname;
export const selecteReferenceColumn = (state: TAppState) => state.excelAddIn.referenceColumn;
export const selectExcelInCellEditingMode = (state: TAppState) => state.excelAddIn.excelInCellEditingMode;
export const selectAllConnectedColumns = (state: TAppState) => state.excelAddIn.connectedColumns;
export const selectAllConnectedColumnExcelIds = (state: TAppState, type: 'run' | 'portfolio') => {
  return state.excelAddIn.connectedColumns[type].map(c => c.excelColumnId);
}
export const selectWorksheetId = (state: TAppState) => state.excelAddIn.worksheetId;
export const selectAddInId = (state: TAppState) => state.excelAddIn.addInId;
export const selectColumnConnectionDetails = (state: TAppState, type: 'run' | 'portfolio', columnId: any) => {
  if (!type) {
    return;
  }

  return state.excelAddIn.connectedColumns[type].find(c => c.columnId === columnId);
}
export const selectRunColumnsConnectionDetails = (state: TAppState) => state.excelAddIn.connectedColumns.run;

let prevState: ExcelAddInSavedState | null;
export const selectExcelSavedState = (state: TAppState): ExcelAddInSavedState => {

  const newState: ExcelAddInSavedState =  {
    pathname: state.excelAddIn.pathname,
    referenceColumn: state.excelAddIn.referenceColumn,
    connectedColumns: state.excelAddIn.connectedColumns,
    worksheetId: state.excelAddIn.worksheetId,
  }

  if (prevState && isEqual(prevState, newState)) {
    return prevState;
  }

  prevState = newState;
  return newState;
}