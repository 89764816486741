import { captureException } from "@sentry/core";
import { baseApi } from ".";
import { portfolioEndpoints } from "./portfolio.endpoints";
import { runEndpoints } from "./run.endpoints";
import { GetPrivilegeResponse, RemovePrivilegeRequest, UpdatePrivilegeRequest } from "@/app/data/objects";
import { ObjectPrivilege } from "@/types/enums";
import { toast } from "react-toastify";
import { canDelete } from "@/utils/privilege.utils";

export const objectEndpoints = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getObjectPrivilege: builder.query<GetPrivilegeResponse, string>({
      query: (objectId) => `/privileges/${objectId}`,
    }),

    // this api is used for create/update
    updateObjectPrivilege: builder.mutation<null, UpdatePrivilegeRequest>({
      query: ({ objectId, email, privilege, privilege_version}) => ({
        url: `/privileges/${objectId}`,
        method: 'PATCH',
        body: {
          id: objectId,
          email,
          privilege,
          privilege_version,
        },
      }),
      async onQueryStarted( { objectId, email }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUserPrivilege } = await queryFulfilled
          dispatch(
            objectEndpoints.util.updateQueryData('getObjectPrivilege', objectId, (prevResults) => {
              if (!prevResults) {
                return prevResults as any;
              }

              return prevResults.map(p => {
                if (p.email === email) {
                  return updatedUserPrivilege;
                }
                return p;
              });
            })
          )
        } catch {}
      },
    }),
    removeObjectPrivilege: builder.mutation<null, RemovePrivilegeRequest>({
      query: ({ objectId, email, privilege_version}) => ({
        url: `/privileges/${objectId}`,
        method: 'PATCH',
        body: {
          id: objectId,
          email,
          privilege: null,
          privilege_version,
        },
      }),
    }),
    removeObjectPrivilegeOptimistic: builder.mutation<null, RemovePrivilegeRequest>({
      query: ({ objectId, email, privilege_version}) => ({
        url: `/privileges/${objectId}`,
        method: 'PATCH',
        body: {
          id: objectId,
          email,
          privilege: null,
          privilege_version,
        },
      }),
      async onQueryStarted( { objectId, email }, { dispatch, queryFulfilled }) {
        // optimistic update
        const patchResult = dispatch(
          objectEndpoints.util.updateQueryData('getObjectPrivilege', objectId, (objectPrivilegeResponse) => {
            const data = objectPrivilegeResponse || [];
            return data.filter(p => p.email !== email);
          })
        );
        
        try {
          await queryFulfilled;
        } catch (e){
          captureException(e);
          toast.error(`Failed to remove user "${email}", try again...`);
          // revert state back if it failed
          // TODO: maybe it's better to just do objectEndpoints.util.updateQueryData with previous data
          patchResult?.undo?.();
        };
      },
    }),
    deleteObject: builder.mutation<null, { type: 'portfolio' | 'run', id: string, email: string }>({
      queryFn:  async ({ id, email }, api, o, baseQuery) => {
        const privilege = await baseQuery({ url: `/privileges/${id}`, method: 'GET', });

        if (privilege.error) {
          return privilege;
        }
        
        if (!Array.isArray(privilege?.data)) {
          return { error: { status: 404, data: 'Privilege not found' } };
        }

        const privilegeData = (privilege?.data as GetPrivilegeResponse)?.find(p => p.email === email);
        

        const result = await baseQuery({ 
          url: `/privileges/${id}`, 
          method: 'PATCH',
          body: {
            email,
            privilege: null,
            privilege_version: privilegeData?.privilege_version,
          }
        })

        if ((result?.meta as any)?.response?.status !== 200) {
          return { error: { status: 500, data: 'Failed to remove privilege' } };
        }

        return { data: null };
      },

      // update data in store
      async onQueryStarted( { id, type  }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          
          try {
            if (type === 'portfolio') {
              dispatch(
                portfolioEndpoints.util.updateQueryData('getPortfolioList', undefined, (prevData) => {
                  return prevData.filter(p => p.id !== id)
                })
              );
            } else if (type === 'run') {
              dispatch(
                runEndpoints.util.updateQueryData('getRunList', undefined, (prevData) => {
                  return prevData.filter(p => p.id !== id)
                })
              );
            }
          } catch (e) {
            captureException(e)
            console.error(e)
          }
        } catch (e) {}
      },
    })

  }),
});

export const {
  useDeleteObjectMutation,
  useGetObjectPrivilegeQuery,
  useLazyGetObjectPrivilegeQuery,
  useUpdateObjectPrivilegeMutation,
  useRemoveObjectPrivilegeMutation,
  useRemoveObjectPrivilegeOptimisticMutation,
} = objectEndpoints;


