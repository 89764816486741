import { createContext, useContext } from "react";
import { Tab } from "./tab";
import { TabsHeader } from "./tabsHeader";
import { TabStyle, TabVariant } from "./tabs.types";

type TabsContextType<T = any> = {
  active: T;
  variant: TabVariant
  style: TabStyle
  setActiveTab?: (tab: T) => void;
}

const TabsContext = createContext<TabsContextType>({
  active: null,
  variant: 'default',
  style: 'default',
  setActiveTab: () => {},
})

export function Tabs<T,>({ 
  active,
  children,
  variant = 'default',
  style = 'default',
  onChange,
}: { 
  active?: T;
  children: React.ReactNode;
  variant?: TabVariant;
  style?:  TabStyle
  onChange?: (tab: T) => void;
}) {
  return (
    <TabsContext.Provider value={{ active, variant, style, setActiveTab: onChange }}>
      {children}
    </TabsContext.Provider>
  );
}

Tabs.Tab = Tab;
Tabs.Header = TabsHeader;

export const useTabsContext = () => useContext(TabsContext)