import { captureException } from "@sentry/react";
import { getAddInWorksheet } from "../excel.utils";

export async function getColumnIdByTitle(title: string): Promise<string | null> {
  return Excel.run(async (context) => {
    try {
    const sheet = await getAddInWorksheet(context)

    let range = sheet.getUsedRange();
    range.load("address");
    await context.sync();


    const r = range.address.split(':'); // example "Sheet1!B1:F934"
    let lastCell = r.length > 0 ? r[1] : '';

    if (!lastCell) {
      lastCell = r[0].split('!')[1];

      if (!lastCell) {
        return null;
      }
    }

    const lastCellLetter = lastCell.replace(/[0-9]/g, '');

    // Get the first row from the sheet (assuming it contains the headers)
    const rangeAddress = `A1:${lastCellLetter}1`;
    const headerRange = sheet.getRange(rangeAddress); // Get the entire first row
    headerRange.load("values"); // Load the values in the first row

    await context.sync();

    // Check if the first row actually has data
    if (!headerRange.values || headerRange.values.length === 0 || !headerRange.values[0]) {
      console.warn("The first row is empty or data could not be retrieved.");
      return null; // No data found in the first row
    }

    const headers = headerRange.values[0]; // Get the first row (headers)

    // Iterate through the headers and perform a case-insensitive search for the title
    for (let colIndex = 0; colIndex < headers.length; colIndex++) {
      if (typeof headers[colIndex] === "string" && headers[colIndex].toLowerCase() === title.toLowerCase()) {
        // If a match is found, return the column letter
        return getColumnIdFromIndex(colIndex + 1); // Convert 0-based index to 1-based index for Excel
      }
    }

    // Return null if no match was found
    return null;
    } catch (error) {
      captureException(error);
      return null;
    }
  });
}

// Helper function to convert a 1-based column index (e.g., 1 = A, 2 = B) to the corresponding column letter
function getColumnIdFromIndex(colIndex: number): string {
  let letter = "";
  while (colIndex > 0) {
    const mod = (colIndex - 1) % 26;
    letter = String.fromCharCode(65 + mod) + letter;
    colIndex = Math.floor((colIndex - 1) / 26);
  }
  return letter;
}
