import { ObjectPrivilege } from "@/types/enums"
import { useFormContext } from "react-hook-form";
import { FormValues } from "../ShareModal.types";


export const PrivilegeSelector = () => {
  const { watch, setValue } = useFormContext<FormValues>();
  const selected = watch('privilege')

  const options = [
    {
      label: 'View only',
      value: ObjectPrivilege.Read
    },
    {
      label: 'Owner',
      value: ObjectPrivilege.Owner
    },
  ]

  return (
    <div className="flex gap-[1.375rem] text-[#FBFBFD] text-[0.875rem] font-medium">
      {options.map(option => {
        const isSelected = option.value === selected;;

        return (
          <div className="cursor-pointer flex gap-2 items-center" onClick={() => setValue('privilege',option.value)}>
            <Circle selected={isSelected} />
            {option.label}
          </div>
        )
      })}
    </div>
  )
}

const Circle = ({ selected }: { selected: boolean }) => (
  <div className="w-[0.875rem] h-[0.875rem] flex border-[0.0938rem] border-[#A5A6C9] rounded-full">
    {selected && (
      <div className="w-[0.3125rem] h-[0.3125rem] bg-[#A5A6C9] rounded-full m-auto" />
    )}
  </div>
)