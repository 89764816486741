import { ReactNode, forwardRef } from "react";
import { useTabsContext } from "./tabs";
import clsx from "clsx";
import { TabStylesMap } from "./tabs.types";

export const Tab = forwardRef<HTMLDivElement, {
  children: ReactNode
  value: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
}>(({
  children,
  value,
  className,
  active,
  ...rest
}, ref) => {
  const { active: activeTab, setActiveTab, variant, style } = useTabsContext();
  const isActive = typeof active === 'boolean' ? active : activeTab === value;

  const css: TabStylesMap = {
    default: {
      default: clsx(
        'bg-[#333557] flex flex-row gap-[0.375rem] items-center justify-between pl-[0.625rem] pr-[0.375rem] py-[0.625rem] rounded-t-[0.625rem] sm:gap-[0.625rem] sm:min-w-[9rem] sm:pl-[1.25rem] sm:pr-[0.625rem] h-[3.5rem] cursor-pointer', {
        'opacity-40 hover:opacity-100': !isActive,
      }),
      dark: 'TODO', // implement if needed
    },
    row: {
      default: clsx('p-1  text-[#FBFBFD] text-[0.875rem] leading-4 font-medium text-left rounded-[0.25rem] cursor-pointer', {
        'bg-[#1F2034]': isActive,
        'hover:bg-[#1F2034]': !isActive,
      }),
      dark: clsx('p-1  text-[#FBFBFD] text-[0.875rem] leading-4 font-medium text-left rounded-[0.25rem] cursor-pointer', {
        'bg-[#333557]': isActive,
        'hover:bg-[#333557]': !isActive,
      })
      
    }
  }

  return (
    <div
      ref={ref}
      className={clsx(css[variant][style], className)}
      onClick={() => setActiveTab?.(value)}
      {...rest}
    >
      {children}
    </div>
  )
})