import { ColumnConfigMapType, PriceType, UIMode } from "@/types/types";
import { Side } from "../data/api";
import { ReactNode } from "react";
import { PercentilesButton } from "../components/table/components/percentilesButton";

export const NO_BONDS_FOR_FILTERS = 'No bonds found matching filter criteria';

export enum IssuerColumn {
  SelectCheckbox = 'SelectCheckbox',
  Alert = 'Alert',
  Cusip = 'Cusip',
  Ticker = 'Ticker',
  Coupon = 'Coupon',
  Maturity = 'Maturity',
  AmountOutstanding = 'AmountOutstanding',
  SAndPRating = 'SAndPRating',
  Tenor = 'Tenor',
  Series = 'Series',
  Percentiles = 'Percentiles',
  BidSpd = 'BidGSpd',
  OfferSpd = 'OfferGSpd',
  BidSpdChange = 'BidGSpdChange',
  BYTM = 'BYTM',
  BYTMChange = 'BYTMChange',
  BidPx = 'BidPx',
  OfferPx = 'OfferPx',
  BidPxChange = 'BidPxChange',
  BidSpdPrev = 'BidGspdPrev',
  BYTMPrev = 'BYTMPrev',
  BidPxPrev = 'BidPxPrev',
}

// text for column titles
export const IssuerColumnTitle: Record<IssuerColumn, ReactNode> = {
  [IssuerColumn.SelectCheckbox]: '',
  [IssuerColumn.Alert]: '',
  [IssuerColumn.Cusip]: 'Cusip',
  [IssuerColumn.Ticker]: 'Ticker',
  [IssuerColumn.Coupon]: 'Coupon',
  [IssuerColumn.Maturity]: 'Maturity',
  [IssuerColumn.SAndPRating]: 'S&P',
  [IssuerColumn.Tenor]: 'Benchmark',
  [IssuerColumn.Series]: 'Series',
  [IssuerColumn.AmountOutstanding]: 'Outstanding',
  [IssuerColumn.Percentiles]: <PercentilesButton />,
  [IssuerColumn.BidSpd]: 'Bid Spread',
  [IssuerColumn.OfferSpd]: 'Offer Spread',
  [IssuerColumn.BidSpdChange]: 'Bid Spread Chng',
  [IssuerColumn.BYTM]: 'B YTM',
  [IssuerColumn.BYTMChange]: 'B YTM Chng',
  [IssuerColumn.BidPx]: 'Bid Px',
  [IssuerColumn.OfferPx]: 'Offer Px',
  [IssuerColumn.BidPxChange]: 'Bid Px Chng',
  [IssuerColumn.BidSpdPrev]: 'Bid Spread {prevPeriod}',
  [IssuerColumn.BYTMPrev]: 'B YTM {prevPeriod}',
  [IssuerColumn.BidPxPrev]: 'Bid Px {prevPeriod}',
}

export const IGIssuerColumnOrder: IssuerColumn[] = [
  IssuerColumn.SelectCheckbox,
  IssuerColumn.Alert,
  IssuerColumn.Cusip,
  IssuerColumn.Ticker,
  IssuerColumn.Coupon,
  IssuerColumn.Maturity,
  IssuerColumn.SAndPRating,
  IssuerColumn.Series,
  IssuerColumn.AmountOutstanding,
  IssuerColumn.Tenor,
  IssuerColumn.Percentiles,
  IssuerColumn.BidSpd,
  IssuerColumn.OfferSpd,
  IssuerColumn.BidSpdChange,
  IssuerColumn.BYTM,
  IssuerColumn.BYTMChange,
  // IssuerColumn.BidPx,
  // IssuerColumn.BidPxChange,
  IssuerColumn.BidSpdPrev,
  IssuerColumn.BYTMPrev,
  // IssuerColumn.BidPxPrev,
]

export const HYIssuerColumnOrder: IssuerColumn[] = [
  IssuerColumn.SelectCheckbox,
  IssuerColumn.Alert,
  IssuerColumn.Cusip,
  IssuerColumn.Ticker,
  IssuerColumn.Coupon,
  IssuerColumn.Maturity,
  IssuerColumn.SAndPRating,
  IssuerColumn.Series,
  IssuerColumn.AmountOutstanding,
  IssuerColumn.Percentiles,
  IssuerColumn.BidPx,
  IssuerColumn.OfferPx,
  IssuerColumn.BidPxChange,
  // IssuerColumn.BYTM,
  // IssuerColumn.BYTMChange,
  // IssuerColumn.BidSpd,
  // IssuerColumn.BidSpdChange,
  IssuerColumn.BidPxPrev,
  // IssuerColumn.BYTMPrev,
  // IssuerColumn.BidSpdPrev,
]

export const ColumnsOrderConfig: Record<UIMode, IssuerColumn[]>  = {
  [UIMode.InvestmentGrade]: IGIssuerColumnOrder,
  [UIMode.HighYield]: HYIssuerColumnOrder,
}


export const ColumnConfigMap: ColumnConfigMapType<IssuerColumn> = {
  [IssuerColumn.SelectCheckbox]: null,
  [IssuerColumn.Alert]: null,
  [IssuerColumn.Cusip]: null,
  [IssuerColumn.Ticker]: null,
  [IssuerColumn.Coupon]: null,
  [IssuerColumn.Maturity]: null,
  [IssuerColumn.SAndPRating]: null,
  [IssuerColumn.Series]: null,
  [IssuerColumn.Tenor]: null,
  [IssuerColumn.AmountOutstanding]: null,
  [IssuerColumn.Percentiles]: null,
  [IssuerColumn.BidSpd]: { 
    side: Side.bid,
    priceType: PriceType.Spread,
  },
  [IssuerColumn.OfferSpd]: {
    side: Side.offer,
    priceType: PriceType.Spread,
  },
  [IssuerColumn.BidSpdChange]: {
    side: Side.bid,
    priceType: PriceType.Spread,
  },
  [IssuerColumn.BYTM]: {
    side: Side.bid,
    priceType: PriceType.Ytm,
  },
  [IssuerColumn.BYTMChange]: {
    side: Side.bid,
    priceType: PriceType.Ytm,
  },
  [IssuerColumn.BidPx]: {
    side: Side.bid,
    priceType: PriceType.Price,
  },
  [IssuerColumn.OfferPx]: {
    side: Side.offer,
    priceType: PriceType.Price,
  },
  [IssuerColumn.BidPxChange]: {
    side: Side.bid,
    priceType: PriceType.Price,
  },
  [IssuerColumn.BidSpdPrev]: {
    side: Side.bid,
    priceType: PriceType.Spread,
  },
  [IssuerColumn.BYTMPrev]:  {
    side: Side.bid,
    priceType: PriceType.Ytm,
  },
  [IssuerColumn.BidPxPrev]: {
    side: Side.bid,
    priceType: PriceType.Price,
  },
}