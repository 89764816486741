import { ObjectPrivilege } from "@/types/enums";

export const isReadonly = (privilege: ObjectPrivilege | undefined): boolean => {
  if (!privilege) {
    return false
  }

  return privilege === ObjectPrivilege.Read
}

export const canEdit = (privilege: ObjectPrivilege | undefined): boolean => {
  if (!privilege) {
    return false
  }

  return privilege === ObjectPrivilege.Owner || privilege === ObjectPrivilege.Write
}

export const canDelete = (privilege: ObjectPrivilege | undefined): boolean => {
  if (!privilege) {
    return false
  }

  return privilege === ObjectPrivilege.Owner
}