import TableListBoxV2Base from "@/app/components/listbox/tableListBoxV2/tableListBoxV2Base"
import { AlertTargetType } from "@/app/data/api";
import { AlertTableItem } from "../alertsTable";

export const AlertTargetTypeDropdown = ({
  alert,
  onChange,
}: {
  alert: AlertTableItem['alert'],
  onChange: (data: { id: string, targetType: AlertTargetType }) => void;
}) => {

  function convertGspreadToSpread(targetType: AlertTargetType) {
    return targetType === AlertTargetType.spread ? 'spread' : targetType;
  }

  function convertSpreadToGspread(targetType: string) {
    return targetType === 'spread' ? AlertTargetType.spread : targetType as AlertTargetType;
  }

  const targetTypeOptions = Object.values(AlertTargetType).map(convertGspreadToSpread);

  return (
    <TableListBoxV2Base
      value={convertGspreadToSpread(alert.targetType)}
      options={targetTypeOptions}
      selectValue={v => {
        onChange({ id: alert.id, targetType: convertSpreadToGspread(v) })
      }}
    />
  )
}