import { Side } from '@/app/data/api';
import { Bond } from '@/app/data/bondIndex';
import { InferenceResult } from '@/hooks/data/useSimpleInferenceData';
import { PriceType } from '@/types/types';

import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  ChartOptions,
  ChartData,
  TimeScale,
} from 'chart.js';
import {
  Chart,
  getElementAtEvent,
} from 'react-chartjs-2';
import { MouseEvent, useMemo, useRef } from 'react';
import { formatPrice } from '@/utils/number.utils';
import { PriceTypeLabel } from '@/app/bond/bond.constants';
import { isEmpty } from 'lodash';
import { LoadingOverlay } from '@/app/loading';
import clsx from 'clsx';
import { useOpenPage } from '@/hooks/useOpenPage';
import { PointData } from './curveChart.types';
import { InteractionItem } from 'chart.js';
import { getFormatConfig } from '../../bondsChart.utils';
import { ONE_DAY } from '@/constants';
import { externalTooltipHandler } from '../chart/chart.tooltip';
import { getTooltipLabels } from '../chart/chart.utils';
import { useChartHeight } from '../chart/hooks/useChartHeight';

ChartJS.register(TimeScale);

const plugins = [
  BarElement,
  Tooltip,
]

const colors = {
  yColor: '#2E65A0',
  xColor: '#5D5F9D',
}

export const CurveChart = ({
  bonds,
  inferenceResult,
  priceType,
  side,
  delta,
  className,
}: {
  bonds: Bond[];
  inferenceResult: InferenceResult<Bond>['data'];
  priceType: PriceType;
  side: Side;
  delta: boolean;
  className?: string
}) => {
  const openPage = useOpenPage();
  const chartHeight = useChartHeight();

  // generate chart data
  const { chartData, data } = useMemo(() => {
    const data = (bonds || []).map((b, idx) => {
      const inferenceData = inferenceResult[b.figi][side][priceType] || {}
      const { current, diff, quantiles = [] } = inferenceData;
      const y = delta ? diff : current;

      let fifthPercentile: number | undefined;
      let ninetyFifthPercentile: number | undefined;

      if (!delta) {
        // calculate 5/95th percentiles only if we are not in delta mode
        const isBidSpread = side === Side.bid && priceType === PriceType.Spread;
        const isOfferPrice = side === Side.offer && priceType === PriceType.Price;

        if (isBidSpread || isOfferPrice) {
          // we need to get opposite quantiles for bid spread and offer price
          fifthPercentile = quantiles[quantiles.length - 1];
          ninetyFifthPercentile = quantiles[0];
        } else {
          fifthPercentile = quantiles[0];
          ninetyFifthPercentile = quantiles[quantiles.length - 1];
        }
      }

      if (typeof y === 'undefined') {
        // y value is requried;
        return null;
      }


      const d: PointData = {
        id: b.figi,
        bond: b,
        inferenceData,
        y,
        x: new Date(b.maturity).getTime(),
        fifthPercentile,
        ninetyFifthPercentile,
      }

      return d;

    }).filter(Boolean)


    const chartData: ChartData<"scatter", any> = {
      datasets: [{
        data,
        type: 'scatter',
        borderWidth: 2,
        pointRadius: 5,
        borderColor: '#C9CADE',
        pointHoverRadius: 6,
        hoverBorderWidth: 2,
      }]
    }

    return { chartData, data };
  }, [bonds, side, inferenceResult, priceType, delta])

  // chart options
  const options = useMemo(() => {
    const formatConfig = getFormatConfig(priceType, delta)

    const options: ChartOptions<'scatter'> = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          border: {
            display: false,
          },
          grid: {
            display: true,
            color: colors.yColor,
            drawTicks: false,
            drawOnChartArea: true,
          },
          beginAtZero: false,
          ticks: {
            padding: 10,
            align: 'center',
            crossAlign: 'far',
            color: colors.yColor,
            minRotation: 0, // set min/max rotation to same value to make performance better
            maxRotation: 0,
            callback: function (value) {
              return typeof value === 'number' ? formatPrice(value, priceType, formatConfig) : value;
            }
          },
        },
        x: {
          border: {
            display: false,
          },
          grid: {
            display: true,
            color: colors.xColor,
            drawTicks: false,
            drawOnChartArea: true,
          },
          ticks: {
            color: colors.xColor,
            padding: 10,
            // sampleSize: dataRef.current.length,
            align: 'center',
            stepSize: ONE_DAY * 365,

            
            callback: function (value) {
              const d1 = new Date(value).getFullYear()
              const d2 = new Date().getFullYear()
              const diff = d1 - d2;

              return diff <= 0 ? '' : `${diff}Y`; 
            }
          },
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: () => '',
            label: (tooltipItem) => {
              return getTooltipLabels({
                tooltipItem: tooltipItem, 
                priceType, 
                delta, 
                formatConfig, 
                isFloatingBarsMode: false,
              }) as string[]; // we implement custom tooltip in chart.tooltip.ts which handles custom tooltip data type `CustomLabel`. Assigning string[] here to make typescript happy
            }
          },
          caretPadding: 10,
          displayColors: false,
          position: 'nearest',
          enabled: false,
          external: externalTooltipHandler
        },
      }
    };
    return options;
  }, [priceType, delta])



  // calculated values
  const chartTitle = <>{PriceTypeLabel[priceType]} {delta && <>&#916;</>}</>
  const loading = isEmpty(data);

  const chartRef = useRef<ChartJS>(null);

  // returns point data 
  const getPointDataOnEvent = (element: InteractionItem[]): PointData | undefined => {
    if (!element.length) return;
    const { datasetIndex, index } = element[0];
    return chartData.datasets[datasetIndex].data[index];
  };


  const handleChartClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const data = getPointDataOnEvent(getElementAtEvent(chart, event)); // get point data

    if (!data) {
      return;
    }

    openPage.bond(data.bond) // open bond page
  };


  return (
    <div className={clsx('relative', className)}>
      <div className="relative pt-8">
        <h4 className='absolute top-0 left-1/2 -translate-x-1/2'>
          {chartTitle}
        </h4>
      </div>
      {loading && <LoadingOverlay className="rounded-lg" />}
      <div className='overflow-x-auto relative'>
        <Chart
          ref={chartRef as any}
          type="scatter"
          onClick={handleChartClick}
          plugins={plugins}
          options={options}
          data={chartData}
          // height={800}
          style={{
            height: chartHeight
          }}
        />
      </div>
    </div>
  )
}

