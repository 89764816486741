import { Fragment, FunctionComponent, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useAddToRun, useCreateRun } from '../data/runs';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useDataContext } from '../data/dataProvider';
import Loading from '../loading';
import ActionWithInputModal from '../components/modal/actionWithInputModal';
import Modal from '../components/modal/modal';
import { useGetRunListQuery } from '@/store/api/run.endpoints';
import { openConfirmationDialogPromise } from '../components/confirmationDialog/confirmationDialog.utils';
import { WARN_ABOUT_MANY_ITEMS_LIMIT } from '@/constants';
import { canEdit } from '@/utils/privilege.utils';
import clsx from 'clsx';

// if figi is provided, it is used instead of the global selectedBonds
const AddToRun: FunctionComponent<{ figi?: string }> = ({ figi }) => {

  const { selectedBonds, resetSelectedBonds } = useDataContext();
  const { data: runs, isLoading } = useGetRunListQuery();
  const runToName = useMemo(() => (runs || []).reduce((a: { [key: string]: string }, c) => { a[c.id] = JSON.parse(c.metadata)['name']; return a; }, {}), [runs]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [targetRunName, setTargetRunName] = useState<string>('');
  const [bondCount, setBondCount] = useState<number>(0);

  const addToRun = useAddToRun();

  // create
  const createRun = useCreateRun();
  const [createName, setCreateName] = useState<string>('');
  const [showCreate, setShowCreate] = useState<boolean>(false);

  const figisToAdd = (figi && figi.length) ? new Set([figi]) : selectedBonds;

  const sortedRuns = useMemo(() => {
    return [...(runs || [])].sort((a, b) => runToName[a.id].localeCompare(runToName[b.id]))
  }, [runs, runToName])

  const confirmAddManyBonds = async () => {
    return openConfirmationDialogPromise({
      title: <>Do you want to add <span className='text-[#fff] font-bold'>{figisToAdd.size}</span> bonds to run?</>,
      buttonText: 'Proceed',
    })
  }

  return (
    <>
      {
        figisToAdd.size > 0
          ? <Menu as="div" className="inline-block relative">
            {({ open }) =>
              <>
                <div>
                  <Menu.Button className={clsx(open ? 'bg-[#8183B3] rounded-t-[0.375rem]' : 'bg-[#484A7A] rounded-[0.375rem]', 'inline-flex justify-center px-[1.75rem] py-[0.875rem] text-[0.75rem] w-full focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-[#4384C8]')}>
                    Add to Run
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute bg-[#5D5F9D] divide-y divide-[#8183B3] max-h-[16rem] overflow-y-auto left-0 origin-top-left ring-1 ring-black/5 rounded-b-[0.375rem] rounded-tr-[0.375rem] shadow-lg w-[17rem] z-[3] focus:outline-none">
                    <Menu.Item key='__create_new__'>
                      {({ active }) => (
                        <button
                          className={clsx(active ? 'bg-[#484A7A]' : '', 'flex flex-row place-content-between px-[0.875rem] py-[0.625rem] text-[0.875rem] w-full')}
                          onClick={async () => {
                            if (figisToAdd.size > WARN_ABOUT_MANY_ITEMS_LIMIT) {
                              if (!await confirmAddManyBonds()) { // ask user for confirmation if many items
                                return;
                              }
                            }

                            setCreateName('');
                            setShowCreate(true);
                          }}
                        >
                          <span className="text-left truncate w-[13rem]">Create New Run</span>
                        </button>
                      )}
                    </Menu.Item>

                    {
                      isLoading ?
                        <Loading className="py-[0.625rem] w-[17rem]" />
                        : sortedRuns.map(r =>
                          <Menu.Item key={r.id} disabled={!canEdit(r.privilege)}>
                            {({ active, disabled }) => (
                              <button
                                className={clsx('flex flex-row place-content-between px-[0.875rem] py-[0.625rem] text-[0.875rem] w-full  text-[#FBFBFD]', {
                                  'bg-[#484A7A]': active,
                                  'text-opacity-50 cursor-not-allowed': disabled,
                                })}
                                onClick={async () => {
                                  if (!figisToAdd.size) {
                                    return;
                                  }

                                  if (figisToAdd.size > WARN_ABOUT_MANY_ITEMS_LIMIT) {
                                    if (!await confirmAddManyBonds()) { // ask user for confirmation if many items
                                      return;
                                    }
                                  }

                                  setBondCount(figisToAdd.size);
                                  setTargetRunName(runToName[r.id]);
                                  setProcessing(true);
                                  addToRun(r.id, figisToAdd).finally(() => {
                                    setProcessing(false);
                                    setTargetRunName('');
                                    setBondCount(0);
                                    if (!figi) {
                                      resetSelectedBonds();
                                    }
                                  });
                                }}
                              >
                                <span className="text-left truncate w-[13rem]">{JSON.parse(r.metadata)['name']}</span>
                                <PlusIcon className='h-[1.25rem] w-[1.25rem]' />
                              </button>
                            )}
                          </Menu.Item>
                        )
                    }
                  </Menu.Items>
                </Transition>
              </>
            }
          </Menu>
          : <div className="bg-[#8183B3]/[0.1] inline-flex justify-center px-[1.75rem] py-[0.875rem] rounded-[0.375rem] select-none text-[#7D7D82] text-[0.75rem]">
            Add to Run
          </div>
      }
      <ActionWithInputModal
        action={async () => {
          const result = await createRun(createName, figisToAdd);
          if (!result) {
            return false; // prevent modal from closing
          }

          // clear selection if it's a multi-add
          if (!figi) {
            resetSelectedBonds();
          }
        }}
        actionName='Create'
        disabled={!createName}
        label='New Run Name'
        placeholder='Name'
        setShow={setShowCreate}
        setValue={setCreateName}
        show={showCreate}
        value={createName}
        title='Create Run'
      />
      <Modal
        body={
          <div className="pt-[0.625rem] w-full">
            <Loading />
            <button aria-hidden="true" className="visually-hidden"></button>{/* hidden button to avoid FocusTrap warning */}
          </div>
        }
        show={processing}
        title={
          <div className="truncate w-full">Adding {bondCount} bond{bondCount > 1 ? 's' : ''} to {targetRunName}</div>
        } />
    </>
  )
}

export default AddToRun;
