import { DateObjectUnits, DateTime, DurationLike, DurationLikeObject } from "luxon";

export enum TimePeriod {
  '3H' = '3H',
  '1D' = '1D',
  '5D' = '5D',
  '1M' = '1M',
  '3M' = '3M',
  '6M' = '6M',
  '1Y' = '1Y',
  '3Y' = '3Y',
  '5Y' = '5Y',
  '10Y' = '10Y',
  '20Y' = '20Y',
  '30Y' = '30Y',
  '40Y' = '40Y',
  '50Y' = '50Y',
  'Max' = 'Max',
  'Custom' = 'Custom',
}

export const SELECTABLE_TIME_PERIODS = [
  TimePeriod["1D"],
  TimePeriod["5D"],
  TimePeriod["1M"],
  TimePeriod["3M"],
  TimePeriod["6M"],
  TimePeriod["1Y"],
  TimePeriod["3Y"],
  TimePeriod["5Y"],
  TimePeriod.Max,
  TimePeriod.Custom,
]

export type PeriodConfig = {
  minus: DurationLike,
  step: DurationLikeObject
  startDate?: Date;
  endDate?: Date;
  getInitialTime: (d?: DateTime) => DateTime
  setHour?: (d: DateTime) => DateTime
}

const HOUR_CONFIG: DateObjectUnits = {
  hour: 17,
}

const setHour = (d: DateTime) => {
  return d.set(HOUR_CONFIG);
}

const getHourRangeStartTime = (d?: DateTime) => {
  return (d || DateTime.now()).startOf('hour');
}

const getDayRangeStartTime = (d?: DateTime) => {
  return (d || DateTime.now()).startOf('day').set(HOUR_CONFIG);
}

const getMonthRangeStartTime = (d?: DateTime) => {
  return (d || DateTime.now()).startOf('month').set(HOUR_CONFIG);
}

export const TIME_PERIOD_CONFIG: Record<TimePeriod, PeriodConfig | null> = {
  [TimePeriod["3H"]]: {
    minus: { hours: 3 },
    step: { minute: 1 },
    getInitialTime: (d?: DateTime) => (d || DateTime.now()).startOf('minute'),
  },
  [TimePeriod["1D"]]: {
    minus: { days: 1 },
    step: { minute: 10 },
    getInitialTime: () => {
      // we get start of last 10th minute, 10, 20, 30, 40 ,50, 00
      const  d = DateTime.now().startOf('minute')
      const minute = d.get('minute');
      const newMinute = minute - (minute % 10);
      return d.set({ minute: newMinute });
    }, 
  },
  [TimePeriod["5D"]]: {
    minus: { days: 5 },
    step: { hour: 1 },
    getInitialTime: getHourRangeStartTime,
  },
  [TimePeriod["1M"]]: {
    minus: { months: 1 },
    step: { hour: 4 },
    getInitialTime: getHourRangeStartTime,
    setHour,
  },
  [TimePeriod["3M"]]: {
    minus: { months: 3 },
    step: { day: 1 },
    getInitialTime: getDayRangeStartTime, 
    setHour,
  },
  [TimePeriod["6M"]]: {
    minus: { months: 6 },
    step: { day: 1 },
    getInitialTime: getDayRangeStartTime,
    setHour
  },
  [TimePeriod["1Y"]]: {
    minus: { years: 1 },
    step: { day: 1 },
    getInitialTime: getDayRangeStartTime,
    setHour
  },
  [TimePeriod["3Y"]]: {
    minus: { years: 3 },
    step: { weeks: 1 },
    getInitialTime: getDayRangeStartTime, 
    setHour
  },
  [TimePeriod["5Y"]]: {
    minus: { years: 5 },
    step: { months: 1 },
    getInitialTime: getMonthRangeStartTime,
    setHour
  },
  [TimePeriod["10Y"]]: {
    minus: { years: 10 },
    step: { months: 1 },
    getInitialTime: getMonthRangeStartTime,
    setHour
  },
  [TimePeriod["20Y"]]: {
    minus: { years: 20 },
    step: { months: 1 },
    getInitialTime: getMonthRangeStartTime,
    setHour
  },
  [TimePeriod["30Y"]]: {
    minus: { years: 30 },
    step: { months: 1 },
    getInitialTime: getMonthRangeStartTime,
    setHour
  },
  [TimePeriod["40Y"]]: {
    minus: { years: 40 },
    step: { months: 2 },
    getInitialTime: getMonthRangeStartTime,
    setHour
  },
  [TimePeriod["50Y"]]: {
    minus: { years: 50 },
    step: { months: 2 },
    getInitialTime: getMonthRangeStartTime,
    setHour,
  },
  [TimePeriod.Custom]: null,
  [TimePeriod.Max]: null,
}