import { useEffect, useMemo, useState } from "react";
import { Column } from "../table";

export type Sort = 'ascending' | 'descending' | 'none';
export type SortItem<Id = string> = {
  id: Id;
  column: Id;
  direction: Sort;
}

type SortFunc = <T,>(a: T, b: T) => number
type SortConfig = { sortFunc: SortFunc, direction: Sort }

export const useTableSort  = <T, Id extends string = string>({
  columns,
  generateItemKey,
  items,
  initialSort,
}: {
  columns: Column<T, Id>[];
  generateItemKey: (a: T) => string;
  items: any[];
  initialSort: SortItem<Id>[];
}) => {
  const [sort, setSort] = useState<SortItem<Id>[]>(initialSort);


  useEffect(() => {
    // remove sort for columns that are no longer in the columns list
    const colKeys = columns.map(c => c.id).reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {} as Record<Id, boolean>);

    const newSort = sort.filter(s => colKeys[s.column]);
    if (newSort.length !== sort.length) {
      setSort(newSort);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns])

  
  const sortFunction = useMemo(() => {
    const sortConfigs = sort.map(s => {
      const column = columns.find(c => c.id === s.column);
      return column 
        ? { sortFunc: column.sort, direction: s.direction } 
        : undefined;
    }).filter(sortConfigGuard);

    return sortConfigs.length 
      ? createKeyFallbackSort(sortConfigs, generateItemKey)
      : undefined;
  }, [columns, generateItemKey, sort]);

  // sort items
  const sortedItems = useMemo(() => {
    const _items = [...items];
    if (sortFunction) {
      _items.sort(sortFunction);
    }
    return _items;
  },[items, sortFunction]);

  // toggleSort
  function toggleSort(column: Id) {
    if (!sort.find(s => s.column === column)) {
      setSort([
        ...sort,
        { id: column, column, direction: 'ascending' },
      ]);
    } else {
      const newSort = sort.map(s => {
        return s.column === column 
          ? { ...s, direction: s.direction === 'ascending' ? 'descending' as const : 'ascending' as const } 
          : s
      })
      setSort(newSort);
    }
  }

  // remove sort
  function removeColumnSort(column: Id) {
    setSort(s => s.filter(s => s.column !== column));
  }

  // resetSort
  function resetSort() {
    setSort(initialSort);
  }

  return {
    sort,
    sortedItems,
    toggleSort,
    resetSort,
    removeColumnSort,
    setSort,
  }
}

function sortConfigGuard(item: any): item is SortConfig {
  if (!item) return false;
  return 'sortFunc' in item && 'direction' in item;
}

const createKeyFallbackSort = <T,>(sortConfigs: SortConfig[], generateItemKey: (a: T) => string) => {
  // create these here so we don't do as much computation during each sort comparison
  const directionalSorts = sortConfigs.map(c => {
    return c.direction === 'descending' ? (a: T, b: T) => -1 * c.sortFunc(a, b) : c.sortFunc;
  })
  
  const fallbackSort = (a: T, b: T) => generateItemKey(a).localeCompare(generateItemKey(b));
  
  return (a: T, b: T) => {
    for (const sort of directionalSorts) {
      const result = sort(a, b);
      if (result !== 0) {
        return result;
      }
    }

    return fallbackSort(a, b);
  };
}