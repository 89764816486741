import { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { FaChevronRight } from 'react-icons/fa6';

export type MenuItem = {
  ariaLabel: string;
  Item: () => ReactNode; // render item contents
  itemCss?: ({ active, disabled }: { active: boolean, disabled: boolean }) => string; // optional css applied to item
  key: string; // unique key for this option
  disabled?: boolean; // is this option disabled
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // onClick handler for button
  subMenu?: MenuItem[]; // sub menu items
};

type StyledMenuProps = {
  ariaLabel: string;
  Button: () => ReactNode; // render button contents
  buttonCss?: string; // optional css applied to button
  menuCss?: string; // optional css applied to the Menu
  menuItems: MenuItem[];
  menuItemsCss?: string; // optional css applied to Menu.Items
};

const StyledMenu = ({
  ariaLabel,
  buttonCss,
  menuCss,
  menuItems,
  menuItemsCss,
  Button
}: StyledMenuProps) =>
  <Menu
    as='div'
    className={clsx(
      'relative',
      menuCss,
    )}
  >
    <Menu.Button
      aria-label={ariaLabel}
      onClick={e => e.stopPropagation()}
      className={clsx(
        "p-[0.625rem] rounded-[0.625rem]",
        buttonCss,
      )}
    >
      { Button() }
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={clsx(
          "absolute mt-[0.25rem] origin-top-right right-0 rounded-[0.3125rem] w-[9rem] z-[3] focus:outline-none",
          menuItemsCss,
        )}
      >
        {
          menuItems.map(item =>
            <Menu.Item
              aria-label={item.ariaLabel}
              as='button'
              disabled={item.disabled}
              className={({ active, disabled }) => clsx(
                'relative',
                'group/menu-parent',
                active ? 'bg-[#484A7A]' : 'bg-[#5D5F9D]',
                'flex items-center justify-between px-[0.625rem] py-[0.4375rem] text-left text-[0.875rem] w-full',
                item.itemCss ? item.itemCss({ active, disabled }) : '',
                'first:rounded-t-[0.3125rem] last:rounded-b-[0.3125rem]',
                {
                  'hover:first:rounded-tr-[0rem]': item.subMenu,
                  'hover:last:rounded-br-[0rem]': item.subMenu,
                  'text-[#FBFBFD]': !item.disabled,
                  'cursor-not-allowed text-[#FBFBFD5E]': item.disabled,
                }
              )}
              key={item.key}
              onClick={(e) => {
                if (item.disabled) {
                  return;
                }

                if (!(e.target as HTMLElement).closest('[data-submenu]')) {
                  item.onClick?.(e);
                }
              }}
            >
              {item.Item()}
              {item.subMenu && <FaChevronRight />}
              {item.subMenu && renderSubmenu(item.subMenu)}
            </Menu.Item>
          )
        }
      </Menu.Items>
    </Transition>
  </Menu>;

export default StyledMenu;


const renderSubmenu = (menu: MenuItem[]) => {
  return (
    <div className='absolute left-full top-0 hidden group-hover/menu-parent:block' data-submenu>
      {menu.map(item => {
        return (
          <div
            className={clsx(
              'whitespace-nowrap',
              'first:rounded-tr-[0.3125rem] last:rounded-br-[0.3125rem]',
              'bg-[#5D5F9D] hover:bg-[#484A7A] block px-[0.625rem] py-[0.4375rem] text-left text-[0.875rem] w-full',
              item.itemCss ? item.itemCss({ active: false, disabled: item.disabled || false }) : '',
              {
                'text-[#FBFBFD]': !item.disabled,
                'cursor-not-allowed text-[#FBFBFD5E]': item.disabled,
              }
            )}
            key={item.key}
            onClick={(e) => {
              if (item.disabled) {
                return;
              }

              item.onClick?.(e as any)
            }}
          >
            {item.Item()}
          </div>
        )
      })}
    </div>
  );
};