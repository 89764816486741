import { PrivilegeUser } from "@/app/data/objects"
import { FaUsers } from "react-icons/fa6"
import { UserItem } from "./UserItem";
import { UserItemLoading } from "./UserItemLoading";

export const UsersSection = ({
  users,
  loading,
  objectId,
}: {
  users: PrivilegeUser[],
  loading: boolean;
  objectId: string
}) => {
  return (
    <>
      <h4 className="flex gap-2.5 items-center text-[0.875rem] font-medium leading-[1rem] pb-2.5 border-b-[#484A7A] border-b-[0.0625rem] mb-[1.125rem]">
        <FaUsers size={18} />
        Users
      </h4>

      <div className="flex flex-col gap-[1.125rem] min-[601px]:max-h-[25rem] overflow-y-auto pr-[0.875rem] -mr-[0.875rem] flex-1">
        {loading && (
          <>
            <UserItemLoading />
            <UserItemLoading />
            <UserItemLoading />
          </>
        )}

        {!loading && users.map((user) => {
          return (
            <UserItem 
              key={user.id} 
              user={user} 
              users={users} 
              objectId={objectId}
            />
          )
        })}
      </div>
    </>
  )
}