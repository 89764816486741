import { ObjectPrivilege } from "@/types/enums";
import { canEdit } from "@/utils/privilege.utils";
import { memo, ReactNode } from "react"

export const PrivilegesProvider = memo(({
  children,
  privilege,
  Provider,
}: {
  children: ReactNode;
  privilege: ObjectPrivilege | undefined;
  Provider: any
}) => {
  const _canEdit = (): boolean => canEdit(privilege);

  return (
    <Provider value={{
      canEdit: _canEdit
    }}>
      {children}
    </Provider>
  )
})