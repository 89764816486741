import { ObjectPrivilege } from '@/types/enums';
import { DropdownWithRightCheck } from '@/app/components/listbox/DropdownWithRightCheck/DropdownWithRightCheck';
import { PrivilegeUser } from '@/app/data/objects';
import { useSelector } from 'react-redux';
import { selectCurrentUserEmail } from '@/store/slices/auth.slice';

export const PrivilegeDropdown = ({
  value,
  disabled,
  onChange,
  ...rest
}: {
  value: ObjectPrivilege,
  disabled?: boolean;
  onChange: (v: ObjectPrivilege) => void,
}) => {
  const options: ObjectPrivilege[] = [
    ObjectPrivilege.Owner,
    ObjectPrivilege.Read,
  ]

  const optionLabel: Record<ObjectPrivilege, string> = {
    [ObjectPrivilege.Owner]: 'Owner',
    [ObjectPrivilege.Read]: 'View',
    [ObjectPrivilege.Write]: 'Edit',
  }

  return (
    <div {...rest}>
      <DropdownWithRightCheck
        renderValue={({ value }) => optionLabel[value]}
        onChange={onChange}
        value={value}
        options={options}
        className='text-[#8183B3] hover:text-[#C9CADE]'
        activeClassName='text-[#C9CADE]'
        optionClassName='min-w-[10.3125rem]'
        disabled={disabled}
        // isOptionDisabled={(option) => {
        //   if (option === ObjectPrivilege.Read || option === ObjectPrivilege.Write) {

        //   }
        // }}
        popperProps={{
          placement: 'bottom-end',
        }}
      />
    </div>
  );
}

