import { CircleButton } from "@/app/components/buttons/CircleButton"
import ActionModal from "@/app/components/modal/actionModal";
import { NAV_FORMAT } from "@/constants";
import { usePortfolioPrivileges } from "@/contexts/privileges/components/PortfolioPrivilegesProvider";
import { numericFormatter } from "@/utils/number.utils";
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

export const EditPortfolioNAV = ({
  editing,
  setEditing,
  title,
  renderValue,
  nav,
  onNavChange,
  isFetchingPortfolio,
}: {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  title?: ReactNode;
  nav: number | null | undefined
  renderValue: (value: ReactNode) => ReactNode;
  onNavChange: (nav: number | undefined) => void;
  isFetchingPortfolio?: boolean;
}) => {
  const [localNav, setLocalNav] = useState(nav)
  const { canEdit } = usePortfolioPrivileges();

  useEffect(() => {
    setLocalNav(nav)
  }, [nav])

  const hasNav = typeof nav === 'number';
  

  if (isFetchingPortfolio) {
    return canEdit() ? '-' : null;
  }

  return (
    <div className={clsx({
      'm-auto self-center justify-self-center': !hasNav,
    })}>

      {/* Show add NAV button if NAV is missing */}
      {!hasNav && canEdit() && (
        <div className="flex lg:flex-col gap-[0.625rem] items-center">
          {/* invisible click handler */}
          <div onClick={() => setEditing(true)} className="absolute left-0 top-0 bottom-0 right-0 cursor-pointer" />
          <div className="rounded-full w-[1rem] h-[1rem] flex items-center justify-center bg-[#FBFBFD]  text-[#5D5F9D]">
            <PlusIcon width={16} />
          </div>

          <div className="text-[#FBFBFD] text-[0.875rem] font-medium">NAV</div>
        </div>
      )}

      {hasNav && (
        <>
          {title}
          {renderValue(
            <span className="flex items-center gap-1">
              {numericFormatter(nav, NAV_FORMAT)}

              {canEdit() && (
                <CircleButton onClick={() => setEditing(true)} p="p-0" size="h-5 w-5 lg:h-7 lg:w-7 mt-0.5" bg="bg-[#FBFBFD]">
                  <PencilSquareIcon width={16} color="#5D5F9D" />
                </CircleButton>
              )}
            </span>
          )}
        </>
      )}

      <ActionModal
        action={() => {
          let navValue = localNav ?? undefined
          if (typeof navValue === 'number' && navValue <= 0) {
            navValue = undefined
          }
          onNavChange(localNav ?? undefined)
        }}
        actionName='Save'
        onClose={() => setEditing(false)}
        show={editing}
        title='Edit NAV'
        body={(
          <NumericFormat
            {...NAV_FORMAT}
            allowNegative={false}
            value={localNav}
            onValueChange={(e) => setLocalNav(e.floatValue)}
            className={clsx("bg-[#5D5F9D] border-0 px-[1.25rem] py-[0.625rem]",
              "ring-0 rounded-full",
              "text-[1.25rem] text-[#FBFBFD] w-full",
              "focus:ring-2 focus:ring-inset focus:ring-[#4384C8] placeholder:text-[#C9CADE]/[0.5]")}
          />
        )}
      />
    </div>
  )
}