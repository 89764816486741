import { useState } from "react";
import ActionModal from "@/app/components/modal/actionModal";
import { DateTime } from "luxon";
import { DateTimeField } from "@/app/components/fields/date/DateTimeField/DateTimeField";
import TradeDateCalendar from "@/app/components/tradeDateCalendar";

export const CustomTimePeriodDrawer = ({
  open,
  onClose,
  onApply,
  maxDate,
  minDate,
}: {
  open: boolean;
  onClose: () => void;
  onApply: (startDate: number, endDate: number) => void;
  maxDate?: number;
  minDate?: number;
}) => {
  const now = DateTime.now().setZone('America/New_York').toMillis()
  const initialEndDate = Math.min(now, maxDate || Infinity);
  const initialStartDate = DateTime.fromMillis(initialEndDate, { zone: 'America/New_York' }).minus({ days: 2 }).startOf('day').toMillis();

  const [activeDate, setActiveDate] = useState<'start' | 'end'>('start');
  const [startDate, setStartDate] = useState<number | null>(initialStartDate);
  const [endDate, setEndDate] = useState<number | null>(initialEndDate);

  const buttonDisabled = !startDate || !endDate || startDate > endDate;

  const calendarValue = activeDate === 'start' ? startDate : endDate;
  const setCalendarValue = activeDate === 'start' ? setStartDate : setEndDate;

  return (
    <ActionModal
      show={open}
      onClose={onClose}
      closeOnlyWithButton
      size="content"
      fullScreen="(max-width: 500px)"
      overflow="y-auto"
      disabled={buttonDisabled}
      actionName="Apply"
      title="Custom Time Period"
      action={() => {
        if (buttonDisabled) return;

        onApply(startDate, endDate);
      }}
      body={(
        <div className="w-full sm:w-[23.75rem] sm:mx-auto">

          {/* period start  */}
          <div className="flex items-center gap-[0.625rem] ">

            <div className="flex  flex-col gap-[0.625rem] w-fit mb-5 ">

              <div className="flex items-center justify-between">
                <div className="min-w-[4rem]">
                Start
                </div>
                <DateTimeField
                  value={startDate}
                  min={minDate}
                  max={maxDate}
                  selected={activeDate === 'start'}
                  onChange={(v) => setStartDate(v)}
                  onClick={() => setActiveDate('start')}
                />
              </div>
              <div className="flex items-center justify-between">

              <div className="min-w-[4rem]">
                End
                </div>
                <DateTimeField
                  value={endDate}
                  min={minDate}
                  max={maxDate}
                  selected={activeDate === 'end'}
                  onChange={(v) => setEndDate(v)}
                  onClick={() => setActiveDate('end')}
                />
              </div>
            </div>
          </div>

          <div className="">
            <TradeDateCalendar
              value={calendarValue}
              selectValue={setCalendarValue}
            />
          </div>


        </div>
      )}
    />
  )
}