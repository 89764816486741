import { FaCircleCheck } from "react-icons/fa6";

export const CircleCheck = ({
  checked,
}: {
  checked: boolean;
}) => {
  return (
    <span className="inline-flex min-w-[0.875rem] w-[0.875rem] h-[0.875rem] border-[#FBFBFD] border-[0.0938rem] rounded-[50%] relative overflow-hidden">
      {checked && <FaCircleCheck size="1.125rem" className=" absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
    </span>
  )
}