import Modal from "@/app/components/modal/modal";
import { memo, useEffect, useState } from "react";
import { UsersSection } from "./components/UsersSection";
import { useGetObjectPrivilegeQuery, useLazyGetObjectPrivilegeQuery } from "@/store/api/object.endpoints";
import { AddUsersForm } from "./components/AddUsersForm";
import { LoadingOverlay } from "@/app/loading";
import { toast } from "react-toastify";
import { closeShareModal } from "@/store/actions/shareModal.actions";
import { useSelector } from "react-redux";
import { selectIsShareModalOpen, selectObjectId, selectObjectName, selectObjectType } from "@/store/slices/shareModal.slice";
import { isEmpty } from "lodash";

export const ShareModal = memo(() => {
  const show = useSelector(selectIsShareModalOpen);
  const objectId = useSelector(selectObjectId);
  const [addingUsers, setAddingUsers] = useState(false);

  const [fetchObjectPrivilegeApi, { currentData, isFetching }] = useLazyGetObjectPrivilegeQuery();
  const isLoading = isFetching && !currentData;
  
  const users = currentData || [];

  useEffect(() => {
    if (show && objectId) {
      fetchObjectPrivilegeApi(objectId);
    }
  }, [show, objectId])

  async function refetchPrivilegeList() {
    try {
      await fetchObjectPrivilegeApi(objectId).unwrap();
    } catch (error) {
      toast.error('Failed to update the user list, please refresh the page to get latest data');
    }
  }

  const handleClose = () => {
    if (addingUsers) {
      toast.warn('Please wait until the users are added');
    } else {
      closeShareModal();
    }
  }

  return (
    <Modal
      show={show}
      onClose={handleClose}
      closeOnlyWithButton
      size="content"
      padding="p-[1rem] sm:px-[2rem] sm:pt-[2rem] sm:pb-[1rem]"
      fullScreen="(max-width: 600px)"
      overflow="y-auto"
      body={
        <div className="w-[41.6875rem] max-w-full">
          <Title />
          <Subtitle />

          <AddUsersForm 
            objectId={objectId} 
            currnetUsers={users}
            refetchPrivilegeList={refetchPrivilegeList} 
            onSubmittingChange={setAddingUsers}
          />

          {isLoading && <LoadingOverlay bg="bg-[#33355796]" />}

          <div className="mb-[2.375rem]" />
          
          <div className="relative py-[1rem] -ml-[0.5rem] -mr-[0.5rem] px-[0.5rem] min-h-[14rem] flex-col flex">
            <UsersSection 
              users={users} 
              loading={isLoading || (isFetching && !isEmpty(currentData))} 
              objectId={objectId as string}
            />

            {/* show loader when we have data and it's updating */}
            {isFetching && !isLoading && <LoadingOverlay className="rounded-md" bg="bg-[#33355796]" />} 
          </div>
        </div>
      }
    />
  )
})


const Title = () => {
  const objectName = useSelector(selectObjectName);
  const objectType = useSelector(selectObjectType);

  const label = objectType === 'portfolio' ? 'Portfolio' : 'Run';
  const title = `Share ${label}`;

  return (
    <h4 className="mb-[0.1875rem] text-[#C9CADE] font-medium uppercase leading-[1.1875rem] break-words">
      {title}
    </h4>
  )
}

const Subtitle = () => {
  return <div className="mb-[2.125rem] text-[#C9CADE] font-medium text-[0.75rem] leading-[0.75rem]">Invite other users to view or edit</div>
}