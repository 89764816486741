import { FaCaretDown } from "react-icons/fa6"
import { CheckboxUI } from "./checkbox"

export const CheckboxSelectAll = ({
  itemsTotal,
  checked,
  checkedAll,
  onlySelectAll,
  onToggle,
  onToggleAll,
}: {
  itemsTotal: number;
  checked: boolean;
  checkedAll: boolean;
  onlySelectAll?: boolean;
  onToggle: () => void;
  onToggleAll: () => void;
}) => {

  if (onlySelectAll) {
    return (
      <div className="flex items-center gap-2 relative  p-2 pr-0 rounded-t-[0.5rem] -ml-2">
        <CheckboxUI
          ariaLabel='Select all'
          checked={checkedAll}
          onClick={onToggleAll}
        />
      </div>
    )
  }

  return (
    <div className="flex items-center gap-2 relative  p-2 pr-0 rounded-t-[0.5rem] -ml-2 group/checkbox-all hover:bg-[#5D5F9D] select-none">
      {/* CheckboxUI */}
      <CheckboxUI
        ariaLabel='Select all on current page'
        checked={checked}
        onClick={onToggle}
      />

      {/* Right padding */}
      <span className="w-3 absolute top-0 bottom-0 -right-1 group-hover/checkbox-all:bg-[#5D5F9D] rounded-tr-[0.5rem] -z-1" />

      {/* Arrow down */}
      <FaCaretDown className="relative z-2" />

      {/* Dropdown */}
      <div className="absolute whitespace-nowrap top-full rounded-[0.5rem] p-2 left-0 bg-[#5D5F9D] text-[#FBFBFD] rounded-tl-none hidden group-hover/checkbox-all:flex">
        <div className="flex gap-2 items-center text-sm font-medium cursor-pointer" onClick={onToggleAll}>
          <CheckboxUI
            ariaLabel='Select all'
            checked={checkedAll}
            onClick={() => { /* click handled for entire header */ }}
          />
          Select all ({itemsTotal})
        </div>
      </div>
    </div>
  )
}