import { FunctionComponent, ReactNode, useState } from 'react';
import Modal, { ModalProps } from './modal';
import usePrevious from 'react-use/lib/usePrevious';
import clsx from 'clsx';

const ActionModal: FunctionComponent<{
  action?: () => (Promise<void | boolean> | void | boolean), // return `false` if you want to prevent modal from closing after action is done. Useful for validation or error handling
  actionName?: string,
  body: ReactNode,
  disabled?: boolean,
  onClose: () => void;
  onCancel?: () => void;
  show: boolean,
  title?: ReactNode
  hideCancel?: boolean;
  cancelButtonText?: string;
  closeOnOutsideClick?: boolean;
} & Omit<ModalProps, 'title'>> = ({
  action,
  actionName,
  body,
  disabled,
  onClose,
  onCancel,
  show,
  title,
  loading,
  closeOnOutsideClick,
  cancelButtonText,
  hideCancel = false,
  ...rest
}) => {
    const [processing, setProcessing] = useState<boolean>(false);

    // prevents content change when modal is closed
    const prevBody = usePrevious(body)

    async function handleActionClick() {
      setProcessing(true);
      const result = await action?.()
      setProcessing(false)

      if (typeof result === 'boolean' && !result) {
        // prevent modal from closing
        return;
      }

      // close modal
      onClose();
    }

    return (
      <Modal
        {...rest}
        panelClassName='w-[26.875rem]'
        onClose={closeOnOutsideClick || rest.closeOnlyWithButton ? onClose : undefined}
        body={
          <div className="flex flex-col gap-[1.25rem] items-start w-full">
            {!show ? prevBody : body}
            <div className="flex flex-row gap-[0.25rem] items-center justify-end w-full">
              {!hideCancel && (
                <button
                  className="bg-[#333557] px-[2rem] py-[0.625rem] rounded-[0.625rem] text-[0.875rem] text-[#FBFBFD]"
                  onClick={() => {
                    if (onCancel) {
                      onCancel()
                    } else {
                      onClose();
                    }
                  }}>{cancelButtonText || 'Cancel'}</button>
              )}
              {actionName && (
                <button
                  type="button"
                  className={clsx({
                    'bg-[#5D5F9D] px-[2rem] py-[0.625rem] rounded-[0.625rem] text-[0.875rem] text-[#FBFBFD]': !disabled,
                    'bg-[#8183B3]/[0.1] px-[2rem] py-[0.625rem] rounded-[0.625rem] select-none text-[0.875rem] text-[#7D7D82]': disabled
                  })}
                  disabled={disabled}
                  onClick={handleActionClick}
                >
                  {actionName}
                </button>
              )}
            </div>
          </div>
        }
        show={show}
        title={title || actionName}
        loading={processing || loading}
      />
    );
  }

export default ActionModal;
