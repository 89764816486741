import { PriceTypeLabel } from "@/app/bond/bond.constants";
import { PriceType } from "@/types/types";
import { Chart, TooltipModel } from "chart.js";
import { PointData } from "../../hooks/useInferenceLinesData";
import { CustomNumericFormatProps, formatPrice } from "@/utils/number.utils";
import { formatDateLong } from "@/utils/date.utils";
// import { CustomLabel } from "./chart.types";

const TOOLTIP_WIDTH = 249;


const getOrCreateTooltip = (chart: Chart) => {
  if (!chart.canvas.parentNode) {
    return;
  }

  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = '#5D5F9D';
    tooltipEl.style.boxShadow = '10px 10px 30px 0px rgba(7, 1, 31, 0.35), -6px -6px 30px 0px rgba(97, 94, 255, 0.40)';
    tooltipEl.style.borderRadius = '5px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .2s ease';
    tooltipEl.style.padding = '10px 13px';
    tooltipEl.style.width = `${TOOLTIP_WIDTH}px`;

    // add title 
    const title = document.createElement('div');
    title.classList.add('tooltipTitle');
    title.style.fontSize = '12px';
    title.style.fontWeight = '500';
    title.style.color = '#A5A6C9';
    title.style.marginBottom = '2px';
    tooltipEl.appendChild(title);

    // add table
    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.width = '100%';
    tooltipEl.appendChild(table);

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: { chart: Chart, tooltip: TooltipModel<any> }) => {
  const { chart, tooltip } = context;

  if (!chart) {
    return;
  }

  // Tooltip Element
  const tooltipEl = getOrCreateTooltip(chart);

  if (!tooltipEl) {
    return;
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // add title
  const titleEl = tooltipEl.querySelector('.tooltipTitle');
  if (titleEl) {

    const timestamp = (tooltip.dataPoints[0].raw as PointData).x
    titleEl.innerHTML = formatDateLong(timestamp); 
  }

  if (tooltip.dataPoints) {

    const { tableBody, tableHead } = renderMultipleDatasetTooltip(tooltip)

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
  while (tableRoot?.firstChild) {
    tableRoot?.firstChild.remove();
  }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }


  // tooltip position
  const { left, top } = getTooltipPosition(chart);
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = `${left}px`;
  tooltipEl.style.top = `${top}px`;
  tooltipEl.style.width = `${TOOLTIP_WIDTH}px`;
};

function getTooltipPosition(chart: Chart) {
  const canvasWidth = chart.canvas.clientWidth
  const top = 20;
  const left = (canvasWidth - TOOLTIP_WIDTH) / 2 + TOOLTIP_WIDTH / 2 + 40;

  return {
    top,
    left,
  }
}


function renderMultipleDatasetTooltip(tooltip: TooltipModel<any>) {
  const priceType = (tooltip.dataPoints[0].raw as PointData).priceType;
  const priceHeader = PriceTypeLabel[priceType];

  // table head
  const tableHead = document.createElement('thead');
  const tr = createRow();
  tr.appendChild(createColumnHeder(''));
  tr.appendChild(createColumnHeder(priceHeader));
  tr.appendChild(createColumnHeder('%'));
  tableHead.appendChild(tr);


  

  // table body
  const tableBody = document.createElement('tbody');

  (tooltip.dataPoints || []).forEach((data, i) => {
    const tr = createRow();


    // label
    const label = data.dataset.label;
    const labelTd = createColumn(label, 'td');
    labelTd.style.fontSize = '12px';
    labelTd.style.lineHeight = '14px';
    labelTd.style.fontWeight = '500';
    labelTd.style.color = '#A5A6C9';
    tr.appendChild(labelTd);

    // value
    const value = (data.raw as any).y || 0;
    const config: Partial<CustomNumericFormatProps> | undefined = priceType === PriceType.Spread ? { decimalScale: 1 } : undefined
    const formattedValue = formatPrice(value || 0, priceType, config)
    tr.appendChild(createColumn(formattedValue, 'td'));

    // percentile
    const percentile = (data.raw as any).percentile
    tr.appendChild(createColumn(percentile, 'td'));
    

    tableBody.appendChild(tr);
  });


  return {
    tableHead,
    tableBody
  }
}

export const createColumnHeder = (text: string) => {
  const th = createColumn(text, 'th');
  th.style.fontSize = '12px';
  th.style.lineHeight = '14px';
  th.style.color = '#A5A6C9';
  return th;
}

export const createColumn = (text: string, tag: 'th' | 'td' = 'th') => {
  const th = document.createElement(tag);
  th.style.borderWidth = '0';
  th.style.paddingRight = '6px';
  th.style.textAlign = 'left';
  th.style.fontSize = '14px';
  th.style.lineHeight = '16px';
  th.style.fontWeight = '500';
  th.style.whiteSpace = 'nowrap';
  const textNode = document.createTextNode(text);
  th.appendChild(textNode);
  return th;
}

const createRow = () => {
  const tr = document.createElement('tr');
  tr.style.borderWidth = '0';
  return tr;
}