import { useState } from "react";
import { useInterval } from "react-use";

export const useIntervalCounter = (interval: number = 1000, initialCounter: number = 0) => {
  const [counter, setCounter] = useState<number>(initialCounter);
  useInterval(() => {
    setCounter(prev => prev + 1);
  }, interval)

  return counter;
}