import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { ConfigContext } from '../../site';
import { ApiRequest, ApiResponse, AtsIndicator, Inference, InferenceResponse, isMessageResponse, NewInferenceResponse, Side, Trade } from './api';
import { Bond, BondIndexData, createSearchFunctions, Issuer } from './bondIndex';
import { selectGetTokenFn, selectRefreshTokenFn, selectToken } from '../../store/slices/auth.slice';
import { getAppState } from '../../store';
import { useSelector } from 'react-redux';
import { captureException } from '@sentry/core';
import { toast } from 'react-toastify';
import { PriceType } from '@/types/types';
import { noop } from 'lodash';
import { createInferenceKey } from './data.utils';
import { Percentile } from '@/constants';
import { figiUniverse } from '@/utils/getFigiUniverse';

declare global {
  interface JSON {
    parseMore: typeof JSON.parse
  }
};

export { AtsIndicator };
export type { Bond };
export type { Inference };
export type { Issuer };
export { Side };
export type { Trade };

function isNewInferenceResponse(response: InferenceResponse): response is NewInferenceResponse {
  return 'side' in response;
}


export const getQuantileIndex = (percentile: Percentile, side: Side) => (side === Side.offer ? 100 - +percentile : +percentile) / 5 - 1; // TODO: we don't want to use this one anymore, remove it 


export enum WebSocketError {
  deactivated = 'deactivated',
  connectionError = 'connectionError',
  webSocketError = 'webSocketError'
};

export const tenorBucketsMap = {
  '0 - 3 years': [0, 3],
  '3.01 - 5 years': [3, 5],
  '5.01 - 7 years': [5, 7],
  '7.01 - 10 years': [7, 10],
  '10.01 - 20 years': [10, 20],
  '20.01 - 30 years': [20, 30],
  '30+ years': [30]
}

export const tenorBucketsOptions = Object.entries(tenorBucketsMap).map(([k, v]) => ({
  label: k,
  value: v,
}));

export const PriceOptionsMap = {
  '< 80': [0, 80],
  '80-90': [80, 90],
  '90-95': [90, 95],
  '95-100': [95, 100],
  '100-105': [100, 105],
  '105-110': [105, 110],
  '110-120': [110, 120],
  '> 120': [120]
};

export const priceOptions = Object.entries(PriceOptionsMap).map(([k, v]) => ({
  label: k,
  value: v,
}));



export const spreadOptionsMap = {
  '0-25': [0, 26],
  '26-50': [26, 51],
  '51-75': [51, 76],
  '76-100': [76, 101],
  '101-125': [101, 126],
  '126-150': [126, 151],
  '151-175': [151, 176],
  '176-200': [176, 201],
  '201-225': [201, 226],
  '226-250': [226, 251],
  '251-275': [251, 276],
  '276-300': [276, 301],
  '301-400': [301, 401],
  '401-500': [401, 501],
  '501-600': [501, 601],
  '601-700': [601, 701],
  '701-800': [701, 801],
  '801-900': [801, 901],
  '901-1000': [901, 1001],
  '1001+': [1001]




};

export const spreadOptions = Object.entries(spreadOptionsMap).map(([k, v]) => ({
  label: k,
  value: v,
}));

export const ytmOptionsMap = {
  '0 - 1%': [0, 1.01],
  '1.01 - 2%': [1.01, 2],
  '2.01 - 3%': [2.01, 3],
  '3.01 - 4%': [3.01, 4],
  '4.01 - 5%': [4.01, 5],
  '5.01 - 6%': [5.01, 6],
  '6.01 - 7%': [6.01, 7],
  '7.01 - 8%': [7.01, 8],
  '8.01 - 9%': [8.01, 9],
  '9.01 - 10%': [9.01, 10],
  '10.01 - 15%': [10.01, 15],
  '15%+': [15]
};

export const ytmOptions = Object.entries(ytmOptionsMap).map(([k, v]) => ({
  label: k,
  value: v,
}));

type State = {
  unrecognizedFigis: string[];
  applicationErrorList: string[];
  bidPercentile: Percentile;
  bidQuantileIndex: number;
  dealerPercentile: Percentile;
  dealerQuantileIndex: number;
  inferences: { [key: string]: (Inference & { frontendId: number })[] };
  offerPercentile: Percentile;
  offerQuantileIndex: number;
  selectedBonds: Set<string>;
  trades: { [key: string]: Trade[] };
  webSocketError?: WebSocketError;
};

type Action =
  // add application error
  { type: 'addApplicationErrors', payload: string[] } |
  // clear cached inferences
  { type: 'clearCachedInferences', payload: { figi: string, atsIndicator: AtsIndicator, quantity: number, side: Side, rfq_label: PriceType } } |
  // delete application error
  { type: 'deleteApplicationErrors', payload: string[] } |
  // message received from WebSocket
  { type: 'message', payload: ApiResponse } |
  // set bid/dealer/offer percentile
  {
    type: 'setBidPercentile' | 'setDealerPercentile' | 'setOfferPercentile',
    payload: Percentile
  } |
  // set selected bonds
  { type: 'setSelectedBonds', payload: Set<string> } |
  // set WebSocket error
  { type: 'setWebSocketError', payload: WebSocketError | null };

  // creates unique key that groups related inferences
export {createInferenceKey} from './data.utils';

const getRfqLabelsFromInference = (inference: Inference): PriceType => {
  if (inference.spread) return PriceType.Spread;
  if (inference.ytm) return PriceType.Ytm;
  if (inference.price) return PriceType.Price;

  throw new Error('Invalid inference while getting rfq label');
}

let inferenceFrontendId = 0;
const tradeSort = (a: Trade, b: Trade) => a.report_date.getTime() - b.report_date.getTime();

const mergeData = <T,>(
  newData: T[],
  existingData: { [key: string]: T[]; },
  existingDataFilter: ((v: T, i: number, a: T[]) => boolean),
  getKey: (t: T) => string,
  maxKeyCount: number,
  sort?: (a: T, b: T) => number,
  mergedDataFilter?: ((v: T, i: number, a: T[]) => boolean)
): { [key: string]: T[]; } => {
  const newDataMap: { [key: string]: T[]; } = {};
  newData.forEach(t => {
    const k = getKey(t);
    if (!newDataMap[k]) newDataMap[k] = [];
    newDataMap[k].push(t);
  });
  const newKeySet = new Set(Object.getOwnPropertyNames(newDataMap));
  const allKeys = [
    ...Object.getOwnPropertyNames(newDataMap),
    ...Object.getOwnPropertyNames(existingData).filter(k => !newKeySet.has(k))
  ];
  const mergedMap = allKeys.slice(0, maxKeyCount).reduce((a, c) => {
    const oldData = (existingData[c] || []).filter(existingDataFilter);
    const newData = (newDataMap[c] || []);
    const mergedData = [...oldData, ...newData];
    if (sort) {
      mergedData.sort(sort);
    }
    const filteredMergedData = mergedDataFilter
      ? mergedData.filter(mergedDataFilter)
      : mergedData;
    if (filteredMergedData.length) {
      a[c] = filteredMergedData;
    }
    return a;
  }, {} as { [key: string]: T[]; });
  return mergedMap;
}

const reducer: (prev: State, action: Action) => State = (prev: State, action: Action) => {
  switch(action.type) {
    case 'addApplicationErrors': {
      const errorSet = new Set(action.payload);
      return {
        ...prev,
        applicationErrorList: [
          ...prev.applicationErrorList.filter(e => !errorSet.has(e)),
          ...action.payload
        ]
      };
    }
    case 'clearCachedInferences': {
      // clear cached inferences
      const inferenceKey = createInferenceKey(
        action.payload.figi,
        action.payload.atsIndicator,
        action.payload.quantity,
        action.payload.side,
        action.payload.rfq_label,
      );
      return {
        ...prev,
        inferences: Object.keys(prev.inferences)
          .filter(k => k !== inferenceKey)
          .reduce((a: { [key: string]: (Inference & {frontendId: number})[] }, c) => {
            a[c] = prev.inferences[c];
            return a;
          }, {})
      };
    }
    case 'deleteApplicationErrors': {
      const errorSet = new Set(action.payload);
      return {
        ...prev,
        applicationErrorList: [
          ...prev.applicationErrorList.filter(e => !errorSet.has(e))
        ]
      };
    }
    case 'message': {
      // process a message
      const json = action.payload;
      if (isMessageResponse(json)) {
        let unrecognizedFigis = prev.unrecognizedFigis;

        if ('unrecognized_figis' in json) {
          const figis = new Set([...unrecognizedFigis, ...json.unrecognized_figis, ]);
          unrecognizedFigis = Array.from(figis);
        }

        return {
          ...prev,
          unrecognizedFigis,
          applicationErrorList: [
            ...prev.applicationErrorList.filter(e => e !== json.message),
            json.message
          ]
        };
      } else {
        const inferences = json.inference
          ? mergeData<Inference & { frontendId: number }>(
              json.inference.reduce((a, c) => {
                let inference: NewInferenceResponse;
                if (isNewInferenceResponse(c)) {
                  // new response
                  inference = c;
                } else {
                  // old response
                  // TODO: remove this code after backend is updated
                  // convert old inference to new response 
                  const side = c.bid ? Side.bid : c.dealer ? Side.dealer : Side.offer;

                  inference = {
                    ats_indicator: c.ats_indicator,
                    date: c.date,
                    figi: c.figi,
                    quantity: c.quantity,
                    price: side === Side.bid ? (c as any).bid : side === Side.dealer ? (c as any).dealer : (c as any).offer,
                    side,
                    tenor: c.tenor,
                  }
                }

                // prepare inference data
                type InferenceWithId = Inference & { frontendId: number };
                const d: InferenceWithId = {
                  ...inference,
                  date: new Date(inference.date),
                  frontendId: inferenceFrontendId++,
                }


                return [...a, d];
              }, [] as (Inference & { frontendId: number })[]),
              prev.inferences,
              v => v.frontendId > inferenceFrontendId - 50000, // keep last n inferences globally
              i => createInferenceKey(i.figi, i.ats_indicator, i.quantity, i.side, getRfqLabelsFromInference(i)), // organize by inference key
              Number.MAX_SAFE_INTEGER) // inference cache limited only by global count
          : prev.inferences;

        const trades = json.trade ?
          mergeData<Trade>(
            json.trade.map(t => ({
              // TODO: update this code after backend IG/HY is done
              ...t,
              report_date: new Date(t.report_date),
              execution_date: new Date(t.execution_date),
              ...(
                (t as any).inference ? {
                  inferred_price: (t as any).inference,
                  spread: 200,
                  inferred_spread: [],
                  ytm: 3.0,
                  inferred_ytm: [],
                } : {
                  // There is no need to override in case of the new version of the response.
                }
              ),
            })),
            prev.trades,
            t => t.entry < json.start, // rewrite history (if needed) by filtering trades with entry bigger than the start entry of the new trades
            t => t.figi, // organize by figi
            500, // only keep trades for the n most recently requested figis
            tradeSort, // sort by report date
            (t, i, a) => a.length - i <= 200) // only keep the most recent n trades per figi
          : prev.trades;

        return {
          ...prev,
          inferences,
          trades
        };
      }
    }
    case 'setBidPercentile':
      return {
        ...prev,
        bidPercentile: action.payload,
        bidQuantileIndex: getQuantileIndex(action.payload, Side.bid)
      };
    case 'setDealerPercentile':
      return {
        ...prev,
        dealerPercentile: action.payload,
        dealerQuantileIndex: getQuantileIndex(action.payload, Side.dealer)
      };
    case 'setOfferPercentile':
      return {
        ...prev,
        offerPercentile: action.payload,
        offerQuantileIndex: getQuantileIndex(action.payload, Side.offer)
      };
    case 'setSelectedBonds': {
      // set the selected bonds iff they changed
      const newSelectedBonds = action.payload;
      const equal = prev.selectedBonds.size === newSelectedBonds.size && Array.from(prev.selectedBonds).every(b => newSelectedBonds.has(b));
      return {
        ...prev,
        selectedBonds: equal ? prev.selectedBonds : newSelectedBonds
      };
    }
    case 'setWebSocketError': {
      const newState = {...prev};
      if (action.payload === null) {
        delete newState.webSocketError;
      } else {
        newState.webSocketError = action.payload;
      }
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
    }
  }
};

const initialState = {
  unrecognizedFigis: [],
  applicationErrorList: [],
  bidPercentile: '50' as Percentile,
  bidQuantileIndex: getQuantileIndex('50', Side.bid),
  dealerPercentile: '50' as Percentile,
  dealerQuantileIndex: getQuantileIndex('50', Side.dealer),
  inferences: {},
  offerPercentile: '50' as Percentile,
  offerQuantileIndex: getQuantileIndex('50', Side.offer),
  selectedBonds: new Set<string>(),
  trades: {}
};

export type FinraHolidays = { holiday: string[], early_close: { [date: string]: string } } | null;
export type IsFinraHoliday = ((date: Date) => boolean) | null;

export const DataContext = createContext<
  State &
  {
    dispatch: (a: Action) => void,
    finraHolidays: FinraHolidays,
    getUnrecognizedFigis: () => string[],
    isFinraHoliday: IsFinraHoliday,
    getBond: ((figi: string) => Bond | null) | null,
    getBondByCusip: ((cusip: string) => Bond | null) | null,
    getBondByIsin: ((cusip: string) => Bond | null) | null,
    getIssuer: ((ticker: string) => Issuer | null) | null,
    getIssuerBonds: ((ticker: string) => Bond[]) | null,
    getPrevEoD: ((ms: number) => number) | null,
    searchBonds: ((query: string) => Bond[]) | null,
    searchIssuers: ((query: string) => Issuer[]) | null,
    resetSelectedBonds: () => void;
    send: ((request: ApiRequest, required?: boolean) => void) | null
  }>({
    ...initialState,
    dispatch: () => {},
    getUnrecognizedFigis: () => [],
    finraHolidays: null,
    isFinraHoliday: null,
    getBond: null,
    getBondByCusip: null,
    getBondByIsin: null,
    getIssuer: null,
    getIssuerBonds: null,
    getPrevEoD: null,
    searchBonds: null,
    searchIssuers: null,
    resetSelectedBonds: noop,
    send: null
  });

const DataProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {

  const config = useContext(ConfigContext);

  // application state
  const [ state, dispatch ] = useReducer(reducer, {...initialState});
  const getUnrecognizedFigis = useCallback(() => {
    return state.unrecognizedFigis;
  }, [state.unrecognizedFigis])

  // local state
  const [server, setServer] = useState<string>(config.serverList[Math.floor(Math.random() * config.serverList.length)]);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const reconnectionTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const refreshTokenTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const connectionTimeoutTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const token = useSelector(selectToken);

  const getNextServer = useMemo(() => () => config.serverList[(config.serverList.indexOf(server) + 1) % config.serverList.length], [server, config.serverList]);
  const reconnect = useMemo(() => () => {
    setServer(getNextServer());
    setRetryCount(prev => prev + 1);
  }, [getNextServer, setRetryCount, setServer]);
  const getRetryDelay = useMemo(() => (count: number) =>
    Math.min(
      Math.floor(count / (config.serverList.length || 1)) + 2,
      30
    ) * 1000
  , [config.serverList.length]);

  const send = useMemo(() => ws
    ? async (request: ApiRequest, required: boolean=true) => {
        const getToken = selectGetTokenFn(getAppState());

        try {
          const { token } = await getToken(); 
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({...request, token}));
          } else if (required) {
            dispatch({ type: 'addApplicationErrors', payload: ['WebSocket not open while attempting a required send']});
          }
        } catch (e) {
          captureException(e);
        }
      }
    : null
  , [dispatch, ws]);

  // lazy-load the bond data
  const [bondIndexDataItems, setBondIndexDataItems] = useState<BondIndexData[]>([]);
  useEffect(() => {
    fetch('https://s3.amazonaws.com/deepmm.public/bond_data.json', { method: "GET", mode: "cors" })
      .then(response => response.json())
      .then(json => {
        setBondIndexDataItems(json)

        // save cusips and isins to the redux store 
        json.forEach((bond: any) => {
          figiUniverse.cusips.set(bond['C'], true);
          figiUniverse.isins.set(bond['I'], true);
        })
      })
      .catch(error => {
        captureException(error);
        toast.error('Failed to load bond data, please refresh the page to try again.')
      })
  }, []);


  const { getBond, getBondByCusip, getBondByIsin, getIssuer, getIssuerBonds, searchBonds, searchIssuers } = useMemo(() => {
    return bondIndexDataItems && bondIndexDataItems.length 
      ? createSearchFunctions(bondIndexDataItems)
      : { getBond: null, getBondByCusip: null, getBondByIsin: null, getIssuer: null, getIssuerBonds: null, searchBonds: null, searchIssuers: null }
  }, [bondIndexDataItems]);


  // lazy-load finra holidays
  const [finraHolidays, setFinraHolidays] = useState<{ holiday: string[], early_close: { [date: string]: string } } | null>(null);
  useEffect(() => {
    fetch('https://s3.amazonaws.com/deepmm.public/finra_holidays.json', { method: "GET", mode: "cors" })
      .then(response => response.json())
      .then(json => void setFinraHolidays(json));
  }, []);
  // gets the previous EoD epoch ms when passed an epoch ms
  const getPrevEoD = useMemo(() => {
    if (finraHolidays) {
      // both of these sets contain the epoch ms of the beginning of the corresponding America/New_York day
      const holidaySet = new Set(finraHolidays.holiday.map(d => DateTime.fromISO(d, { zone: 'America/New_York' }).toMillis()));
      const earlyCloseSet = new Set(Object.getOwnPropertyNames(finraHolidays.early_close).map(d => DateTime.fromISO(d, { zone: 'America/New_York' }).toMillis()));
      return (ms: number) => {
          // use America/New_York so we're checking the correct weekday, updating to the correct EOD time, etc.
          let prevEod = DateTime.fromMillis(ms, { zone: 'America/New_York' });
          let prevEodStartOfDay = prevEod.startOf('day').toMillis();
          while (
            prevEod.weekday > 5 || // weekend
            holidaySet.has(prevEodStartOfDay) || // holiday
            (earlyCloseSet.has(prevEodStartOfDay) && prevEod < DateTime.fromISO(finraHolidays.early_close[prevEod.toISODate()!]).plus({ hour: 1 })) || // before 1 hour after early market close
            prevEod < prevEod.set({ hour: 18 }) // before 1 hour after normal market close
          ) {
            prevEod = prevEod.minus({ days: 1 }).endOf('day'); // last millisecond of previous day
            prevEodStartOfDay = prevEod.startOf('day').toMillis();
          }
          return earlyCloseSet.has(prevEodStartOfDay) ?
            DateTime.fromISO(finraHolidays.early_close[prevEod.toISODate()!]).plus({ hour: 1 }).toMillis() : // 1 hour after early close ET
            prevEod.startOf('day').set({ hour: 18 }).toMillis(); // 6pm ET
      }
    } else {
      return null;
    }
  }, [finraHolidays]);

  // establish WebSocket connection
  useEffect(() => {

    let disposed = false;
    let deactivated = false;
    let error = false;
    let timeout = false;

    try {
      const newWebSocket = new WebSocket(server);

      // close the WebSocket if the connection hasn't been made by the timeout
      // which will trigger reconnection in the onclose handler
      connectionTimeoutTimerRef.current = setTimeout(() => {
        timeout = true;
        newWebSocket.close();
      }, getRetryDelay(retryCount));

      newWebSocket.onopen = () => {
        if (connectionTimeoutTimerRef.current !== null) {
          clearTimeout(connectionTimeoutTimerRef.current);
          connectionTimeoutTimerRef.current = null;
        }
        dispatch({ type: 'setWebSocketError', payload: null }); // clear WebSocket error (if any)
        setWs(newWebSocket);
      };

      newWebSocket.onmessage = ({ data }) => {
        const json = JSON.parseMore(data) as ApiResponse;
        if (isMessageResponse(json) && json.message === 'deactivated') {
          deactivated = true;
          newWebSocket.close();
          dispatch({ type: 'setWebSocketError', payload: WebSocketError.deactivated });
        } else {
          dispatch({ type: 'message', payload: json });
        }
      };

      newWebSocket.onerror = (e) => {
        error = true;
        newWebSocket?.close();
        dispatch({ type: 'setWebSocketError', payload: WebSocketError.webSocketError });
      };

      newWebSocket.onclose = () => {
        setWs(null);
        // if the WebSocket gets closed but we didn't trigger the close ourselves (disposed === true)
        // then set a timer to dispatch the retry action which will trigger a reconnection
        if (error || (!disposed && !deactivated)) {
          reconnectionTimerRef.current = setTimeout(reconnect, timeout
            ? 0 // immediately reconnect since this came from a connection timeout
            : getRetryDelay(retryCount));
        }
        if (refreshTokenTimerRef.current !== null) {
          clearTimeout(refreshTokenTimerRef.current);
          refreshTokenTimerRef.current = null;
        }
        
        // refresh token to check if connection was closed because user no longer has access to the ui
        const refreshTokenFn = selectRefreshTokenFn(getAppState());
        refreshTokenFn?.();
      };

      // effect cleanup
      return () => {
        // React is cleaning up the effect so set disposed to true so we don't try to reconnect
        // when the socket closes
        disposed = true;
        // clear connection timeout timer (if any)
        if (connectionTimeoutTimerRef.current !== null) {
          clearTimeout(connectionTimeoutTimerRef.current);
          connectionTimeoutTimerRef.current = null;
        }
        // clear reconnection timer (if any)
        if (reconnectionTimerRef.current !== null) {
          clearTimeout(reconnectionTimerRef.current);
          reconnectionTimerRef.current = null;
        }
        // clear refresh token timer (if any)
        if (refreshTokenTimerRef.current !== null) {
          clearTimeout(refreshTokenTimerRef.current);
          refreshTokenTimerRef.current = null;
        }
        // close the socket
        newWebSocket?.close();
      };
    } catch (e) {
      dispatch({ type: 'setWebSocketError', payload: WebSocketError.connectionError });
    }
  }, [getNextServer, getRetryDelay, reconnect, retryCount, server, setServer]);

  // send latest token to WebSocket
  useEffect(() => {
    if (ws && token) {
      ws.send(JSON.stringify({ token }));
    }
  }, [ws, token]);

  const resetSelectedBonds = useCallback(() => {
    dispatch({ type: 'setSelectedBonds', payload: new Set() });
  }, [])

  const isFinraHoliday = useCallback((date: Date) => {
    const isoDate = DateTime.fromJSDate(date).toISODate() as string;
    return finraHolidays?.holiday.includes(isoDate) || false;
  }, [finraHolidays])

  // provide context to the children of this component
  return (
    <DataContext.Provider value={{...state, dispatch, getUnrecognizedFigis, resetSelectedBonds, finraHolidays, isFinraHoliday, getBond, getBondByCusip, getBondByIsin, getIssuer, getIssuerBonds, getPrevEoD, searchBonds, searchIssuers, send}}>
      { children }
    </DataContext.Provider>
  );
}

export const useDataContext = () => useContext(DataContext);

export default DataProvider;
