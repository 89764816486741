import { clearColumn } from "@/excelApp/utils/clearColumn";
import { dispatcher, getAppState } from ".."
import { excelAddInActions, selectAllConnectedColumnExcelIds, selectAllConnectedColumns, selecteReferenceColumn } from "../slices/excel.slice"
import { ReferenceColumn, ReferenceColumnType } from "@/excelApp/excel.types";

export const clearAllConnectedColumnsData = async ({ 
  skipIfIsOutsideOurUniverse 
}: { 
  skipIfIsOutsideOurUniverse: boolean
}) => {
  const state = getAppState();
  const connectedColumns = selectAllConnectedColumns(state);

  // clear runs
  for (const connection of connectedColumns.run) {
    await clearColumn(connection.excelColumnId, { skipIfIsOutsideOurUniverse })
  }

  // clear portfolios
  for (const connection of connectedColumns.portfolio) {
    await clearColumn(connection.excelColumnId, { skipIfIsOutsideOurUniverse })
  }
}

export const setReferenceColumn = async ({ excelColumnId, type, disconnectAll }: { 
  excelColumnId: string, 
  type: ReferenceColumnType, 
  /** if true we will disconnect all connected columns */
  disconnectAll?: boolean 
}) => {
  const referenceColumnId = selecteReferenceColumn(getAppState());
  const connectedColumnsIds = selectAllConnectedColumnExcelIds(getAppState(), 'run');

  if (disconnectAll) { // disconnect all connected columns
    dispatcher(excelAddInActions.disconnectAllColumnsByType('run'));
  }

  if (excelColumnId && connectedColumnsIds.includes(excelColumnId)) {
    // if we try to connect the same column we should disconnect it before setting it as reference column (we still want to keep all values in that coumn)
    dispatcher(excelAddInActions.disconnectColumnByExcelId({ excelColumnId, type: 'run' }));
  }


  const newReferenceColumn: ReferenceColumn = { columnId: excelColumnId, type }
  dispatcher(excelAddInActions.setReferenceColumn(newReferenceColumn));

  if (referenceColumnId.columnId !== excelColumnId || referenceColumnId.type !== type) {
    // if we connect different reference column or it's differnet type we need to clear all connected columns
    // otherwise there might be some old data and it will be mixed with new data
    // we do it only if we connect column
    const isDisconnect = !excelColumnId;
    if (!isDisconnect) {
      await clearAllConnectedColumnsData({ skipIfIsOutsideOurUniverse: true }); 
    }
  }
}