import Loading from "@/app/loading";
import { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } from "@/store/api/userPreferences.endpoints";
import { UIMode } from "@/types/types";
import { captureException, captureMessage } from "@sentry/react";
import clsx from "clsx"
import { ReactNode, memo } from "react";
import { stopPropagation } from "@/app/alert/alert.utils";
import { toast } from "react-toastify";
import { useSyncUserPreferences } from "@/app/userPreferences/hooks/useSyncUserPreferences";
import { CircleCheck } from "@/app/components/icons/CircleCheck";

export const AppViewTypeSelectorDesktop = memo(() => {
  const { data: preferences, isLoading, isError } = useGetUserPreferencesQuery();
  const { updateUserPreferences } = useSyncUserPreferences({ errorMessage: 'Something went wrong while updating your preferences. Please try again later.' });

  const handleClick = (uiMode: UIMode) => async () => {
    if (!preferences) {
      captureMessage('User preferences not loaded while trying to update')
      return;
    }

    if (preferences.uiMode === uiMode) {
      return;
    }

    try {
      updateUserPreferences({ uiMode })
    } catch (error) {
      captureException(error);
      toast.error('Something went wrong while updating your preferences. Please try again later.');
    }
  }

  const loading = isLoading;

  return (
    <div 
      className="absolute top-0 right-[100%] shadow-menu bg-[#5D5F9D] ring-black ring-opacity-5 rounded-[0.625rem] rounded-tr-none overflow-hidden" 
      onClick={stopPropagation}
    >

      {isError && (
        <div className="absolute flex p-2">
          We weren't able to load your preferences.
        </div>
      )}

      {loading && (
        <Loading 
          className="absolute flex items-center justify-center w-full h-full" 
          spinnerClassName="w-8 h-8 border-[4px] border-[#fff_transparent_#fff_transparent]"
        />
      )}

      <div className={clsx({
        'opacity-40 pointer-events-none': loading,
        'opacity-0 pointer-events-none': isError,
      })}>
        <Button
          onClick={handleClick(UIMode.HighYield)}
          selected={preferences?.uiMode === UIMode.HighYield}
        >
          High Yield
        </Button>

        <Button
          onClick={handleClick(UIMode.InvestmentGrade)}
          selected={preferences?.uiMode === UIMode.InvestmentGrade}
        >
          Investment Grade
        </Button>
      </div>
    </div>
  )
})

const Button = ({
  children,
  onClick,
  selected,
}: {
  children: ReactNode;
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <div
      role="button"
      className={clsx('flex gap-5 justify-between items-center py-2 px-2.5 text-gray-fb text-left text-sm w-full whitespace-nowrap border-b-[#8183b3] [&:not(:last-child)]:border-b-[1px] cursor-pointer', {
        'bg-[#484A7A]': selected,
        'hover:bg-[#484A7A80]': !selected
      })}
      onClick={onClick}
    >
      {children}

      <CircleCheck checked={selected} />
    </div>
  )
}