import { captureException } from "@sentry/react";
import { toast } from "react-toastify";

export const figiUniverse: {
  data: Map<string, boolean> | null,
  promise: Promise<Map<string, boolean> | null> | null,
  cusips: Map<string, boolean>,
  isins: Map<string, boolean>
} = {
  data: null,
  promise: null,
  cusips: new Map(),
  isins: new Map()
}

export const getFigiUniverseAsync = async (): Promise<Map<string, boolean> | null> => {
  if (figiUniverse.data) {
    return figiUniverse.data;
  }

  if (figiUniverse.promise) {
    return await figiUniverse.promise;
  }


  figiUniverse.promise = fetch('https://s3.amazonaws.com/deepmm.public/universe.txt', { method: "GET", mode: "cors" })
    .then(response => response.text())
    .then(text => {
      const lines = text.split('\n'); // each line of the `universe.txt` is a figi
      const map = new Map<string, boolean>();
      lines.forEach(line => map.set(line, true));
      figiUniverse.data = map;
      return map;
    })
    .catch(error => {
      captureException(error);
      toast.error('Failed to load required data, please refresh the page to try again.')
      return null;
    })

  return await figiUniverse.promise;
}