import clsx from "clsx";
import { MouseEventHandler, ReactNode } from "react";

type CheckboxProps = CheckboxUIProps & {
  label?: ReactNode;
}

export const Checkbox = ({
  label,
  ...rest
}: CheckboxProps) => {
  return (
    <label className="text-[#8183B3] inline-flex items-center gap-1.5 cursor-pointer">
      <CheckboxUI {...rest} />
      {label}
    </label>
  )
}

type CheckboxUIProps = {
  ariaLabel: string;
  checked: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;

}

export const CheckboxUI = ({
  ariaLabel,
  checked,
  disabled,
  onClick
}: CheckboxUIProps) =>
  <button
    type="button"
    aria-label={ariaLabel}
    className={clsx(
      disabled
        ? 'bg-[#7D7D8277] border-[#7D7D82] border-[0.125rem] cursor-default'
        : checked
          ? 'bg-[#BFC5F1] border-[#BFC5F1] border-0'
          : 'bg-transparent border-[#BFC5F1] border-[0.125rem]',
      "box-border h-[0.875rem] rounded-[0.125rem] w-[0.875rem]",
      "focus:outline-none focus:ring-offset-0 focus:ring-[0.125rem] focus:ring-[#4384C8] focus:text-[#BFC5F1]"
    )}
    onClick={e => {
      if (disabled) {
        e.stopPropagation();
      } else {
        onClick?.(e);
      }
    }}
  >
    { checked
        ? <svg
            className="h-full outline-none pointer-events-none stroke-[#252641] w-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="19 7.5 9.5 17 5 11.5"></polyline>
          </svg>
        : <></>
    }
  </button>;

