import { Bond } from "@/app/data/bondIndex";
import { PortfolioResponse, Position } from "@/app/data/portfolios"
import { InferenceResult } from "@/hooks/data/useSimpleInferenceData";
import { convertRatingToSAndP, getSAndPNumericValue, numericFormatter } from "@/utils/number.utils";
import clsx from "clsx"
import { Fragment, ReactNode, useMemo, useState } from "react"
import { getPositionValue, getPrevPositionValue } from "../../portfolio.utils";
import { NAV_FORMAT, TWO_DECIMALS } from "@/constants";
import { isEmpty } from "lodash";
import { Tooltip } from "@/app/components/tooltip";
import { FaCircleInfo } from "react-icons/fa6";
import { SAndPRatingTooltipContent } from "../SAndPRatingTooltipContent";
import { EditPortfolioNAV } from "../EditPortfolioNAV";
import { useDataContext } from "@/app/data/dataProvider";
import { DeltaIcon } from "@/app/components/icons/DeltaIcon";
import styles from './PortfolioSummary.module.scss'
import { getPositionValueAsymptoticSpectrumBackground } from "@/app/components/spectrum";

type DataItem = {
  title: ReactNode;
  value: string | number | undefined | ReactNode
  delta?: string | number | undefined | ReactNode
}

export const PortfolioSummary = ({
  portfolio,
  positions,
  inferenceData,
  isFetchingPortfolio,
  onNavChange,
}: {
  portfolio: PortfolioResponse | undefined;
  positions: (Position & Bond)[],
  inferenceData: InferenceResult<Position & Bond>['data'],
  isFetchingPortfolio?: boolean;
  onNavChange: (nav: number | undefined) => void;
}) => {
  const { getUnrecognizedFigis } = useDataContext();


  const {
    avgPrice,
    avgPriceDelta,
    avgRating,
    shortMV,
    longMV,
    grossMV,
    deltaShortMV,
    deltaShortMVFormatted,
    deltaLongMV,
    deltaLongMVFormatted,
    deltaGrossMV,
    totalValue,
    totalDelta,
    totalDeltaFormatted,
    longCount,
    shortCount,
  } = useMemo(() => {
    let totalRating = 0;
    let totalRatedValue = 0; // when we calculate rating we ignore S&P 0 rating so total value will be different
    let totalValueAbs = 0;
    let prevTotalValueAbs = 0;
    let totalSize = 0;
    let longMV = 0;
    let shortMV = 0;
    let prevLongMV = 0;
    let prevShortMV = 0;
    let totalValue = 0;
    let totalDelta = 0;
    let longCount = 0;
    let shortCount = 0;
    const unrecognizedFigisSet = new Set(getUnrecognizedFigis())


    positions.forEach((position) => {
      const positionValue = getPositionValue(position, inferenceData);

      // rating calculation
      const bondRating = getSAndPNumericValue(position.rating);
      if (bondRating !== 0) {
        // we calculate rating only for bonds with rating > 0
        totalRating += Math.abs(positionValue) * getSAndPNumericValue(position.rating);
        totalRatedValue += Math.abs(positionValue);
      }


      // we do not include unrecognized bonds in the calculation
      if (unrecognizedFigisSet.has(position.figi)) {
        return;
      }

      const prevPositionValue = getPrevPositionValue(position, inferenceData, false);

      if (position.size < 0) {
        shortMV += positionValue;
        prevShortMV += prevPositionValue;
        shortCount += 1;
      } else {
        longMV += positionValue;
        prevLongMV += prevPositionValue;
        longCount += 1;
      }

      totalValueAbs += Math.abs(positionValue);
      prevTotalValueAbs += Math.abs(prevPositionValue);
      totalSize += Math.abs(position.size);
      totalValue += positionValue;
      totalDelta += positionValue - prevPositionValue // getPositionValue(position, inferenceData, true);
    })

    const avgRating = totalRatedValue === 0
      ? 0
      : totalRating / totalRatedValue;
    const avgPrice = totalSize === 0
      ? 0
      : totalValueAbs / totalSize * 100;
    const prevAvgPrice = totalSize === 0
      ? 0
      : prevTotalValueAbs / totalSize * 100;
    const avgPriceDelta = avgPrice - prevAvgPrice;
    const grossMV = longMV - shortMV;
    const prevGrossMV = prevLongMV - prevShortMV;

    const deltaLongMV = longMV - prevLongMV;
    const deltaShortMV = shortMV - prevShortMV;
    const deltaGrossMV = grossMV - prevGrossMV;

    return {
      avgPrice: numericFormatter(avgPrice, TWO_DECIMALS),
      avgPriceDelta: numericFormatter(avgPriceDelta, TWO_DECIMALS),
      avgRating: convertRatingToSAndP(avgRating),
      shortMV: numericFormatter(shortMV, NAV_FORMAT),
      longMV: numericFormatter(longMV, NAV_FORMAT),
      grossMV: numericFormatter(grossMV, NAV_FORMAT),
      deltaShortMV: deltaShortMV,
      deltaShortMVFormatted: numericFormatter(deltaShortMV, NAV_FORMAT),
      deltaLongMV: deltaLongMV,
      deltaLongMVFormatted: numericFormatter(deltaLongMV, NAV_FORMAT),
      deltaGrossMV: numericFormatter(deltaGrossMV, NAV_FORMAT),
      totalValue: numericFormatter(totalValue, NAV_FORMAT),
      totalDelta,
      totalDeltaFormatted: numericFormatter(totalDelta, NAV_FORMAT),
      shortCount,
      longCount,
    }
  }, [positions, inferenceData, getUnrecognizedFigis])

  const hasInferenceData = useMemo(() => {
    // go through all positions and check if we have inference data for at least one of them 
    for (let i = 0; i < positions.length; i++) {
      if (!isEmpty(inferenceData[positions[i].figi].bid.price.quantiles)) {
        return true;
      }
    }

    return false;
  }, [positions, inferenceData])

  const hasPrevInferenceData = useMemo(() => {
    // go through all positions and check if we have inference data for at least one of them 
    for (let i = 0; i < positions.length; i++) {
      if (!isEmpty(inferenceData[positions[i].figi].bid.price.prevQuantiles)) {
        return true;
      }
    }

    return false;
  }, [positions, inferenceData])

  const bondsMissing = useMemo(() => {
    if (!hasInferenceData) {
      return 0;
    }

    const unrecognizedFigisSet = new Set(getUnrecognizedFigis());

    // go through all positions and check how many of them are present in unrecognizedFigisSet
    const bondsMissingCount = positions.reduce((acc, position) => {
      if (unrecognizedFigisSet.has(position.figi)) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return bondsMissingCount;
  }, [positions, hasInferenceData, getUnrecognizedFigis])



  const bondsTotal = positions.length;
  const bondsPriced = bondsTotal - bondsMissing;

  const topRow = [
    // { title: 'NAV', value: <EditPortfolioNAV nav={portfolio?.value?.nav} onNavChange={onNavChange} isFetchingPortfolio={isFetchingPortfolio} /> },
    {
      title: 'Deep MM NAV',
      value: dataOrPlaceholer(totalValue),
      delta: (
        <Delta delta={totalDelta} deltaFormatted={prevDataOrPlaceholder(totalDeltaFormatted)} />
      )
    },
    {
      title: 'Deep MM Long MV',
      value: dataOrPlaceholer(longMV),
      delta: <Delta delta={deltaLongMV} deltaFormatted={prevDataOrPlaceholder(deltaLongMVFormatted)} />
    },
    {
      title: 'Deep MM Short MV',
      value: dataOrPlaceholer(shortMV),
      delta: <Delta delta={deltaShortMV} deltaFormatted={prevDataOrPlaceholder(deltaShortMVFormatted)} />
    },
    {
      title: 'Deep MM Gross MV',
      value: dataOrPlaceholer(grossMV),
      // delta: `${deltaGrossMV}`
    }
  ]

  function dataOrPlaceholer(value: number | string) {
    if (!hasInferenceData) {
      return '-';
    }
    return value;
  }

  function prevDataOrPlaceholder(value: number | string) {
    if (!hasPrevInferenceData) {
      return '-';
    }
    return value;
  }

  const bottomRow = [
    {
      title: 'Bond Count',
      value: bondsTotal,
    },
    {
      title: 'Bond Missing',
      value: dataOrPlaceholer(bondsMissing),
    },
    {
      title: 'Priced Bonds',
      value: dataOrPlaceholer(bondsPriced),
    },
    {
      title: 'Avg. Price',
      value: dataOrPlaceholer(avgPrice),
    },
    {
      title: 'Avg. Price Δ',
      value: dataOrPlaceholer(avgPriceDelta),
    },
    // {
    //   title: 'Avg. Spread',
    //   value: 1.00, // 1 decimal
    // },
    // {
    //   title: 'Avg. Spread Δ',
    //   value: '-', // 1 decimal
    // },
    // {
    //   title: 'Avg. Yield',
    //   value: '1.00', // 3 decimal
    // },
    // {
    //   title: 'Avg. Yield Δ',
    //   value: '-', // 3 decimal
    // },
    {
      title: (
        <>
          Avg. Rating
          <Tooltip tooltip={<SAndPRatingTooltipContent />} maxWidthCss='max-w-[20.625rem]' delayShow={350}>
            <FaCircleInfo className="cursor-pointer hover:text-[#FBFBFD] " />
          </Tooltip>
        </>
      ),
      value: dataOrPlaceholer(avgRating),
    },
  ]

  return (
    <div className="flex flex-col gap-[0.625rem] md:gap-[1.0625rem] w-full">
      {/* top row */}
      <TopRow
        items={topRow}
        portfolio={portfolio}
        onNavChange={onNavChange}
        isFetchingPortfolio={isFetchingPortfolio}
      />

      {/* bottom row */}
      <BottomRow items={bottomRow} />
    </div>
  )
}

// Helper components

const TopRow = ({
  items,
  portfolio,
  onNavChange,
  isFetchingPortfolio,
}: {
  items: DataItem[]
  portfolio: PortfolioResponse | undefined;
  onNavChange: (nav: number | undefined) => void;
  isFetchingPortfolio?: boolean;
}) => {
  const [navModalOpen, setNavModalOpen] = useState(false)

  return (
    <div className={styles.topRow}>
      <div className={clsx(styles.topItem, styles.block, styles.blockBg)}>
        <EditPortfolioNAV
          editing={navModalOpen}
          setEditing={setNavModalOpen}
          nav={portfolio?.value?.nav}
          onNavChange={onNavChange}
          isFetchingPortfolio={isFetchingPortfolio}
          title={
            <h5 className={styles.title} style={{ color: 'inherit' }}>
              NAV
            </h5>
          }
          renderValue={(value) => (
            <div className={styles.value}>
              {value}
            </div>
          )}
        />
      </div>

      {items.map((item, index) => {
        return (
          <TopItem item={item} key={index} />
        )
      })}
    </div>
  )
}

const TopItem = ({
  item,
}: {
  item: DataItem;
}) => {
  return (
    <div className={clsx(styles.topItem, styles.block)}>
      <h5 className={styles.title}>
        {item.title}
      </h5>

      <div className={styles.value}>
        {item.value}
      </div>

      {item.delta}
    </div>
  )
}


const BottomRow = ({
  items,
}: {
  items: DataItem[]
}) => {
  return (
    <div className={styles.bottomRow}>

      {items.map((item, index) => {
        return (
          <BottomItem item={item} key={index} />
        )
      })}
    </div>
  )
}

const BottomItem = ({
  item,
}: {
  item: DataItem;
}) => {
  return (
    <div className={clsx(styles.bottomItem, styles.blockSmall)}>
      <h5 className={styles.title}>
        {item.title}
      </h5>

      <div className={styles.value}>
        {item.value}
      </div>
    </div>
  )
}

const Delta = ({ delta, deltaFormatted }: { delta: number | undefined, deltaFormatted: string | number }) => {
  if (typeof delta === 'undefined' || deltaFormatted === undefined) {
    return null;
  }

  const background = Math.abs(delta) < 2 || deltaFormatted === '-'
    ? 'bg-[#C9CADE] text-[#333557]'
    : getPositionValueAsymptoticSpectrumBackground(delta, 'light') + ' text-[#FBFBFD]'

  return (
    <div className={clsx(styles.delta, background)}>
      <DeltaIcon />
      {deltaFormatted}
    </div>
  )
}