import { Inference, Side } from "@/app/data/api"
import { getQuantileIndexV2 } from "@/app/data/data.utils";
import { useDataContext } from "@/app/data/dataProvider";
import { Percentile } from "@/constants";
import { PriceType } from "@/types/types";
import { useMemo } from "react";

export type PointData = {
  x: number;
  y: number;
  priceType: PriceType;
  percentile?: Percentile;
}

// TODO: calculate inferences lines data only for active sides?
export const useInferencesLinesData = ({
  bidInferences,
  dealerInferences,
  offerInferences,
  percentileActive,
  priceType,
}: {
  bidInferences: Inference[];
  dealerInferences: Inference[];
  offerInferences: Inference[];
  percentileActive: boolean;
  priceType: PriceType;
}) => {
  const { bidPercentile, dealerPercentile, offerPercentile } = useDataContext();


  const bid = useMemo(() => {
    return getSideData(bidPercentile, Side.bid, priceType, bidInferences);
  }, [bidInferences, priceType, bidPercentile])

  const dealer = useMemo(() => {
    return getSideData(dealerPercentile, Side.dealer, priceType, dealerInferences);
  }, [dealerInferences, priceType, dealerPercentile])

  const offer = useMemo(() => {
    return getSideData(offerPercentile, Side.offer, priceType, offerInferences);
  }, [offerInferences, priceType, offerPercentile])


  return {
    bid,
    dealer,
    offer,
  }
}

function getSideData(percentile: Percentile, side: Side, priceType: PriceType, inferences: Inference[]) {
  const quantileIndex = getQuantileIndexV2(percentile, side, priceType);
  const fifthPercentileIndex = getQuantileIndexV2('5', side, priceType);
  const ninetyFifthPercentileIndex = getQuantileIndexV2('95', side, priceType);
  let data: PointData[] = [];
  let fifthPercentile: PointData[] = [];
  let ninetyFifthPercentile: PointData[] = [];

  inferences.forEach(inference => {
    const time = inference.date.getTime();
    data.push({
      x: time,
      y: inference[priceType]?.[quantileIndex] as number,
      priceType,
      percentile: percentile,
    })

    fifthPercentile.push({
      x: time,
      y: inference[priceType]?.[fifthPercentileIndex] as number,
      priceType,
      percentile: '5',
    })

    ninetyFifthPercentile.push({
      x: time,
      y: inference[priceType]?.[ninetyFifthPercentileIndex] as number,
      priceType,
      percentile: '95',
    })
  })

  return {
    data,
    fifthPercentile,
    ninetyFifthPercentile,
  }
}