import { BidOfferSpreadType } from "@/types/enums";
import { BidOfferSpread, BOSpreadFixed, BOSpreadProfit, BOSpreadRange } from "../data/runs";
import { CalculatedBOSpreadMap, RunColumn, RunColumnTitle } from "./run.constants";
import { capitalize } from "lodash";

export const getColumnTitle = (previousString?: string) => (column: RunColumn) => RunColumnTitle[column].replace('{prevPeriod}', previousString || '');

export const isPercentileSelectionDisabled = (boSpread: BidOfferSpread | undefined) => {
  return isCalculatedBoSpread(boSpread);
}

export const isCalculatedBoSpread = (boSpread: BidOfferSpread | undefined): boSpread is BOSpreadProfit | BOSpreadFixed | BOSpreadRange => {
  if (!boSpread) return false

  return CalculatedBOSpreadMap[boSpread.type];
}

export const getBOSpreadLabel = (boSpread: BidOfferSpread | undefined): string | number => {
  if (!boSpread) return ''

  if (boSpread.type === BidOfferSpreadType.Fixed) {
    return boSpread.value;
  } else if (boSpread.type === BidOfferSpreadType.Range) {
    return `${boSpread.min} - ${boSpread.max}`;
  } else {
    return capitalize(boSpread.type);
  }
}