import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CheckIcon, MagnifyingGlassIcon, PlusIcon, SquaresPlusIcon } from '@heroicons/react/20/solid';
import { Bond, DataContext, Side } from '../data/dataProvider';
import Loading from '../loading';
import ListBox from "../components/listbox/listbox";
import useInferences from '../data/useInferences';
import { deepCompareRunBonds, RunBond, useCreateRun, useDeleteRun, useEditRun, useRemoveFromRun, useSaveRunBonds } from '../data/runs';
import RunTable from './runTable';
import ActionModal from '../components/modal/actionModal';
import ActionWithInputModal from '../components/modal/actionWithInputModal';
import { useOpenPage } from '../../hooks/useOpenPage';
import { useGetRunListQuery, useLazyGetRunQuery } from '@/store/api/run.endpoints';
import { captureException } from '@sentry/core';
import { BidCopyToClipboardColumns, BidOfferCopyToClipboardColumns, ColumnConfigMap, ColumnsOrderConfig, NO_RUN_BONDS_FOR_FILTERS, NoDatesForbiddenColumns, OfferCopyToClipboardColumns, RunColumn, RunColumnPrintTitle, ShortCopyToClipboardColumns } from './run.constants';
import { RunActionsMenu } from './components/runActionsMenu';
import { DownloadCsvModal } from '../components/modal/downloadCsvModal';
import { useTableToCsv } from '../components/table/hooks/useTableToCsv';
import { useSelector } from 'react-redux';
import { selectHasCusipAccess } from '@/store/slices/auth.slice';
import { QueryParam } from '@/constants';
import { Filters } from '../components/filters/filters';
import { usePreviousMs } from '../components/filters/hooks/useFilters';
import { useFilteredItems } from '../components/filters/hooks/useFilteredItems';
import { RunCard } from './runCard';
import { isEmpty } from 'lodash';
import { useGetInferenceRfqLabelsFromColumns } from '@/hooks/data/useGetInferenceRfqLabelsFromColumns';
import { useBondsInferenceRequests } from '@/hooks/data/useBondsInferenceRequests';
import { PriceType } from '@/types/types';
import { useUiMode } from '@/hooks/useUiMode';
import { useLatestRef } from '@/hooks/useLatestRef';
import { ProfilerLog } from '../components/profilerLog';
import { useWebworkerInferenceCalculation } from './hooks/useWebworkerInferenceCalculation';
import { RoundPriceToggle } from '../components/roundPriceToggle';
import { ShareModal } from '@/screens/modals/ShareModal/ShareModal';
import { ReadonlyBadge } from '../components/badge/ReadonlyBadge';
import { isReadonly } from '@/utils/privilege.utils';
import { PrivilegesProvider } from '@/contexts/privileges/PrivilegesProvider';
import { RunPrivilegesProvider } from '@/contexts/privileges/components/RunPrivilegesProvider';
import { ExcelReferenceColumnCard } from '@/excelApp/components/ExcelReferenceColumnCard';
import { ObjectPrivilege } from '@/types/enums';
import { FaEye } from 'react-icons/fa6';

const DEFAULT_RUN_BONDS: (RunBond & Bond)[] = [];

const Run = () => {

  const { run: runId } = useParams();
  const { uiMode } = useUiMode();
  const [searchParams] = useSearchParams();
  const { getBond, selectedBonds } = useContext(DataContext);
  const openPage = useOpenPage();
  const [getRunApi] = useLazyGetRunQuery();
  const saveRunBonds = useSaveRunBonds();
  const hasCusipAccess = useSelector(selectHasCusipAccess);

  // download
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const [downloadType, setDownloadType] = useState<undefined | 'noDates'>(undefined);

  // edit
  const editRun = useEditRun();
  const [editName, setEditName] = useState<string>('');
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const { data: unsortedRuns, isLoading: isLoadingRuns, isFetching: isFetchingRuns } = useGetRunListQuery();

  const runFromList = useMemo(() => unsortedRuns?.find(r => r.id === runId), [runId, unsortedRuns]);

  const runToName = useMemo(() =>
    (unsortedRuns || []).reduce((a: { [key: string]: string }, c) => {
      a[c.id] = JSON.parse(c.metadata)['name'];
      return a;
    }, {})
    , [unsortedRuns]);
  const runToPrivilege = useMemo(() =>
    (unsortedRuns || []).reduce((a: { [key: string]: string }, c) => {
      a[c.id] = c.privilege
      return a;
    }, {})
    , [unsortedRuns]);

  const runs = useMemo(() => {
    if (unsortedRuns) {
      const sorted = [...unsortedRuns];
      sorted.sort((a, b) => runToName[a.id].localeCompare(runToName[b.id]));
      return sorted;
    } else {
      return unsortedRuns;
    }
  }, [runToName, unsortedRuns]);

  useEffect(() => {
    if (isFetchingRuns) {
      return;
    }

    if (runs && runs.length && (!runId || !(runId in runToName))) {
      // if no run or the run doesn't exist then go to another (if any)
      const differentId = runs.map(b => b.id).find(pid => pid !== runId);
      openPage.run(differentId, { replace: true });
    } else if (runId && runId.length && runs && !runs.length) {
      // if no runs and runId exists then navigate to run
      openPage.runs({ replace: true });
    }
  }, [openPage, runId, runs, runToName, isFetchingRuns]);

  const [runBonds, setRunBonds] = useState<(RunBond & Bond)[] | undefined | null>();
  const runIdRef = useRef<string | undefined>(undefined);
  const savePromiseRef = useRef<{ [runId: string]: Promise<string> }>({});
  const saveQueuedRef = useRef<{ [runId: string]: boolean }>({});
  const runBondsToSaveRef = useRef<{ [runId: string]: (RunBond & Bond)[] }>({});


  const getBondRef = useLatestRef(getBond);
  useEffect(() => {
    setRunBonds(undefined);
    runIdRef.current = undefined;

    if (runId && runId.length && runId in runToName && getBond) {
      getRunApi(runId).unwrap()
        .then((runResponse) => {
          const retrievedRunBonds = (runResponse ? runResponse.value.bonds : []).map(b => {
            const bond = getBondRef.current ? getBondRef.current(b.figi) : null;
            return bond ? { ...bond, ...b } : null;
          }).filter((v): v is (RunBond & Bond) => !!v); // the filter is just a type guard
          setRunBonds(retrievedRunBonds);
          runIdRef.current = runId;
          runBondsToSaveRef.current[runIdRef.current] = retrievedRunBonds;
          savePromiseRef.current[runIdRef.current] = (savePromiseRef.current[runIdRef.current] || Promise.resolve()).then(() => runResponse.version);
        })
        .catch((e) => {
          captureException(e)
          setRunBonds(null)
        });
    }
  }, [getBond, getRunApi, runId, runToName]);

  useEffect(() => {
    const localRunId = runIdRef.current;
    const localRunBonds = runBonds;
    if (localRunId && localRunId.length && localRunBonds && runBondsToSaveRef.current[localRunId] && !deepCompareRunBonds(localRunBonds, runBondsToSaveRef.current[localRunId])) {
      runBondsToSaveRef.current[localRunId] = localRunBonds;
      if (!saveQueuedRef.current[localRunId]) {
        saveQueuedRef.current[localRunId] = true;
        savePromiseRef.current[localRunId] = savePromiseRef.current[localRunId]
          .then(version => new Promise(resolve => void setTimeout(() => resolve(version), 250)))
          .then(version => {
            saveQueuedRef.current[localRunId] = false;
            return saveRunBonds(localRunId, version as string, runBondsToSaveRef.current[localRunId] as (RunBond & Bond)[])
              .then(({ version }) => version);
          });
      }
    }
  }, [runBonds, saveRunBonds]);

  //  prepare bond request data
  const rfqLabelMap = useGetInferenceRfqLabelsFromColumns(ColumnsOrderConfig, ColumnConfigMap);
  const getQuantity = useCallback((b: RunBond, side: Side) => side === Side.bid ? b.bidSize : b.offerSize, []);
  const bondRequests = useBondsInferenceRequests({
    bonds: runBonds || DEFAULT_RUN_BONDS,
    rfqLabelMap,
    getQuantity: getQuantity,
  });

  // prepare inference request
  const { previousMs } = usePreviousMs();
  const [_, inferences] = useInferences(bondRequests, previousMs || undefined);
  const roundPrice = searchParams.get(QueryParam.RoundPrice) === 'true';



  const inferenceResult = useWebworkerInferenceCalculation({
    inferences,
    items: runBonds || DEFAULT_RUN_BONDS,
    roundPrice,
    runId,
    loading: isLoadingRuns,
  });

  // table to csv
  const inferenceRef = useLatestRef(inferenceResult)
  const { tableRef, getCsvRows, handleCopyClick } = useTableToCsv<RunBond & Bond, RunColumn, 'short' | 'bid_offer' | 'default' | 'bid' | 'offer' | 'noDates'>({
    filterRow: ({ item }) => {
      if (!inferenceRef.current) {
        return false;
      }

      return !inferenceRef.current?.data?.[item.figi]?.[Side.bid]?.[PriceType.Price]?.data?.error
    },
    getColumnTitle: (columnKey) => {
      if (!hasCusipAccess && columnKey === RunColumn.Cusip) {
        return 'FIGI';
      }
      return RunColumnPrintTitle[columnKey];
    },
    parseValue: ({ columnKey, value, item }) => {
      if (!hasCusipAccess && columnKey === RunColumn.Cusip) {
        return item.figi;
      }
      return value
    },
    filterColumn: ({ data, columnKey, isHeader }) => {
      if (!data) {
        throw new Error('data is required');
      }

      if (data === 'default') {
        return true;
      }

      if (data === 'noDates') {
        return !NoDatesForbiddenColumns.includes(columnKey);
      }

      let validColumns: RunColumn[] | undefined;
      if (data === 'short') {
        // copy only few columns for short copy
        validColumns = ShortCopyToClipboardColumns[uiMode];
      } else if (data === 'bid_offer') {
        if (isHeader) {
          return false;
        }

        // copy only bid and offer columns
        validColumns = BidOfferCopyToClipboardColumns[uiMode];
      } else if (data === 'bid') {
        if (isHeader) {
          return false;
        }

        // copy only bid columns
        validColumns = BidCopyToClipboardColumns[uiMode];
      } else if (data === 'offer') {
        if (isHeader) {
          return false;
        }

        // copy only offer columns
        validColumns = OfferCopyToClipboardColumns[uiMode];
      }


      if (!validColumns) {
        captureException(new Error(`ShortCopyToClipboardColumns not found for uiMode: ${uiMode}`));
        return true;
      };

      return validColumns.includes(columnKey)
    },
    parseCsv: (csv: string) => {
      // remove first empty line if exists
      return csv.split('\n')
        .filter((r, idx) => idx > 0 || r.trim().length > 0)
        .join('\n');
    },
  }, [runId]);

  // filtered run bonds
  const filteredRunBonds = useFilteredItems(runBonds || DEFAULT_RUN_BONDS, inferenceResult);

  // create
  const createRun = useCreateRun();
  const [createName, setCreateName] = useState<string>('');
  const [showCreate, setShowCreate] = useState<boolean>(false);

  // delete
  const deleteRun = useDeleteRun();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  // remove
  const removeFromRun = useRemoveFromRun();
  const [removeFigiSet, setRemoveFigiSet] = useState<Set<string>>(new Set());
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const remove = useMemo(() =>
    (figiSet: Set<string>) => {
      setRemoveFigiSet(figiSet);
      setShowRemove(true);
    }
    , [setRemoveFigiSet, setShowRemove]);


  // linking
  const [linkedFigis, setLinkedFigis] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (runId) {
      setLinkedFigis(new Set());
    }
  }, [runId, setLinkedFigis])

  async function handleCreateRun() {
    const result = await createRun(createName, selectedBonds)
    if (!result) {
      return false; // prevent modal from closing
    }
  }



  const renderCreateRunModal = () => (
    <ActionWithInputModal
      action={handleCreateRun}
      actionName='Create'
      disabled={!createName}
      label='New Run Name'
      placeholder='Name'
      setShow={setShowCreate}
      setValue={setCreateName}
      show={showCreate}
      value={createName}
      title='Create Run'
    />
  )

  if (runs && !runs.length) {
    return (
      <>
        <div className="flex flex-row p-[0.625rem] w-full">
          <button
            className="bg-[#5D5F9D] flex flex-row gap-[0.625rem] items-center justify-center px-[1.25rem] py-[0.625rem] rounded-[0.625rem]"
            onClick={() => {
              setCreateName('');
              setShowCreate(true);
            }}
          >
            <span>Create Run</span>
          </button>
        </div>
        {renderCreateRunModal()}
      </>
    );
  }

  if (isLoadingRuns || !runId || !runId.length || !getBond) {
    return <Loading className="mt-[2.5rem]" />;
  }

  const readonly = isReadonly(runFromList?.privilege);

  return (
    <PrivilegesProvider privilege={runFromList?.privilege} Provider={RunPrivilegesProvider}>
      <ProfilerLog id="sasha render run index">
        <div className="pt-[1.25rem] lg:px-[1.25rem]">
          <div className="flex flex-row gap-[0.375rem] items-center justify-between sm:gap-[0.625rem]">
            <div className="bg-[#333557] flex flex-row gap-[0.375rem] items-center justify-between max-w-[20rem] min-w-[8rem] pl-[0.625rem] pr-[0.375rem] py-[0.625rem] rounded-t-[0.625rem] sm:gap-[0.625rem] sm:w-[20rem] sm:pl-[1.25rem] sm:pr-[0.625rem]">
              <div className="font-medium text-[0.875rem] truncate">{runToName[runId]}</div>
              {readonly && <ReadonlyBadge />}
              <RunActionsMenu
                runId={runId}
                runName={runToName[runId]}
                onCopyClick={handleCopyClick}
                onDelete={() => setShowDelete(true)}
                onDownload={(type) => {
                  setShowDownload(true)
                  setDownloadType(type)
                }}
                onEdit={() => {
                  setEditName(runToName[runId]);
                  setShowEdit(true);
                }}
              />
            </div>
            <div className="flex flex-row gap-[0.375rem] items-center justify-center sm:gap-[0.625rem]">
              <RoundPriceToggle />
              <ListBox
                button={() => <SquaresPlusIcon className="h-full w-full" />}
                buttonCss={() => 'bg-[#5D5F9D] h-[2.25rem] p-[0.625rem] rounded-full w-[2.25rem]'}
                divCss='w-[auto]'
                popperProps={{
                  placement: 'bottom-end',
                }}
                option={(value, props) => {
                  const isOwner = runToPrivilege[value] === ObjectPrivilege.Owner;

                  return value === '__new__'
                    ? <div className="flex flex-row items-center justify-between">
                      <div>Create New Run</div>
                      <PlusIcon className="h-[1rem] w-[1rem]" />
                    </div>
                    : <div className='flex flex-row items-center justify-between gap-3'>
                      <span className={`block text-[0.875rem] truncate ${props.selected ? 'font-medium' : 'font-normal'}`}>
                        {runToName[value]}
                      </span>
                      {!isOwner && (
                        <span>
                          <FaEye className='w-3' />
                        </span>
                      )}
                      {props.selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                }}
                optionCss={() => 'min-w-[305px] bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[2.5rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0] hover:bg-[#484A7A]'}
                optionsCss='absolute max-h-[10rem] mt-[0.25rem] origin-top-right overflow-auto right-0 rounded-[0.625rem] w-[20rem] z-[3]'
                selectValue={value => {
                  if (value === '__new__') {
                    setCreateName('');
                    setShowCreate(true);
                  } else {
                    // reset run bonds before navigating to another run, (important step)
                    setRunBonds(undefined);
                    openPage.run(value);
                  }
                }}
                value={runId}
                values={['__new__', ...(runs || []).map(b => b.id)]}
              />
            </div>
          </div>
          <div className="bg-[#333557] rounded-tr-[0.625rem] w-full lg:h-[calc(100vh-11.3rem)] lg:rounded-b-[0.625rem]">
            <div className="flex flex-col gap-[0.625rem] items-start py-[0.625rem] w-full">

              {/* Filters */}
              <Filters
                bonds={runBonds || DEFAULT_RUN_BONDS}
                className='px-[0.625rem]'
                pageSizeParamName={QueryParam.RunPageSize}
              />

              <ExcelReferenceColumnCard className="mx-[0.625rem]" />

              {
                !runBonds || !runBonds.length
                  ? <></> // loading and empty run handled below
                  : <div className="h-[calc(100vh-15rem)] hidden pb-[0.625rem] w-full lg:block">
                    <ProfilerLog id='sasha Render Run Table'>
                      <RunTable
                        bonds={filteredRunBonds}
                        inferenceResult={inferenceResult}
                        linkedFigis={linkedFigis}
                        remove={remove}
                        selectBond={b => void openPage.bond(b)}
                        setLinkedFigis={setLinkedFigis}
                        setRunBonds={setRunBonds}
                        tableRef={tableRef}
                        readonly={readonly}
                      />
                    </ProfilerLog>
                  </div>
              }
            </div>

            {
              !runBonds
                ? <Loading className="py-[3rem] pt-[0.875rem]" />
                :
                !runBonds.length
                  ? <div className="bg-[#333557] flex flex-col mx-[0.625rem] place-items-center py-[2rem] rounded-[0.625rem] text-[#DDDDE2] sm:py-[2.5rem] sm:text-[1.25rem]">
                    <MagnifyingGlassIcon className="h-[2rem] mb-[0.2rem] text-[#C9CADE] sm:h-[2.8rem]" />
                    <span>Start searching and add bonds</span>
                    <span>to this run.</span>
                  </div>
                  : (
                    <div className="flex flex-row gap-[0.75rem] items-start overflow-x-auto pb-[1rem] px-[0.625rem] w-full lg:hidden">
                      {filteredRunBonds.slice(0, 20).map(b => (
                        <div key={b.figi} onClick={() => openPage.bond(b)}>
                          <RunCard
                            bond={b}
                            inferenceResult={inferenceResult}
                            remove={figi => void remove(new Set([figi]))}
                          />
                        </div>
                      ))}
                      {filteredRunBonds.length > 20 && (
                        <div className="bg-[#1F2034] flex flex-row items-center justify-center max-w-[17.1875rem] min-w-[17.1875rem] px-[0.625rem] py-[1.5rem] rounded-[0.625rem] w-[17.1875rem]">
                          <span>{`${filteredRunBonds.length - 20} more...`}</span>
                        </div>
                      )}
                      {isEmpty(filteredRunBonds) && <div className="text-[#8183B3]">{NO_RUN_BONDS_FOR_FILTERS}</div>}
                    </div>
                  )
            }
          </div>

          {renderCreateRunModal()}

          <ActionModal
            action={() => deleteRun(runId)}
            actionName={readonly ? 'Remove' : 'Delete'}
            body={
              <div className="grid grid-cols-[auto,1fr,auto] grid-rows-1">
                <span>{readonly ? 'Remove' : 'Delete'}</span>
                <span className="pl-[0.3125rem] text-[#FBFBFD] truncate">{runToName[runId]}</span>
                <span>?</span>
              </div>
            }
            onClose={() => setShowDelete(false)}
            show={showDelete}
            title={`${readonly ? 'Remove' : 'Delete'} Run`}
          />
          <ActionModal
            action={async () => {
              const result = await removeFromRun(runId, removeFigiSet)
              if (result === false) {
                return false;
              }

              setLinkedFigis(new Set());
            }}
            actionName='Remove'
            body={
              <div className="">
                Remove <span className="text-[#FBFBFD]">{removeFigiSet.size}</span> bond{removeFigiSet.size > 1 ? 's' : ''} from run?
              </div>
            }
            show={showRemove}
            onClose={() => setShowRemove(false)}
          />

          {/* Edit Name */}
          <ActionWithInputModal
            action={() => editRun(runId, editName)}
            actionName='Edit'
            disabled={!editName}
            label='Edit Run Name'
            placeholder='Name'
            setShow={setShowEdit}
            setValue={setEditName}
            show={showEdit}
            value={editName}
            title='Edit Run'
          />

          {/* Download file Modal */}
          <DownloadCsvModal
            getRows={() => {
              return getCsvRows(downloadType || 'default');
            }}
            name={runToName[runId]}
            show={showDownload}
            setShow={setShowDownload}
          />
        </div>

        {/* ShareModal */}
        <ShareModal />
      </ProfilerLog>
    </PrivilegesProvider>
  );
}

export default Run;
