import { ReactNode } from "react";
import { DEFAULT_PRICE_FORMAT, DEFAULT_SPREAD_FORMAT, DEFAULT_YIELD_FORMAT, ONE_DAY, ONE_HOUR, ONE_MINUTE } from "../../constants";
import { AlertTargetType, Side } from "../data/api";
import { VolatilityCondition } from "./alert.types";
import { NumericFormatProps } from "react-number-format";
import { PriceType } from "@/types/types";
import { FilterName } from "../components/filters/filterDrawer";

export const MAX_ALERTS = 500;
export const SEARCH_PARAM_NAME  = 's'

export const ALERT_FILTERS: FilterName[] = ['ticker', 'tenor', 'coupon', 'series', 'rating']

export const SnoozeMap = {
  '1m': ONE_MINUTE,
  '5m': 5 * ONE_MINUTE,
  '15m': 15 * ONE_MINUTE,
  '30m': 30 * ONE_MINUTE,
  '1h': ONE_HOUR,
  '2h': 2 * ONE_HOUR,
  '4h': 4 * ONE_HOUR,
  '8h': 8 * ONE_HOUR,
  '1d': ONE_DAY,
  '1w': 7 * ONE_DAY,
}

export const SnoozeReverseMap = Object.entries(SnoozeMap).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {} as Record<any, any>) // TODO: fix any



export enum AlertColumn {
  Link = 'Link',
  BellIcon = 'bellIcon',
  Warning = 'warning', // 'warning' is a special column to show the warning icon
  Cusip = 'cusip',
  Ticker = 'ticker',
  Coupon = 'coupon',
  Maturity = 'maturity',
  SAndPRating = 'SAndPRating',
  Series = 'series',
  Tenor = 'tenor',
  AmountOutstanding = 'amountOutstanding',
  Size = 'size',
  Side = 'side',
  TargetType = 'targetType',
  CurrentLevel = 'currentLevel',
  TargetValue = 'targetValue',
  Distance = 'distance',
  MinProbabilityPctg = 'minProbabilityPctg',
  CurrentProbability = 'currentProbability',
  VolatilityCondition = 'volatilityCondition',
  VolatilityTodayMin = 'volatilityTodayMin',
  VolatilityTodayMax = 'volatilityTodayMax',
  VolatilityPrevMin = 'volatilityPrevMin',
  VolatilityPrevMax = 'volatilityPrevMax',
  Note = 'note',
  Remove = 'remove',
}

// text for table titles
export const AlertColumnTitle: Record<AlertColumn, string | ReactNode> = {
  [AlertColumn.Link]: '',
  [AlertColumn.BellIcon]: '',
  [AlertColumn.Warning]: '',
  [AlertColumn.Cusip]: 'Cusip',
  [AlertColumn.Ticker]: 'Ticker',
  [AlertColumn.Coupon]: 'Coupon',
  [AlertColumn.Maturity]: 'Maturity',
  [AlertColumn.SAndPRating]: 'S&P',
  [AlertColumn.Tenor]: 'Benchmark',
  [AlertColumn.Series]: 'Series',
  [AlertColumn.AmountOutstanding]: 'Outstanding',
  [AlertColumn.Size]: 'Size',
  [AlertColumn.Side]: 'Side',
  [AlertColumn.TargetType]: 'Type',
  [AlertColumn.CurrentLevel]: 'Current',
  [AlertColumn.TargetValue]: 'Target',
  [AlertColumn.Distance]: 'Difference',
  [AlertColumn.MinProbabilityPctg]: <>Min <br />Probability</>,
  [AlertColumn.CurrentProbability]: <>Current <br />Probability</>,
  [AlertColumn.VolatilityCondition]: 'Condition',
  [AlertColumn.VolatilityTodayMin]: <>Current <br /> Lower Bound </>,
  [AlertColumn.VolatilityTodayMax]: <>Current <br />Upper Bound</>,
  [AlertColumn.VolatilityPrevMin]: <>Prev <br />Lower Bound</>,
  [AlertColumn.VolatilityPrevMax]: <>Prev <br />Upper Bound</>,
  [AlertColumn.Note]: 'Note',
  [AlertColumn.Remove]: '',
}

// text that will be displayed in multicolumn filters 
export const AlertColumnFilterLabel: Record<AlertColumn, ReactNode> = {
  ...AlertColumnTitle,
  [AlertColumn.MinProbabilityPctg]: 'Min Probability',
  [AlertColumn.CurrentProbability]: 'Current Probability',
  [AlertColumn.VolatilityTodayMin]: 'Current Lower Bound',
  [AlertColumn.VolatilityTodayMax]: 'Current Upper Bound',
  [AlertColumn.VolatilityPrevMin]: 'Prev Lower Bound',
  [AlertColumn.VolatilityPrevMax]: 'Prev Upper Bound',
}

export const targetAlertsColumnsMap = {
  [AlertColumn.Link]: true,
  [AlertColumn.BellIcon]: true,
  [AlertColumn.Cusip]: true,
  [AlertColumn.Ticker]: true,
  [AlertColumn.Coupon]: true,
  [AlertColumn.Maturity]: true,
  [AlertColumn.SAndPRating]: true,
  [AlertColumn.Tenor]: true,
  [AlertColumn.Series]: true,
  [AlertColumn.AmountOutstanding]: true,
  [AlertColumn.Size]: true,
  [AlertColumn.Side]: true,
  [AlertColumn.TargetType]: true,
  [AlertColumn.CurrentLevel]: true,
  [AlertColumn.TargetValue]: true,
  [AlertColumn.Distance]: true,
  [AlertColumn.MinProbabilityPctg]: true,
  [AlertColumn.CurrentProbability]: true,
  [AlertColumn.Note]: true,
  [AlertColumn.Remove]: true,
} as Record<AlertColumn, boolean>

export const volatilityAlertsColumnsMap = {
  [AlertColumn.Link]: true,
  [AlertColumn.BellIcon]: true,
  [AlertColumn.Cusip]: true,
  [AlertColumn.Ticker]: true,
  [AlertColumn.Coupon]: true,
  [AlertColumn.Maturity]: true,
  [AlertColumn.SAndPRating]: true,
  [AlertColumn.Tenor]: true,
  [AlertColumn.Series]: true,
  [AlertColumn.AmountOutstanding]: true,
  [AlertColumn.Size]: true,
  [AlertColumn.Side]: true,
  [AlertColumn.TargetType]: true,
  [AlertColumn.VolatilityCondition]: true,
  [AlertColumn.VolatilityTodayMax]: true,
  [AlertColumn.VolatilityTodayMin]: true,
  [AlertColumn.VolatilityPrevMax]: true,
  [AlertColumn.VolatilityPrevMin]: true,
  [AlertColumn.Note]: true,
  [AlertColumn.Remove]: true,
} as Record<AlertColumn, boolean>;

export const TargetAlertsColumnOrder = [
  AlertColumn.Link,
  AlertColumn.BellIcon,
  AlertColumn.Cusip,
  AlertColumn.Ticker,
  AlertColumn.Coupon,
  AlertColumn.Maturity,
  AlertColumn.SAndPRating,
  AlertColumn.Tenor,
  AlertColumn.Series,
  AlertColumn.AmountOutstanding,
  AlertColumn.Size,
  AlertColumn.Side,
  AlertColumn.TargetType,
  AlertColumn.CurrentLevel,
  AlertColumn.TargetValue,
  AlertColumn.Distance,
  AlertColumn.MinProbabilityPctg,
  AlertColumn.CurrentProbability,
  AlertColumn.Note,
  AlertColumn.Remove,
]

export const VolatilityAlertsColumnOrder = [
  AlertColumn.Link,
  AlertColumn.BellIcon,
  AlertColumn.Cusip,
  AlertColumn.Ticker,
  AlertColumn.Coupon,
  AlertColumn.Maturity,
  AlertColumn.SAndPRating,
  AlertColumn.Tenor,
  AlertColumn.Series,
  AlertColumn.AmountOutstanding,
  AlertColumn.Size,
  AlertColumn.Side,
  AlertColumn.TargetType,
  AlertColumn.VolatilityCondition,
  AlertColumn.VolatilityPrevMin,
  AlertColumn.VolatilityPrevMax,
  AlertColumn.VolatilityTodayMin,
  AlertColumn.VolatilityTodayMax,
  AlertColumn.Note,
  AlertColumn.Remove,
]

export const TargetAlertsColumnsOrderConfig = {
  default: TargetAlertsColumnOrder,
}

export const VolatilityAlertsColumnsOrderConfig = {
  default: VolatilityAlertsColumnOrder,
}

export const TARGET_ALERT_SIDES = [Side.bid, Side.offer];
export const VOLATILITY_ALERT_SIDES = [Side.bid, Side.dealer, Side.offer];

export const volatilityConditionOptions: VolatilityCondition[] = [
  [50, 50],
  [55, 45],
  [60, 40],
  [65, 35],
  [70, 30],
  [75, 25],
  [80, 20],
  [85, 15],
  [90, 10],
  [95, 5],
]

export const DEFAULT_VOLATILITY_CONDITION = [95, 5] as VolatilityCondition

export const PRICE_TARGET_FORMAT_MAP: Record<AlertTargetType, NumericFormatProps> = {
  [AlertTargetType.price]: DEFAULT_PRICE_FORMAT,
  [AlertTargetType.ytm]: DEFAULT_YIELD_FORMAT,
  [AlertTargetType.spread]: DEFAULT_SPREAD_FORMAT,
}

export const AlertTargetTypeToPriceType: Record<AlertTargetType, PriceType> = {
  [AlertTargetType.price]: PriceType.Price,
  [AlertTargetType.ytm]: PriceType.Ytm,
  [AlertTargetType.spread]: PriceType.Spread,
}