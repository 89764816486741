import { AtsIndicator, Side } from "@/app/data/api";
import { Bond } from "@/app/data/bondIndex";
import { InferenceRequest } from "@/app/data/useInferences";
import { isEmpty, isEqual } from "lodash";
import { useMemo, useRef } from "react";
import { PriceType } from "@/types/types";

export const useBondsInferenceRequests = <T extends Bond>({ 
  bonds, 
  rfqLabelMap,
  cacheLatest,
  getQuantity,
}: { 
  bonds: T[], 
  rfqLabelMap:  Record<Side, PriceType[]>,
  cacheLatest?: boolean;
  getQuantity: (item: T, side: Side) => number;
}) => {
  const prevResult = useRef<InferenceRequest[]>([]);

  const bondRequests: InferenceRequest[] = useMemo(() => {
    if (!bonds) {
      return [];
    }
    const requests =  ([Side.bid, Side.dealer, Side.offer])
      .reduce((acc, side) => {
        const rfqLabels = rfqLabelMap[side];

        if (isEmpty(rfqLabels)) { // no need to request data for the side if we don't have any labels
          return acc;
        }

        // create bond request for each rfq label
        const bondRequests = bonds.reduce((acc, b) => {
          rfqLabels.forEach(rfqLabel => {
            acc.push({
              figi: b.figi,
              atsIndicator: AtsIndicator.N,
              quantity: getQuantity(b, side),
              side,
              rfq_label: rfqLabel,
            })
          })
          return acc
        }, [] as InferenceRequest[])


        return [...acc, ...bondRequests];
      }, [] as InferenceRequest[]) 
      
      if (cacheLatest && isEqual(requests, prevResult.current)) {
        return prevResult.current;   
      }

      prevResult.current = requests;

      return requests;
  }, [bonds, getQuantity, rfqLabelMap, cacheLatest]);

  return bondRequests;
}