import { Tabs } from "@/app/components/tabs/tabs";
import { ChartType } from "../bond.types";
import { Tooltip } from "@/app/components/tooltip";

export const ChartTypeSwitch = ({ chartType, setChartType }: {
  chartType: ChartType;
  setChartType: (t: ChartType) => void;
}) => {
  const tabStyles = "flex-1 sm:flex-initial text-center whitespace-nowrap";

  return (
    <Tabs
      active={chartType}
      onChange={(t) => setChartType(t)}
      variant="row"
    >
      <Tabs.Header className="w-full sm:w-auto">
        <Tabs.Tab value={ChartType.Recent} className={tabStyles}>Recent</Tabs.Tab>
        <Tabs.Tab value={ChartType.EvenlySpaced} className={tabStyles}>Evenly Spaced</Tabs.Tab>
        <Tooltip
          tooltip="Chance to Fill"
          contentClassName="text-[0.75rem]"
          offset={5}
        >
          <Tabs.Tab value={ChartType.CTF} className={tabStyles}>CTF</Tabs.Tab>
        </Tooltip>
        <Tabs.Tab value={ChartType.Historical} className={tabStyles}>Historical</Tabs.Tab>

      </Tabs.Header>
    </Tabs>
  )
}