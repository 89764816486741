import { Tooltip } from "@/app/components/tooltip";
import { selecteReferenceColumn, selectIsExcelAddIn } from "@/store/slices/excel.slice"
import clsx from "clsx";
import { HTMLAttributes, memo, useEffect } from "react";
import { FaCircleInfo } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux"
import { getColumnIdByTitle } from "../utils/getColumnIdByTitle";
import { openConfirmationDialog } from "@/app/components/confirmationDialog/confirmationDialog.utils";
import { setReferenceColumn } from "@/store/actions/excel.actions";
import { showSelectColumnDialog } from "../utils/showSelectColumnDialog";
import { getSelectedColumnId } from "../excel.utils";
import { ReferenceColumnType } from "../excel.types";

// displays excel reference column along with actions 
export const ExcelReferenceColumnCard = memo((props: { className?: string }) => {
  const isExcelAddIn = useSelector(selectIsExcelAddIn);

  if (!isExcelAddIn) {
    return null;
  }

  return <Content {...props} />;
})

// <Content />
const Content = ({ className }: { className?: string }) => {
  const referenceColumn = useSelector(selecteReferenceColumn);

  const tooltipText = (
    <>
      Reference column is the one with FIGI, CUSIP or ISIN.
      <br />
      <br />
      Deepmm data is matched based on this column. Reference column is optional.
      <br />
      <br />
      If reference column is missing we will add data row by row in the same order as in the excel.
      <br />
      <br />
      Click on <strong>refresh</strong> to detect reference column automatically.
    </>
  )

  const detectReferenceColumn = async (): Promise<boolean> => {
    let type: ReferenceColumnType = '';
    let excelColumnId = await getColumnIdByTitle('isin')

    if (excelColumnId) {
      type = 'isin';
    }

    // check if there is figi column
    if (!excelColumnId) {
      excelColumnId = await getColumnIdByTitle('figi');

      if (excelColumnId) {
        type = 'figi';
      }
    }

    // check if there is cusip column
    if (!excelColumnId) {
      excelColumnId = await getColumnIdByTitle('cusip');

      if (excelColumnId) {
        type = 'cusip';
      }
    }

    if (excelColumnId) {
      await setReferenceColumn({ excelColumnId, type });
      return true;
    } else {
      await setReferenceColumn({ excelColumnId: '', type: '' });
      return false;
    }
  }

  const refreshReferenceColumn = async () => {
    const result = await detectReferenceColumn();

    if (!result) {
      openConfirmationDialog({
        title: 'Reference column not found',
        content: (
          <div>
            Please make sure you have a column with title <strong className="font-semibold text-[#FBFBFD]">FIGI, CUSIP or ISIN</strong> or <strong className="font-semibold text-[#FBFBFD]">set column manually</strong>
          </div>
        ),
        buttonText: 'Ok',
        hideCancel: true,
      })
    }
  }

  useEffect(() => {
    // detect reference column on mount
    detectReferenceColumn();
  }, [])

  async function setReferenceColumnManually() {
    const confirmed = await showSelectColumnDialog({
      title: 'Set reference column',
      buttonText: 'Save',
      content: (
        <div>
          Click on the cell in the column where the <strong className="font-semibold text-[#FBFBFD]">figi/cusip/isin</strong> is located and click <strong className="font-semibold text-[#FBFBFD]">"save"</strong> when done.
        </div>
      )
    })

    if (!confirmed) {
      return;
    }

    const excelColumnId = await getSelectedColumnId()

    if (!excelColumnId) {
      return;
    }

    await setReferenceColumn({ excelColumnId, type: '' });
  }

  async function disconnectReferenceColumn() {
    await setReferenceColumn({ excelColumnId: '', type: '', disconnectAll: true });
  }

  return (
    <div className={clsx(className, 'bg-[#1F2034] p-2 rounded-lg')}>
      <div className="flex items-center gap-3">

        <div className="flex items-center gap-1">
          Reference column

          <Tooltip tooltip={tooltipText} maxWidthCss='max-w-[20.625rem]'>
            <FaCircleInfo onClick={(e) => e.stopPropagation()} />
          </Tooltip>
        </div>

        {referenceColumn.type && (
          <Card>
            {referenceColumn.type}
          </Card>
        )}

        {referenceColumn.columnId && (
          <Card>
            {referenceColumn.columnId ?? '-'}
          </Card>
        )}

        <div className="flex flex-col gap-1 ml-4">

          <Button onClick={() => refreshReferenceColumn()}>
            refresh
          </Button>

          <Button onClick={() => setReferenceColumnManually()}>
            set manually
          </Button>

          {referenceColumn.columnId && (
            <Button onClick={() => disconnectReferenceColumn()}>
              disconnect
            </Button>
          )}


        </div>
      </div>


    </div>
  )
}

const Button = (props: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      {...props}
      className=" text-[0.875rem] py-1 px-2 min-w-[2.5rem] text-center bg-[#5D5F9D] rounded-md text-[#FBFBFD] mx-auto w-full"
    />
  )
}

const Card = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className="font-bold text-[1.25rem] p-4 min-w-[2.5rem] text-center bg-[#5D5F9D] rounded-md text-[#FBFBFD] mx-auto uppercase" {...props} />
  )
}