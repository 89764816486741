import { CheckIcon, PlayIcon } from '@heroicons/react/20/solid';
import ListBox, { ListboxProps } from '../listbox';
import clsx from 'clsx';
import { ReactNode, useEffect } from 'react';
import { CircleCheck } from '../../icons/CircleCheck';

export type DropdownWithRightCheckProps<T extends readonly string[]> = {
  className?: string;
  activeClassName?: string;
  optionClassName?: string;
  isOptionDisabled?: (v: T[number]) => boolean;
  options: T,
  popperProps?: ListboxProps<T>['popperProps'];
  renderOption?: (props: { value: T[number], disabled?: boolean }) => ReactNode;
  renderValue?: (props: { value: T[number] }) => ReactNode;
  onChange: (v: T[number]) => void;
  value: T[number];
  disabled?: boolean;
}

export const DropdownWithRightCheck = <T extends readonly string[]>({
  className,
  activeClassName,
  optionClassName,
  isOptionDisabled,
  options,
  popperProps,
  renderOption,
  renderValue = ({ value }) =>  value,
  onChange,
  value,
  disabled,
}: DropdownWithRightCheckProps<T>) => {
  const _renderOption = renderOption || renderValue;
  
  return (
    <ListBox
      disabled={disabled}
      button={props =>
        <div className={clsx('group/button flex justify-between items-center gap-[0.625rem] whitespace-nowrap', className, {
          [activeClassName || '']: props.open
        })}>
          {renderValue({ value: props.value })}

          <PlayIcon
            className={clsx({
              'min-w-[0.625rem] w-[0.625rem] transform transition-transform': true,
              'rotate-90': !props.open,
              '-rotate-90': props.open
            })}
            aria-hidden="true"
          />
        </div>
      }
      option={(optionValue, props) =>
        <>
          <div className={`${props.selected ? 'font-medium' : 'font-normal'}`}>
            {_renderOption({ value: optionValue, disabled: isOptionDisabled?.(optionValue) })}
          </div>

          <CircleCheck checked={props.selected} />
        </>
      }
      buttonCss={() => 'w-full'}
      optionCss={(v) => {
        const disabled = isOptionDisabled?.(v);

        return clsx(
          'bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default px-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0]', 
          'flex items-center justify-between gap-5 cursor-pointer',
          optionClassName,
          {
          'hover:bg-[#484A7A]': !disabled,
          'text-opacity-50': disabled,
        })
      }}
      optionsCss={' absolute -left-[0.625rem] max-h-[12rem] mt-[0.1875rem] overflow-auto rounded-[0.625rem] w-[5rem] z-[1]'}
      selectValue={(v) => {
        if (isOptionDisabled?.(v)) return;
        
        onChange(v)
      }}
      value={value}
      values={options}
      popperProps={popperProps}
    />
  );
}

