import { DependencyList, useEffect, useRef } from "react";

/** Works in the same way as `useEffect` just skips first run on mount. Useful if we want to check some boolean value change and do some thing after it was changed but not on mount */
export const useEffectWithSkipMount = <T  extends DependencyList>(cb: () => void, deps: T) => {
  const prevDeps = useRef<T | null>(null);

  useEffect(() => {
    if (prevDeps.current) {
      cb();
    }
    prevDeps.current = deps;
  }, deps);
}