import { excelAddInActions, selectColumnConnectionDetails, selecteReferenceColumn, selectIsExcelAddIn } from "@/store/slices/excel.slice"
import clsx from "clsx";
import { HTMLAttributes, memo, MouseEvent } from "react";
import { useDispatch } from "react-redux"
import { checkIfColumnIsEmpty, checkSelectedCellAndReturnColumnId, getSelectedColumnId } from "../excel.utils";
import { openConfirmationDialog, openYesNoDialogPromise } from "@/app/components/confirmationDialog/confirmationDialog.utils";
import { getAppState, useSelector } from "@/store";
import { PortfolioColumn } from "@/app/portfolio/portfolio.constants";
import { RunColumn } from "@/app/run/run.constants";
import { clearColumn } from "../utils/clearColumn";
import { showSelectColumnDialog } from "../utils/showSelectColumnDialog";

type Props = {
  type: 'portfolio'
  columnId: PortfolioColumn;
} | {
  type: 'run'
  columnId: RunColumn;
}

// <ExcelColumnConnection />  - component responsible for connect/disconnect column functionality
export const ExcelColumnConnection = memo((props: Partial<Props>) => {
  const isExcelAddIn = useSelector(selectIsExcelAddIn)

  if (!isExcelAddIn) {
    return null;
  }

  if (!props.columnId || !props.type) { //return placeholder to keep the same height
    return <div className={'h-[1.5625rem] mt-1'}></div>
  }

  return <ColumnConnectionContent {...props as any} />
})

// <ColumnConnectionContent />
const ColumnConnectionContent = ({
  type,
  columnId
}: Props) => {
  const dispatch = useDispatch();
  const columnConnection = useSelector(state => selectColumnConnectionDetails(state, type, columnId));

  // disconnectColumn
  const disconnectColumn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    
    dispatch(excelAddInActions.disconnectColumn({
      type, 
      columnId,
    }))
  }

  const handleConnectClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const selectedExcelColumnId = await checkSelectedCellAndReturnColumnId();

    if (!selectedExcelColumnId) {
      const result = await showSelectColumnDialog({
        title: 'Select column to connect',
        content: 'Click on the cell in the column where you want to show data and click "next" when done.'
      })

      if (!result) {
        return;
      }
    }

    const excelColumnId = await getSelectedColumnId();

    if (!excelColumnId) {
      return;
    }

    // check if column is a reference column 
    // in this case we don't want to connect it but rather ask user to select a different column
    const referenceColumn = selecteReferenceColumn(getAppState());
    if (referenceColumn.columnId === excelColumnId) {
      openConfirmationDialog({
        title: 'Reference column',
        content: 'You are trying to connect a reference column. Please select a different column.',
        buttonText: 'Ok',
        hideCancel: true,
      })
      return
    }

    // check if column is empty
    // show confirmation dialog if not
    const empty = await checkIfColumnIsEmpty(excelColumnId);
    if (!empty) { // column is not empty, let's confirm that user wants to overwrite the data
      const confirmed = await openYesNoDialogPromise({
        title: 'Selected column is not empty',
        content: 'Do you want to overwrite the data?',
      })

      if (!confirmed) {
        return;
      }

      // clear excel column content
      await clearColumn(excelColumnId, { skipIfIsOutsideOurUniverse: true });
    }


    // save selected column
    dispatch(excelAddInActions.setConnectedColumn({
      type,
      columnId,
      excelColumnId,
    }))
  }

  // render connect button if column is not connected
  if (!columnConnection) {
    return (
      <Button
        onClick={handleConnectClick}
        className="mt-1"
      >
        Connect column
      </Button>
    )
  }

  // render connected column details
  return (
    <div className="whitespace-nowrap h-[1.5625rem] mt-1 flex gap-1">
      Col:
      <div className="font-medium flex items-center justify-center px-1 text-[0.875rem] h-full min-w-[1.5rem] text-center bg-[#5D5F9D] rounded-md text-[#FBFBFD] mx-auto uppercase" >
        {columnConnection.excelColumnId}
      </div>

      <Button onClick={disconnectColumn}>
        Disconnect
      </Button>
    </div>
  )
}

// <Button /> - helper styled button component
const Button = (props: HTMLAttributes<HTMLButtonElement>) => {
  return <button
    type="button"
    {...props}
    className={clsx(
      'bg-[#5D5F9D] px-1 py-0.5 min-h-[1.5625rem] rounded-md text-[.875rem] text-[#FBFBFD] whitespace-nowrap font-normal cursor-pointer',
      props.className,
    )}
  />
}