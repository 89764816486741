import { FormattedSpread } from "./formattedSpread";
import { FormattedPrice } from "./formattedPrice";
import { FormattedYtm } from "./formattedYtm";

export const FormatNumber = ({
  value,
  type,
  className
}: {
  value: string | number | null | undefined;
  type: 'ytm' | 'price' | 'spread' | 'spread_decimals';
  className?: string
}) => {
  switch (type) {
    case 'ytm':
      return <FormattedYtm value={value} className={className} />;
    case 'price':
      return <FormattedPrice value={value} className={className} />;
    case 'spread':
      return <FormattedSpread
        value={value}
        className={className}
      />;
    case 'spread_decimals':
      return <FormattedSpread
        value={value}
        className={className}
        decimalScale={1}
      />;
    default:
      throw new Error('Invalid type');
  }
}