export enum ObjectPrivilege {
  Owner = 'owner',
  Write = 'write',
  Read = 'read',
}

export enum BidOfferSpreadType {
  Percentiles = 'percentiles',
  Profit = 'profit',
  Fixed = 'fixed',
  Range = 'range'
}