import clsx from "clsx";

const Loading = ({
  className,
  spinnerClassName,
  centered,
}: {
  className?: string,
  spinnerClassName?: string
  centered?: boolean;
}) => (
  /* adapted from https://loading.io/css/ */
  <div className={clsx("flex items-center justify-center z-[40]", className)}>
    <div className={clsx('animate-spin border-[.3125rem] border-[#C9CADE44_transparent_#C9CADE44_transparent] h-10 w-10 rounded-[50%]', spinnerClassName)}></div>
  </div>
);

export const LoadingOverlay = ({ className, bg }: { className?: string, bg?: string }) => (
  <Overlay className={className} bg={bg}>
    <Loading />
  </Overlay>
);

export const Overlay = ({
  children,
  className,
  bg = 'bg-[#00000033]',
}: {
  children?: React.ReactNode;
  className?: string;
  bg?: string
}) => {
  return <div className={clsx('absolute inset-0 z-[10] flex items-center justify-center', className, bg)}>{children}</div>
}

export default Loading;


export const ModalButtonLoader = () => {
  return (
    <Loading
      className="absolute flex items-center justify-center w-full h-full"
      spinnerClassName="w-6 h-6 border-[0.1875rem] border-[#C9CADE_transparent_#C9CADE_transparent]"
    />
  )
}

