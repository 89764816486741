import { CheckIcon } from '@heroicons/react/20/solid';
import ListBox from './listbox';
import { useUiMode } from '@/hooks/useUiMode';
import clsx from 'clsx';
import { BidOfferSpread } from '@/app/data/runs';
import { BidOfferSpreadType } from '@/types/enums';
import ActionModal from '../modal/actionModal';
import { useEffect, useMemo, useState } from 'react';
import { FilterListBox } from './filterListBox/filterListBox';
import { toast } from 'react-toastify';
import { FaPencil } from 'react-icons/fa6';
import { TableCircleButton } from '../table/components/TableCircleButton';

export type BOSpreadValue = BidOfferSpreadType | number;

export const TableBidOfferSpreadListBox = ({
  selectValue,
  disabled,
  value
}: {
  selectValue: (v: BidOfferSpread) => void,
  disabled?: boolean;
  value: BidOfferSpread
}) => {
  const { isIgUiMode } = useUiMode();
  const [showRangeSelector, setShowRangeSelector] = useState(false);
  const options = isIgUiMode ? BOSpdIGOptions : BOSpdHYOptions
  const _value = value.type === BidOfferSpreadType.Fixed ? value.value : value.type;

  const isRange = value.type === BidOfferSpreadType.Range;
  const min = isRange ? value.min : undefined;
  const max = isRange ? value.max : undefined;

  return (
    <>
      <ListBox
        disabled={disabled}
        divCss='flex-1'
        buttonCss={() => 'w-full'}
        button={props => {
          const buttonValue = typeof props.value === 'string' && props.value === BidOfferSpreadType.Range
            ? `${props.value} (${min} - ${max})`
            : props.value;

          return (
            <div className={clsx('bg-[#8183B3] whitespace-nowrap font-medium leading-[1.17rem] px-[0.3125rem] rounded-[0.3125rem] text-[#0A0B11] text-[1rem]', {
              'cursor-not-allowed': disabled,
              'min-w-[6rem] w-full': !isRange,
              'min-w-[8rem]': isRange,
            })}>
              {buttonValue}
            </div>
          )
        }}
        option={(value, props) =>
          <>
            <div className={`${props.selected ? 'font-medium' : 'font-normal'}`}>
              {value}
            </div>
            {props.selected ? (
              <span className="absolute inset-y-0 left-0 flex items-center pl-[0.25rem]">
                <CheckIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />
              </span>
            ) : null}
          </>
        }
        optionCss={() => 'bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[1.6rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0] hover:bg-[#484A7A]'}
        optionsCss={clsx('absolute -left-[1.125rem] max-h-[7.875rem] mt-[0.1875rem] origin-top-left overflow-auto rounded-[0.625rem] w-[7.75rem] z-[1]')}
        selectValue={(v) => {
          const valueNumber = parseFloat(v);

          if (!isNaN(valueNumber)) {
            selectValue({
              type: BidOfferSpreadType.Fixed,
              value: valueNumber
            });
          } else if (v === BidOfferSpreadType.Range) {
            setShowRangeSelector(true);
          } else if (v === BidOfferSpreadType.Profit || v === BidOfferSpreadType.Percentiles) {
            selectValue({
              type: v,
            });
          } else {
            throw new Error('Invalid value');
          }
        }}
        value={_value as string}
        values={options as string[]}
      />

      {isRange && (
        <TableCircleButton onClick={() => setShowRangeSelector(true)}>
          <FaPencil fontSize={'0.75rem'} />
        </TableCircleButton>
      )}

      <BOSpreadRangeModal
        options={options}
        show={showRangeSelector}
        onClose={() => setShowRangeSelector(false)}
        initialMin={min}
        initialMax={max}
        onSave={(min, max) => {
          selectValue({
            type: BidOfferSpreadType.Range,
            min,
            max,
          });
          setShowRangeSelector(false);
        }}
      />
    </>
  );
}



export const BOSpdHYOptions: BOSpreadValue[] = [
  BidOfferSpreadType.Percentiles, BidOfferSpreadType.Profit, BidOfferSpreadType.Range, 0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1, 1.125, 1.25, 1.375, 1.5, 1.625, 1.75, 1.875, 2, 2.125, 2.25, 2.375, 2.5, 2.625, 2.75, 2.875, 3
]

export const BOSpdIGOptions: BOSpreadValue[] = [
  BidOfferSpreadType.Percentiles, BidOfferSpreadType.Profit, BidOfferSpreadType.Range, 0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50
]

type Option = {
  value: number;
  label: string;
}

const BOSpreadRangeModal = ({
  options,
  show,
  initialMin,
  initialMax,
  onClose,
  onSave,
}: {
  options: BOSpreadValue[],
  show: boolean;
  initialMin: number | undefined;
  initialMax: number | undefined;
  onClose: () => void;
  onSave: (min: number, max: number) => void;
}) => {
  const numberOptions = useMemo(() => {
    return options
      .filter((v) => typeof v === 'number')
      .map(v => ({ 
        label: v.toString(),
        value: v as number,
      }))
  }, [options]);
  const firstElement = numberOptions[0];
  const lastElement = numberOptions[numberOptions.length - 1];

  const [min, setMin] = useState<Option>(firstElement);
  const [max, setMax] = useState<Option>(lastElement);

  useEffect(() => {
    if (show) {
      setMin(numberOptions.find(v => v.value === initialMin) || firstElement);
      setMax(numberOptions.find(v => v.value === initialMax) || lastElement);
    }
  }, [show])

  const getError = () => {
    if (min.value > max.value) {
      return 'Min should not be bigger than Max';
    }
    
    return null;
  }

  function handleSubmit() { 
    const error = getError();

    if (error) {
      toast.error(error, { toastId: 'range-error' });
      return false;
    }

    onSave(min.value, max.value);
  }

  return (
    <ActionModal
      show={show}
      title={'Select Range'}
      action={handleSubmit}
      actionName="Ok"
      size="content"
      onClose={onClose}
      overflow='visible'
      // body 
      body={
        <div className="w-full flex gap-4 min-w-[18.75rem]">
          
          <FilterListBox
            options={numberOptions}
            placeholder="Min"
            value={min}
            onChange={(v) => setMin(v!)}
            className='flex-1'
          />

          <FilterListBox
            options={numberOptions}
            placeholder="Max"
            value={max}
            onChange={(v) => setMax(v!)}
            className='flex-1'
          />
        </div>
      }
    />
  )
}
