import { useSearchParams } from 'react-router-dom';
import StyledListBox from '@components/listbox/styledListbox';
import TradeDateCalendar from '@components/tradeDateCalendar';
import { Bond, spreadOptions, priceOptions, ytmOptions } from '@data/dataProvider';
import { FilterDrawerBase } from '@components/filters/filterDrawerBase';
import { FilterListBox } from '@components/listbox/filterListBox/filterListBox';
import clsx from 'clsx';
import { useFilters } from '@components/filters/hooks/useFilters';
import { getPageSize } from '../table/hooks/useTablePagination';
import { useUiMode } from '@/hooks/useUiMode';

export type FilterName = 'ticker' | 'tenor' | 'coupon' | 'series' | 'rating' | 'bid' | 'offer' | 'previous';
const DEFAULT_ACTIVE_FILTERS: FilterName[] = ['ticker', 'tenor', 'coupon', 'series', 'rating', 'bid', 'offer', 'previous'];

export const FilterDrawer = ({
  show,
  bonds,
  pageSizeParamName,
  onClose,
  activeFilters = DEFAULT_ACTIVE_FILTERS,
}: {
  show: boolean
  bonds: Bond[]
  pageSizeParamName?: string
  onClose: () => void
  activeFilters?: FilterName[]
}) => {
  const { isIgUiMode, isHyUiMode } = useUiMode();
  const [searchParams, setSearchParams] = useSearchParams();


  const {
    clearAll,
    hasActiveFilters,

    // ticker
    tickerFilter,
    tickerOptions,
    setTickerFilter,

    // tenor
    tenorFilter,
    tenorBucketsOptions,
    setTenorFilter,

    // coupon
    couponFilter,
    couponOptions,
    setCouponFilter,

    // series
    seriesFilter,
    seriesOptions,
    setSeriesFilter,

    // rating
    ratingFilter,
    ratingOptions,
    setRatingFilter,

    // bid
    bidFilter,
    setBidFilter,

    // offer
    offerFilter,
    setOfferFilter,

    // bid spread
    bidSpreadFilter,
    setBidSpreadFilter,

    // offer spread
    offerSpreadFilter,
    setOfferSpreadFilter,

    // bid ytm
    bidYtmFilter,
    setBidYtmFilter,

    // offer ytm
    offerYtmFilter,
    setOfferYtmFilter,

    // previous
    previousMs,
    setPreviousMs,
  } = useFilters(bonds);

  return (
    <FilterDrawerBase open={show} onClose={onClose}>

      <div className='w-full p-2.5 flex font-medium text-[0.875rem]'>
        <button
          type="button"
          className={clsx({
            'text-[#8183B3] cursor-not-allowed': !hasActiveFilters,
            'text-[#EDEDF4] cursor-pointer': hasActiveFilters,
          })}
          onClick={() => clearAll()}
        >
          Clear All
        </button>
      </div>

      <div className="w-full">

        {/* Ticker */}
        {activeFilters.includes('ticker') && (
          <FilterListBox
            className="mb-2.5"
            options={tickerOptions}
            isMulti
            placeholder="Enter Ticker"
            isSearchable
            value={tickerFilter}
            onChange={(v) => setTickerFilter(v)}
          />
        )}

        {/* Tenor */}
        {activeFilters.includes('tenor') && (
          <FilterListBox
            placeholder="Select Tenor"
            className="mb-2.5"
            options={tenorBucketsOptions}
            isClearable
            closeMenuOnSelect={false}
            isMulti
            value={tenorFilter}
            onChange={(v) => setTenorFilter(v)}
          />
        )}

        {/* Coupon */}
        {activeFilters.includes('coupon') && (
          <FilterListBox
            placeholder="Select Coupon"
            className="mb-2.5"
            options={couponOptions}
            isMulti
            value={couponFilter}
            onChange={(v) => setCouponFilter(v)}
          />
        )}

        {/* Series */}
        {activeFilters.includes('series') && (
          <FilterListBox
            placeholder="Select Series"
            className="mb-2.5"
            options={seriesOptions}
            value={seriesFilter}
            onChange={(v) => setSeriesFilter(v)}
            isClearable
          />
        )}

        {/* Rating */}
        {activeFilters.includes('rating') && (
          <FilterListBox
            placeholder="Select Rating"
            className="mb-2.5"
            options={ratingOptions}
            value={ratingFilter}
            onChange={(v) => setRatingFilter(v)}
            isClearable
            isMulti
          />
        )}

        {/* Bid Price */}
        {activeFilters.includes('bid') && isHyUiMode && (
          <FilterListBox
            placeholder="Select Bid Buckets"
            className="mb-2.5"
            options={priceOptions}
            isClearable
            value={bidFilter}
            onChange={(v) => setBidFilter(v)}
          />
        )}

        {/* Offer Price */}
        {activeFilters.includes('offer') && isHyUiMode && (
          <FilterListBox
            placeholder="Select Offer Buckets"
            className="mb-2.5"
            options={priceOptions}
            isClearable
            value={offerFilter}
            onChange={(v) => setOfferFilter(v)}
          />
        )}

        {/* Bid Spread */}
        {activeFilters.includes('bid') && isIgUiMode && (
          <FilterListBox
            placeholder="Select Bid Spread Buckets"
            className="mb-2.5"
            options={spreadOptions}
            isClearable
            value={bidSpreadFilter}
            onChange={(v) => setBidSpreadFilter(v)}
          />
        )}

        {/* Offer Spread */}
        {activeFilters.includes('offer') && isIgUiMode && (
          <FilterListBox
            placeholder="Select Offer Spread Buckets"
            className="mb-2.5"
            options={spreadOptions}
            isClearable
            value={offerSpreadFilter}
            onChange={(v) => setOfferSpreadFilter(v)}
          />
        )}

        {/* Bid YTM */}
        {activeFilters.includes('bid') && isIgUiMode && (
          <FilterListBox
            placeholder="Select Bid YTM Buckets"
            className="mb-2.5"
            options={ytmOptions}
            isClearable
            value={bidYtmFilter}
            onChange={(v) => setBidYtmFilter(v)}
          />
        )}

        {/* Offer YTM */}
        {activeFilters.includes('offer') && isIgUiMode && (
          <FilterListBox
            placeholder="Select Offer YTM Buckets"
            className="mb-2.5"
            options={ytmOptions}
            isClearable
            value={offerYtmFilter}
            onChange={(v) => setOfferYtmFilter(v)}
          />
        )}

      </div>

      <div className='mt-auto' />

      {/* Page Size Selector */}
      {pageSizeParamName && (
        <div className="flex flex-row gap-[0.625rem] items-center justify-start w-full">
          <span className="text-[#FBFBFD]">Page size:</span>
          <div className="w-[5.5rem]">
            <StyledListBox
              selectValue={v => void setSearchParams(prev => { prev.set(pageSizeParamName, v); return prev; })}
              value={`${getPageSize(searchParams.get(pageSizeParamName))}`}
              values={['10', '25', '50', '100']}
            />
          </div>
        </div>
      )}

      {/* Calendar */}
      {activeFilters.includes('previous') && (
        <TradeDateCalendar
          value={previousMs}
          selectValue={v => setPreviousMs(v)}
        />
      )}
    </FilterDrawerBase>
  )
}

