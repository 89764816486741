import { AlertSideDropdown } from "../../dropdowns/alertSideDropdown";
import { AlertSizeDropdown } from "../../dropdowns/alertSizeDropdown";
import { AlertTargetTypeDropdown } from "../../dropdowns/alertTargetTypeDropdown";
import { AlertCardProps } from "../alertCard";
import { Col, DarkBox, Row } from "@components/itemCard";
import { AlertTargetInput } from "../../alertTargetInput";
import { MinProbabilityDropdown } from "../../dropdowns/minProbabilityDropdown";
import { FormatNumber } from "@/app/components/formatNumber/formatNumber";
import { AlertTargetTypeToPriceType } from "../../alert.constants";

export const TargetAlertDetails = ({
  alertItem,
  canChangeSide,
  data,
  onChange,
}: AlertCardProps) => {
  const { alert } = alertItem;
  const d = data.alert[alert.id];
  const priceType = AlertTargetTypeToPriceType[alert.targetType];

  return (
    <>
      <DarkBox>
        <Row>
          <Col label="Size">
            <AlertSizeDropdown alert={alert} onChange={onChange} />
          </Col>

          <Col label="Side" end>
            <AlertSideDropdown
              alert={alert}
              onChange={onChange}
              canChangeSide={canChangeSide}
            />
          </Col>
        </Row>

        <Row>
          <Col label="Type">
            <AlertTargetTypeDropdown alert={alert} onChange={onChange} />
          </Col>
        </Row>
      </DarkBox>

      <DarkBox>
        <Row>
          <Col label="Current">
            <FormatNumber type={priceType} value={d.currentStr} />
          </Col>

          <Col label="Target" end>
            <AlertTargetInput
              key={alert.targetType} // re-render input when target type changes
              alert={alertItem}
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row>
          <Col
            label="Difference"
            valueClassName={d.distanceBgColor}
            box
          >
            <FormatNumber type={priceType} value={d.distance} />
          </Col>
        </Row>
      </DarkBox>

      <DarkBox>


        <Row>
          <Col label={<>Min <br />Probability</>}>
            <MinProbabilityDropdown alert={alert} onChange={onChange} />
          </Col>

          <Col
            label={<>Current <br />Probability</>}
            value={d.currentPctgRange}
            valueClassName={d.pctgBgColor}
            end
            box
          />
        </Row>
      </DarkBox>
    </>
  )
}