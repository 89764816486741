import { PeriodConfig, TIME_PERIOD_CONFIG, TimePeriod } from "./historicalChart.consants";
import { IsFinraHoliday } from "@/app/data/dataProvider";
import { isWeekend } from "@/utils/date.utils";
import { DateTime } from "luxon";

export const getTimeArray = ({
  timePeriod,
  isFinraHoliday,
  settlementDate,
  customPeriod,
}: {
  timePeriod: TimePeriod,
  settlementDate: Date
  isFinraHoliday: IsFinraHoliday
  customPeriod: [number, number] | undefined
}): string[] => {
  if (!isFinraHoliday) {
    return [];
  }

  const isCustomPeriod = timePeriod === TimePeriod.Custom;
  let config = TIME_PERIOD_CONFIG[timePeriod];
  if (timePeriod === TimePeriod.Max) {
    config = calculateCustomConfig(settlementDate);
  } else if (isCustomPeriod && customPeriod) {
    const startDate = new Date(customPeriod[0]);
    const endDate = new Date(customPeriod[1]);
    config = calculateCustomConfig(startDate, endDate)
  } else if (!config) {
    return [];
  }

  const { minus, step, getInitialTime, startDate, endDate, setHour } = config
  const periodEnd = isCustomPeriod 
    ?  endDate
      ? DateTime.fromJSDate(endDate)
      : getInitialTime()
    : getInitialTime();
  let periodStart = DateTime.fromMillis(periodEnd.toMillis()).minus(minus)
  const shouldFetchWeekends = timePeriod === TimePeriod["1D"] || timePeriod === TimePeriod["5D"] || Boolean(step?.weeks) || Boolean(step?.months) || isCustomPeriod;
  const _startDate = startDate
    ? setHour 
      ? setHour(DateTime.fromJSDate(startDate)).toJSDate()
      : startDate
    : undefined;
  const _endDate = endDate
    ? setHour
      ? setHour(DateTime.fromJSDate(endDate)).toJSDate()
      : endDate
    : undefined


  const times: string[] = []; // array of times

  
  // add start date if it's defined
  if (startDate) {
    times.push(startDate.toISOString());
  }

  let j = 0;
  for (let i = periodStart; i <= periodEnd; i = i.plus(step)) {
    const date = new Date(i.toMillis());
    

    
    if (_startDate && date <= _startDate) {
      // for custom interval we have bigger period than requested
      // so we need to skip dates which are less than startDate
      continue;
    }

    if (_endDate && date > _endDate) {
      break;
    }

    if (!shouldFetchWeekends && isWeekend(date)) { // no need to fetch weekend date if duration is more than 5 days
      continue;
    }

    if (isFinraHoliday(date)) {
      continue;
    }

    times.push(date.toISOString());
    j++;

    if (j === 2000) {
      throw new Error('Too many iterations');
    }
  }

  // add end date as now
  if (timePeriod !== TimePeriod.Custom) {
    const now = DateTime.now().toJSDate()
    if (periodEnd.toJSDate() < now) {
      times.push(now.toISOString());
    }
  }

  return times;
}


// calculates custom config based on the date
function calculateCustomConfig(startDate: Date, endDate?: Date): PeriodConfig {
  const configs = Object.values(TIME_PERIOD_CONFIG).filter(Boolean).reverse();
  const diff = (endDate || new Date()).getTime() - startDate.getTime();

  for (let i = 0; i < configs.length; i++) {
    const config = configs[i];
    const { getInitialTime, minus } = config!;
    const periodStartDateMs = getInitialTime().minus(minus).toMillis();
    const periodDiff = getInitialTime().toMillis() - periodStartDateMs;

    if (diff > periodDiff) {
      const prevConfig = configs[i - 1];
      if (!prevConfig) {
        throw new Error('No prev config found');
      }
      return {
        ...prevConfig,
        startDate: startDate,
        endDate,
      };
    }
  }

  throw new Error('No config found');

}