import clsx from 'clsx'
import { KeyboardEvent } from 'react'
import { FieldArrayWithId, useFormContext } from 'react-hook-form'
import { FormValues } from '@/screens/modals/ShareModal/ShareModal.types'
import { XMarkIcon } from '@heroicons/react/24/outline';
import { AdaptiveInput } from '@/app/components/fields/AdaptiveInput/AdaptiveInput';
import styles from './EmailListItem.module.scss'

export const EmailListItem = ({
  index,
  onRemove,
}: {
  index: number
  field: FieldArrayWithId<FormValues>
  onRemove: () => void
}) => {
  const { register, setValue, watch } = useFormContext<FormValues>()
  const stopPropagation = (e: React.MouseEvent) => {
    // stop propagation as any click on the container will focus the input and we don't want to do it in case someone clicks on the email
    e.preventDefault()
    e.stopPropagation()
  }

  const value = watch(`emails.${index}.value`)

  function handleInputChange(value: string) {
    setValue(`emails.${index}.value`, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
  }

  function handleInputKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    // remove focus from the input after pressing enter
    if (e.key === 'Enter') {
      e.currentTarget.blur()
    }
  }

  function handleInputBlur() {
    // remove email if it's empty after editing
    if (value.trim() === '') {
      onRemove()
    }
  }

  const containerStyles = clsx(styles.container)

  return (
    <div className={containerStyles} onClick={stopPropagation}>
      {/* editable value of the email */}
      <AdaptiveInput
        value={value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
        className={styles.valueInput}
        readOnly
      />

      {/* hidden input just to register input for react-hook-form */}
      <input type='hidden' {...register(`emails.${index}.value`)} />

      {/* delete email button */}
      <button type='button' onClick={onRemove} className={styles.deleteButton}>
        <XMarkIcon width="1rem" height="1rem" />
      </button>
    </div>
  )
}
