import { useCallback, useEffect } from "react";

import { captureException } from "@sentry/core";
import { UserPreferences } from "@/types/types";
import { useDispatch } from "react-redux";
import { preferencesEndpoints, useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } from "@/store/api/userPreferences.endpoints";
import { debounce } from "lodash";
import { useLatestRef } from "@hooks/useLatestRef";
import { BroadcastChannelName } from "@/constants";
import { broadcastMessage } from "@/utils/broadcastChannel.utils";
import { toast } from "react-toastify";
import { getAppState } from "@/store";

export type PartialUpdateData = Partial<UserPreferences>;

type Ref = {
  version: string | null;
  saveInProgress: boolean;
  data: UserPreferences | null;
}

const ref: Ref = {
  version: null,
  saveInProgress: false,
  data: null,
}

// hooks which saves data on backend and syncs it with other tabs
export const useSyncUserPreferences = ({ errorMessage }: { errorMessage?: string} = {}) => {
  const dispatch = useDispatch();
  const [updatePreferencesApi] = useUpdateUserPreferencesMutation();
  const { data: _userPreferences } = useGetUserPreferencesQuery();

  const preferencesRef = useLatestRef(_userPreferences);

  useEffect(() => {
    // sync version with latest if preferences object was changed wihtout using `syncPreferences` function, for exmaple if preferences object was updated from another tab
    const v = _userPreferences?.version
    if (v && v !== ref.version) {
      ref.version = v
    }
  }, [_userPreferences?.version])

  const syncPreferencesDebouncedApi = useCallback(
    debounce(async (preferences: UserPreferences) => {
      if (ref.saveInProgress) {
        // last save is still in progress. Sync will be triggered with latest data after previous request is done
        return;
      }

      const latestVersion = ref.version || preferencesRef.current?.version as string;
      ref.data = null;
      ref.saveInProgress = true;
  
      try {
        const data = await updatePreferencesApi({ ...preferences, version: latestVersion! }).unwrap();
        broadcastMessage(BroadcastChannelName.UserPreferencesUpdate, data)
        ref.version = data.version; // save latest version
      } catch (e) {
        captureException(e);

        if (ref.data) {
          // request will be retried with latest data, no need to inform user that previous request has failed
        } else {
          // inform user that request has failed
          toast.error(errorMessage || 'Failed to save user preferences. Please try again later.');
        }
      }
      
      ref.saveInProgress = false;
  
      // trigger sync preferences again if there are new changes
      const latestPreferencesData = ref.data;
      if (latestPreferencesData) {
        updateUserPreferences(latestPreferencesData);
      }
    }, 300)
  , [])

  const updateUserPreferences = (preferences: Partial<UserPreferences>) => {
    const cachedData = preferencesEndpoints.endpoints.getUserPreferences.select()(getAppState());
    const updatedPreferences: UserPreferences = { ...(cachedData.data as UserPreferences), ...preferences};
    
    broadcastMessage(BroadcastChannelName.UserPreferencesUpdate, updatedPreferences)
    updateStoredQueryData(updatedPreferences) // sync data in store so it has latest data
    ref.data = updatedPreferences; // save latest data for request

    syncPreferencesDebouncedApi(updatedPreferences);
  }

  const updateStoredQueryData = useCallback((preferences: UserPreferences) => {
    const cachedData = preferencesEndpoints.endpoints.getUserPreferences.select()(getAppState());

    if (!cachedData.data) {
      return;
    }

    const d = {
      ...cachedData.data,
      ...preferences,
    }

    const action = preferencesEndpoints.util.updateQueryData('getUserPreferences', undefined, () => d);
    dispatch(action as any)
  }, [])

  return { 
    updateUserPreferences,
    updateStoredQueryData,
  }
}
