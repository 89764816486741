/* eslint-disable no-loop-func */
import { Bond } from "@/app/data/bondIndex";
import { RunColumn } from "@/app/run/run.constants";
import { InferenceResultData } from "@/hooks/data/useSimpleInferenceData";
import { excelAddInActions, selecteReferenceColumn, selectExcelInCellEditingMode, selectIsExcelAddIn, selectRunColumnsConnectionDetails } from "@/store/slices/excel.slice";
import { isEmpty } from "lodash";
import { MutableRefObject, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { checkIfCellIsEmpty, convertCellDataToMap, getColumnValuesWithPositions, getSkipRowsMap, insertText } from "../excel.utils";
import { getColumnsValueFormatter } from "@/app/run/utils/getColumnsValueFormatter";
import { useUiMode } from "@/hooks/useUiMode";
import { CellData, insertTextsInCellsV2 } from "../utils/insertTextsInCells";
import { throttleAsync } from "@/utils/throttleAsync";
import { UIMode } from "@/types/types";
import { getColumnTitle } from "@/app/run/run.utils";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";
import { useExcelIsInEditModeWarning } from "./hooks/useExcelIsInEditModeWarning";
import { dispatcher } from "@/store";
import { TableRefData } from "@/app/components/table/table";
import { ColumnConnection, ReferenceColumn } from "../excel.types";
import { useFigiUniverse } from "./hooks/useFigiUniverse";
import { useLatest } from "react-use";

type TProps<T extends Bond> = {
  items: T[],
  data: InferenceResultData<T>
  tableRef?: MutableRefObject<TableRefData<T, RunColumn> | null> | undefined
}

// <ExcelColumnSyncManager /> - component is responsible for syncing data from our app tables to excel columns
export const ExcelColumnSyncManager = <T extends Bond>(props: TProps<T>) => {
  const isExcelAddIn = useSelector(selectIsExcelAddIn)

  if (!isExcelAddIn) {
    return null;
  }


  return <Content {...props} />;
}

// Content
// render it as separate component as will subscribe to store and sync data
const Content = <T extends Bond>({
  items,
  data,
  tableRef,
}: TProps<T>) => {
  const { uiMode } = useUiMode()
  const columnsConnectionDetails = useSelector(selectRunColumnsConnectionDetails);
  const referenceColumn = useSelector(selecteReferenceColumn)
  const isInEditMode = useSelector(selectExcelInCellEditingMode);

  useExcelIsInEditModeWarning()
  const { figiUniverseLoaded } = useFigiUniverse();

  // sync data to excel columns
  async function syncColumns({
    items,
    data,
    columnsConnectionDetails,
    referenceColumn,
    uiMode,
  }: {
    items: T[],
    data: InferenceResultData<T>,
    columnsConnectionDetails: ColumnConnection[],
    referenceColumn: ReferenceColumn,
    uiMode: UIMode,
  }) {
    if (isEmpty(columnsConnectionDetails)) { // no columns connected, no need to sync
      return;
    }


    try {
      const _items = referenceColumn.columnId
          ? items
          : tableRef?.current?.getPrintData()?.rows?.map(r => r.item) || items; // if we do not have reference column we need to get items from tableRef so they are in the same order as in table

      const referenceCellData = referenceColumn.columnId ? await getColumnValuesWithPositions(referenceColumn.columnId) : []
      const rowIdMap = convertCellDataToMap(referenceCellData); // map of row id to figi/cusip/isin
      const skipRowsMap = await getSkipRowsMap({
        referenceColumn,
        referenceCellData,
      });

      let columnValues: CellData[] = [];
      let minRowId = Infinity; // we use it to remove empty values before minRowId as we do not want to sync empty values. Also to check if we need to insert column title

      // sync each column
      // do it one by one as excel can not handle doing it in parallel
      for (const c of columnsConnectionDetails) {
        const { columnId, excelColumnId } = c;
        const columnsFormatter = getColumnsValueFormatter({ data, uiMode })
        const getValue = columnsFormatter[columnId as RunColumn]?.getPrintValue || columnsFormatter[columnId as RunColumn]?.getValue
        const getBgColor = columnsFormatter[columnId as RunColumn]?.getBgColor;


        if (!getValue) {
          console.error(`Column ${columnId} does not have getValue or getPrintValue function`)
          return;
        }

        _items.forEach((item, idx) => {
          // let value: number | string
          const value = getValue(item as any);;

          let rowId: number | undefined;

          if (referenceColumn.columnId) {
            const idKey = referenceColumn.type as keyof Bond;

            rowId = idKey
              ? rowIdMap.get(item[idKey] as string)
              : (rowIdMap.get(item.cusip) || rowIdMap.get(item.figi) || rowIdMap.get(item.isin));

            // we have reference column so `rowId` is required in this case. If row Id is missing we weren't able to reference our column data to excel figi/cusip/isin row id
            if (!rowId) {
              return;
            }
          } else {
            // we do not have reference column -> using index as row id
            rowId = idx + 2; // +2 as excel starts from 1 and we have header row
            // value = item[columnId as RunColumn] as any;
          }



          if (rowId < minRowId) {
            minRowId = rowId;
          }

          columnValues[rowId] = { 
            value: `${value}`,
          };

          if (getBgColor) {
            columnValues[rowId].backgroundColor = getBgColor(item as any)
            columnValues[rowId].textColor = '#FBFBFD'
          }

        })



        // set default value for missing values
        for (let i = 0; i < columnValues.length; i++) { // using for loop instead of map as we will have some array values that weren't set and .map will skip them
          if (!columnValues[i]) {
            columnValues[i] = { value: '' };
          }
        }

        columnValues = columnValues.slice(minRowId) // remove empty values before minRowId
        const rangeValues: CellData[][] = columnValues.map((v): CellData[] => [v]);

        // sync value to excel column
        const startCell = `${excelColumnId}${minRowId}`;

        await insertTextsInCellsV2(startCell, rangeValues, skipRowsMap)

        if (minRowId !== 1) {
          // in this case we assume that first row is header
          // so we need to insert column title if first row is empty
          await insertColumnTitleIfRowIsEmpty(excelColumnId, 'run', columnId);
        }
      }
    } catch (error: any) {
      console.error(error)

      if (error.code === "InvalidOperationInCellEditMode") { // if we are in cell editing mode we need to wait until user exits cell editing mode
        dispatcher(excelAddInActions.setExcelInCellEditingMode(true));
      } else {
        toast.error("Error syncing data to excel column", { toastId: 'sync-error' });
        captureException(error);
      }
    }
  }

  async function insertColumnTitleIfRowIsEmpty(excelColumnId: string, type: 'run' | 'portfolio', columnId: string): Promise<void> {
    if (type === 'run') {
      const empty = await checkIfCellIsEmpty(`${excelColumnId}1`)

      if (empty) {
        const title = getColumnTitle()(columnId as RunColumn); // TODO: provide previousString for  getColumnTitle
        await insertText(title, `${excelColumnId}1`)
      }

    } else {
      // TODO: implement portfolio when needed
    }

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const syncColumnsThrottled = useCallback(throttleAsync(syncColumns, 500), []);

  useEffect(() => {
    if (isInEditMode) {
      return; // it's not possible to sync data when in cell editing mode
    }

    if (!figiUniverseLoaded) {
      return; // we need to wait until figi universe is loaded
    }

    syncColumnsThrottled({
      items,
      data,
      columnsConnectionDetails,
      referenceColumn,
      uiMode,
    });
  }, [
    items,
    data,
    columnsConnectionDetails,
    referenceColumn,
    uiMode,
    isInEditMode,
    syncColumnsThrottled,
    figiUniverseLoaded,
  ])



  return null; // render nothing as this component just syncs data
}


