import { ReactNode } from "react";
import { useTabsContext } from "./tabs";
import clsx from "clsx";
import { TabStylesMap } from "./tabs.types";

export const TabsHeader = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string;
}) => {
  const { variant, style } = useTabsContext();

  const css: TabStylesMap = {
    default: {
      default: 'flex flex-row gap-[0.375rem] items-center sm:gap-[0.625rem]',
      dark: 'TODO', // implement if needed
    },
    row: {
      default: `inline-flex items-center gap-0.5 
        bg-[#333557] p-1
        text-[#89AFEE] text-[0.875rem] text-left
        rounded-[0.5rem]
        `,
      dark: `inline-flex items-center gap-0.5 
        bg-[#0A0B11] p-1
        text-[#FBFBFD] text-[0.875rem] text-left
        rounded-[0.5rem]
        border-[1px] border-[#333557]

        `,
    }
  }


  return (
    <div className={clsx(css[variant][style], className)}>
      {children}
    </div>
  );
}