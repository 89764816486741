import { useId } from 'react';
import { useParams } from "react-router-dom";
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useOpenPage } from '../../hooks/useOpenPage';
import clsx from 'clsx';

const Search = () => {

  const openPage = useOpenPage();
  const { query } = useParams();

  const searchId = useId();

  return (
    <div className='relative'>
      <label htmlFor={searchId} className="col-start-2 row-start-1 visually-hidden">
        Search
      </label>
      <input
        autoComplete="off"
        className={clsx("bg-[#5D5F9D] border-0 col-start-2 max-w-[22.5rem] pl-[3.125rem] py-[0.625rem]",
          "ring-0 rounded-full row-start-1",
          "text-[1.25rem] w-full",
          "focus:ring-2 focus:ring-inset focus:ring-[#4384C8] placeholder:text-[#C9CADE]")}
        id={searchId}
        name={searchId}
        onChange={e => void openPage.searchResult(e.target.value, { preventScrollReset: true })}
        placeholder="Search"
        type="search"
        value={query || ''} />
      <MagnifyingGlassIcon className="col-start-2 float-left h-[1.25rem] pl-4 row-start-1 text-[#C9CADE] absolute top-1/2 -translate-y-1/2" />
    </div>
  );
};

export default Search;
