import { FormProvider, useForm } from "react-hook-form"
import { FormValues } from "../ShareModal.types"
import { EmailListField } from "./EmailListField/EmailListField";
import { PrivilegeSelector } from "./PrivilegeSelector";
import { differenceBy, isEmpty, uniq } from "lodash";
import { toast } from "react-toastify";
import { useUpdateObjectPrivilegeMutation } from "@/store/api/object.endpoints";
import { captureException } from "@sentry/react";
import { DEFAULT_PRIVILEGE } from "../ShareModal.constants";
import { ModalButtonLoader } from "@/app/loading";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { PrivilegeUser } from "@/app/data/objects";

export const AddUsersForm = ({
  objectId,
  currnetUsers,
  onSubmittingChange,
  refetchPrivilegeList,
}: {
  objectId: string | undefined;
  currnetUsers: PrivilegeUser[]
  onSubmittingChange: (isSubmitting: boolean) => void;
  refetchPrivilegeList: () => Promise<void>;
}) => {
  const [updateObjectPrivilegeApi] = useUpdateObjectPrivilegeMutation();
  const existingEmails = useMemo(() => currnetUsers.map(user => user.email.toLowerCase()), [currnetUsers]);

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      emails: [],
      privilege: DEFAULT_PRIVILEGE,
    },
  });

  const { handleSubmit, reset, formState: { isSubmitting } } = formMethods;

  useEffect(() => {
    onSubmittingChange(isSubmitting);
  }, [isSubmitting])

  async function onSubmit(data: FormValues) {
    if (isEmpty(data.emails)) {
      toast.error('Add at least one valid email');
      return;
    }

    if (!objectId) {
      captureException(new Error('objectId is not defined when sharing an object '));
      toast.error('Something went wrong. Please try again later');
      return;
    }

    const emailList = uniq(data.emails.map(email => email.value));
    let emails = differenceBy(emailList, existingEmails, v => v.toLowerCase());

    if (isEmpty(emails)) {
      toast.error('All emails are already added to the list');
      return;
    }

    const results = await Promise.allSettled(emails.map(email => {
      return updateObjectPrivilegeApi({
        email: email,
        privilege: data.privilege,
        objectId,
      }).unwrap();
    }));

    const failedEmails: { value: string }[] = [];
    const successEmails: string[] = [];

    results.forEach((result, index) => {
      const email = emails[index];

      if (result.status === 'rejected') {
        failedEmails.push({ value: email });
      } else {
        successEmails.push(email);
      }
    })

    // reset form but keep failed emails in the list
    reset({
      emails: failedEmails,
      privilege: DEFAULT_PRIVILEGE,
    })

    const successCounter = successEmails.length;

    if (failedEmails.length > 0) {
      const message = 
        failedEmails.length === 1 
          ? `Failed to add user "${failedEmails[0].value}"`
          : `Failed to add ${failedEmails.length} users`;

      toast.error(message);
    } else {
      const message = 
      successCounter === 1 
          ? `"${successEmails[0]}" added successfully`
          : `${successCounter} users added successfully`;

      toast.success(message);
    }

    if (successCounter > 0) {
      await refetchPrivilegeList();
    }
  }

  const renderButton = (type: 'desktop' | 'mobile') => {
    const css = clsx(
      'relative items-center justify-center h-[2.5rem] min-w-[6.25rem] px-4 rounded-[0.625rem] bg-[#4384C8] hover:bg-[#448fde] text-[#FBFBFD] font-medium text-[0.875rem]',
      {
        'hidden sm:flex': type === 'desktop',
        'sm:hidden mt-[1rem] w-full': type === 'mobile',
      }
    )
    return (
      <button type="submit" className={css}>
        {isSubmitting ? <ModalButtonLoader /> : 'Add'}
      </button>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form 
        onSubmit={handleSubmit(onSubmit)} 
        className={clsx({
          'pointer-events-none': isSubmitting,
        })}
      >
        <div className="flex gap-5 items-start">
          <EmailListField existingEmails={existingEmails} />

          {renderButton('desktop')}          
        </div>

        <div className="mb-[1rem]" />

        <div className="flex justify-center sm:justify-start">
          <PrivilegeSelector />
        </div>

        {renderButton('mobile')}
      </form>
    </FormProvider>
  )
}
