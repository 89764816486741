'use client'

import { createContext, useContext } from "react"

type RunPrivilegesState = {
  canEdit: () => boolean;
}

export const RunPrivilegesContext = createContext<RunPrivilegesState>({} as any)
export const RunPrivilegesProvider = RunPrivilegesContext.Provider

export const useRunPrivileges = () => useContext(RunPrivilegesContext)
