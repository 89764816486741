import { TimePeriodSwitcher } from "./components/timePeriodSwitcher";
import { TimePeriod } from "./historicalChart.consants";
import { memo, useEffect, useMemo, useState } from "react";
import { SideSelector } from "./components/sideSelector";
import { PercentileToggle } from "./components/percentileToggle";
import { AtsIndicator, InferenceRequestItem, Side } from "@/app/data/api";
import { Chart } from "./components/chart/chart";
import { DateTime } from "luxon";
import { ONE_MINUTE } from "@/constants";
import { getTimeArray } from "./historicalChart.utils";
import { Bond, useDataContext } from "@/app/data/dataProvider";
import { PriceType } from "@/types/types";
import { useHistoricalChartInferences } from "./hooks/useHistoricalChartInferences";
import { useInferencesLinesData } from "./hooks/useInferenceLinesData";
import { isEmpty } from "lodash";
import Loading, { LoadingOverlay } from "@/app/loading";

export const HistoricalChart = memo(({
  bond,
  atsIndicator,
  quantity,
  priceType,
}: {
  bond: Bond;
  atsIndicator: AtsIndicator,
  quantity: number;
  priceType: PriceType
}) => {
  const [customPeriod, setCustomPeriod] = useState<[number, number] | undefined>(undefined);
  const [timePeriod, setTimePeriod] = useState(TimePeriod["1D"]);
  const [percentileActive, setPercentileActive] = useState(true);
  const [sides, setSides] = useState<Record<Side, boolean>>({
    [Side.bid]: true,
    [Side.dealer]: true,
    [Side.offer]: true,
  })

  const settlementDate = useMemo(() => {
    return DateTime.fromISO(bond.settlementDate).toJSDate();
  }, [bond.settlementDate]);

  // get inferences for current time period
  const {
    times,
    bidInferences,
    dealerInferences,
    offerInferences,
  } = useHistoricalChartInferences({
    figi: bond.figi,
    atsIndicator,
    quantity,
    priceType,
    bond,
    timePeriod,
    sides,
    settlementDate,
    customPeriod,
  });

  const dataLoading = isEmpty(bidInferences) && isEmpty(dealerInferences) && isEmpty(offerInferences);
  const firstTime = times[0];
  const lastTime = times[times.length - 2]; // we care about one before last time as last time might be updated in the future by refetching new data but previous times will not change
  const softLoading = bidInferences[0]?.date.getTime() !== new Date(firstTime).getTime() || bidInferences[bidInferences.length - 2]?.date.getTime() !== new Date(lastTime).getTime();
  const loading = dataLoading || softLoading;

  return (
    <>
      <div className='pt-1 min-[1100px]:pt-[1.625rem] min-[1100px]:h-[3.625rem] relative flex min-[1250px]:ml-[5rem] min-[1100px]:gap-[0.25rem] min-[1250px]:gap-[2rem] flex-col min-[1100px]:flex-row'>
        <div className="w-fit min-[1100px]:self-center">
          <SideSelector sides={sides} setSides={setSides} />
        </div>
        <div className="w-fit min-[1100px]:self-center">
          <PercentileToggle active={percentileActive} setActive={setPercentileActive} />
        </div>
        <TimePeriodSwitcher 
          timePeriod={timePeriod} 
          setTimePeriod={setTimePeriod} 
          bond={bond} 
          onCustomPeriodApply={(startDate, endDate) => {
            // convert start date to start of the day using luxon and end date to end of the day 
            // const _startDate = DateTime.fromMillis(startDate, ).startOf('day').toMillis();
            // const _endDate = DateTime.fromMillis(endDate).endOf('day').toMillis();
            setCustomPeriod([startDate, endDate]);
          }}
        />
      </div>

      <div className="min-h-[0px] flex-1 relative">
        {loading && (
          <LoadingOverlay />
        )}
        {!loading && (
          <Chart
            timePeriod={timePeriod}
            sides={sides}
            percentileActive={percentileActive}
            bidInferences={bidInferences}
            offerInferences={offerInferences}
            dealerInferences={dealerInferences}
            priceType={priceType}
          />
        )}
      </div>
    </>
  );
})

