import { FunctionComponent, ReactNode, useContext, useMemo } from 'react';
import { DataContext, Side, Trade } from '../data/dataProvider';
import Table, { Column } from '../components/table/table';
import Loading from '../loading';
import { PriceType } from '@/types/types';
import { FormatNumber } from '../components/formatNumber/formatNumber';
import { PriceTypeLabel, PriceTypeToQuantileKey } from './bond.constants';
import { getQuantileIndexV2 } from '../data/data.utils';

// use the browser locale settings for date formatting
const dateFormatter = new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' });

const tdCss = () => 'group-hover:bg-[#33355790] group-hover:text-[#FBFBFD]  text-[0.8125rem] text-left pr-[0.5rem] last:pr-0 sm:pr-[0.875rem] sm:last:pr-0';
const thCss = () => 'group/recent-table-th';
const header = (name: string) =>
  <div className="border-[#5D5F9D] border-b-[0.0625rem] h-full pb-[0.625rem] text-[#5D5F9D] text-[0.8125rem] text-left w-full pr-[0.5rem] group-last/recent-table-th:pr-0  sm:pr-[0.875rem] group-last/recent-table-th:sm:pr-0 ">
    {name}
  </div>;

const cell = (contents: ReactNode, css?: string) => {
  return <span className={css || ''}>{contents}</span>;
}
  
const getColor = (t: Trade) => t.side === 'B' ? 'text-[#68D358]' : t.side === 'D' ? 'text-[#F1F1F1]' : 'text-[#DE334B]';


// <RecentTrades />
const RecentTrades: FunctionComponent<{
  priceType: PriceType;
  trades: Trade[];
}> = ({ trades, priceType }) => {
  const { bidPercentile, dealerPercentile, offerPercentile } = useContext(DataContext);

  const reversedTrades = useMemo(() => {
    const reversed = [...trades];
    reversed.reverse();
    return reversed;
  }, [trades]);

  const columns: Column<Trade>[] = useMemo(() => {
    const c: Column<Trade>[] = [
      // {
      //   id: 'executed_date',
      //   Cell: t => cell(dateFormatter.format(t.execution_date), getColor(t)),
      //   Header: () => header('Executed'),
      //   tdCss,
      //   thCss,
      // },
      {
        id: 'report_date',
        Cell: t => cell(dateFormatter.format(t.report_date), getColor(t)),
        Header: () => header('Reported'),
        // tdCss: () => `${tdCss()} hidden sm:table-cell`,
        // thCss: () => `${thCss()} hidden sm:table-cell`,
        tdCss,
        thCss,
      },
      {
        id: 'quantity',
        Cell: t => cell('' + t.quantity / 1000000, getColor(t)),
        Header: () => header('Size'),
        tdCss,
        thCss,
      },
      {
        id: 'price',
        Cell: t => {
          const value = t[priceType]
          const _type = priceType === 'spread' ? 'spread_decimals' : priceType;
          const formattedValue = <FormatNumber type={_type} value={value} />
          return cell(formattedValue, getColor(t))
        },
        Header: () => header(PriceTypeLabel[priceType]),
        tdCss,
        thCss,
      },
      {
        id: 'inference',
        Cell: t => {
          const side = t.side === 'B' ? Side.bid : t.side === 'D' ? Side.dealer : Side.offer;
          const percentile = t.side === 'B' ? bidPercentile : t.side === 'D' ? dealerPercentile : offerPercentile;
          const quantileIndex = getQuantileIndexV2(percentile, side, priceType);

          const key = PriceTypeToQuantileKey[priceType];
          const _type = priceType === 'spread' ? 'spread_decimals' : priceType;
          const value = t[key][quantileIndex];
          const formattedValue = <FormatNumber type={_type} value={value} />
          return cell(formattedValue, getColor(t))
        },
        Header: () => header('Model'),
        tdCss,
        thCss,
      }
    ]

    return c;
  }, [bidPercentile, dealerPercentile, offerPercentile, priceType]);

  if (!reversedTrades || !reversedTrades.length) {
    return <Loading className="w-[16.75rem] my-[2.5rem] sm:w-[19.5rem]" />;
  }

  return (
    <Table
      columns={columns}
      generateItemKey={t => `${t.entry}`}
      items={reversedTrades}
      tableCss='lg:mr-[0.625rem]'
      tableName={'t'}
      theadCss='bg-[#0A0B11] sticky top-0 z-[2]'
      trCss={() => 'rounded-[0.375rem] hover:shadow-[0_-0.375rem_0.625rem_0_#615EFF20,0rem_0.625rem_1.25rem_0_#07011F59]'}
      defaultPageSize={100}
    />
  );
}

export default RecentTrades;
