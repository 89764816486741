import React, { KeyboardEvent, useRef, useState } from 'react'
import clsx from 'clsx'
import { useLatest, useMeasure } from 'react-use'
import { EmailList } from './components/EmailList/EmailList'
import styles from './EmailListField.module.scss'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FormValues } from '../../ShareModal.types'
import { MAX_EMAILS } from '../../ShareModal.constants'
import { AdaptiveInput } from '@/app/components/fields/AdaptiveInput/AdaptiveInput'
import { XMarkIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify'
import { isValidEmail } from '@/utils/email.utils'
import { usePasteEventContent } from '@/hooks/usePasteEventContent'
import { differenceBy, uniq } from 'lodash'
import { Tooltip } from '@/app/components/tooltip'

export const EmailListField = ({
  existingEmails
}: {
  existingEmails: string[]
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)
  const [ref, { height }] = useMeasure<HTMLDivElement>()
  const hasMoreThan1LineOfEmails = height > 46 // 46 is default height which is set in styles
  const customBlurRef = useRef(false); // set it to true when we want to blur input manually to prevent email adding multiple times on blur

  const formMethods = useFormContext<FormValues>()
  const fieldArray = useFieldArray<FormValues>({
    name: 'emails',
  })
  const { watch, formState, setError, clearErrors } = formMethods

  const hasEmails = fieldArray.fields.length > 0
  const showPlaceholder = !hasEmails && !value
  const error = formState.errors.emails?.message
  const disabled = formState.isSubmitting
  const emails = watch('emails')
  const emailsRef = useLatest(emails)
  const existingEmailsRef = useLatest(existingEmails)

  usePasteEventContent(focused, (text) => {
    const emails = text.split(/[\s,]+/).filter(Boolean).filter(isValidEmail)
    const uniqueEmails = uniq(emails)
    let newEmails = differenceBy(uniqueEmails, emailsRef.current.map((email) => email.value), v => v.toLowerCase()) // filter out emails that are already added to the input
    newEmails = differenceBy(newEmails, existingEmailsRef.current, v => v.toLowerCase()) // filter out existing emails

    if (newEmails.length > MAX_EMAILS) {
      newEmails = newEmails.slice(0, MAX_EMAILS) // limit to 20 emails
      toast.error(`You can't add more than ${MAX_EMAILS} emails at once, some of the emails are not added`);
    }

    // add new emails to the list
    newEmails.forEach((email) => {
      addEmailIfValid(email.trim())
    })

    const diff = uniqueEmails.length - newEmails.length
    if (diff > 0) {
      toast.error('Some of the emails are already added to the list or user has already been invited');
    }
  })

  function focusInput() {
    inputRef.current?.focus()
  }

  const handleContainerClick = () => {
    focusInput(); 
  }

  function handleClearClick(e: React.MouseEvent) {
    e.stopPropagation()
    formMethods.resetField('emails', {
      defaultValue: []
    })
  }

  function validateEmailsCount(count: number, showWarning = true) {
    // blurs input if max emails count is reached
    if (count >= MAX_EMAILS) {
      customBlurRef.current = true;
      inputRef.current?.blur()

      if (showWarning) {
        toast.error(`You can't add more than ${MAX_EMAILS} emails at once`);
        
      }
      return
    }
  }

  function handleInputFocus() {
    setFocused(true)
    validateEmailsCount(fieldArray.fields.length)
  }

  function addEmailIfValid(email: string) {
    if (email.trim() === '') {
      return false;
    }

    if (!isValidEmail(email)) {
      setError('emails', { message : 'Invalid email' })
      return false;
    }

    // check if email is already part of the input value
    if (emailsRef.current.some((emailObj) => emailObj.value.toLowerCase() === email.toLowerCase())) {
      setError('emails', { message : 'Seems like you have already added this email' })
      return false;
    }


    // check if email already exists
    if (existingEmails.includes(email.trim().toLowerCase())) {
      setError('emails', { message : 'Email already exists' })
      return false;
    }
    
    fieldArray.append({
      value: email,
    })
    setValue('')

    // validate emails count after adding new email
    validateEmailsCount(fieldArray.fields.length + 1, false)

    return true;
  }

  function handleInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    setFocused(false)

    if (customBlurRef.current === false) {
      const email = e.target.value.trim();
      addEmailIfValid(email);
    } else {
      customBlurRef.current = false
    }
  }

  function handleInputChange(value: string) {
    clearErrors('emails')
    setValue(value)
  }

  function handleInputKeyDown(e: KeyboardEvent) {
    if (value === '') {
      const key = e.key
      if (key === 'Backspace') {
        // delete last email if someone presses backspace and input is empty
        fieldArray.remove(fieldArray.fields.length - 1)
      } 
      return
    }

    if (value.trim() === '') {
      return
    }

    if (e.key === 'Enter') { // always prevent default for enter key if value is not empty 
      e.preventDefault();
    }

    
    if (e.key === 'Enter' || e.key === ',') { // add email if someone presses enter or coma
      const email = value.trim();
      const result = addEmailIfValid(email);

      if (result) {
        e.preventDefault();
      }
    }
  }

  const containerStyles = clsx(styles.fieldContainer, {
    [styles.hasMoreThan1LineOfEmails]: hasMoreThan1LineOfEmails,
    [styles.disabled]: disabled,
    [styles.error]: !!error,
  })

  const isNewEmailExist = existingEmails.includes(value.trim().toLowerCase());

  return (
    <>
      <div className={containerStyles} onClick={handleContainerClick} ref={ref}>

        {/* Placeholder */}
        {showPlaceholder && (
          <div className='whitespace-nowrap italic text-[#8183B3] text-[0.875rem] absolute left-2.5 my-auto pointer-events-none'>
            Enter user’s email within your organization.
          </div>
        )}

        

        {/* EmailList */}
        <EmailList 
          fieldArray={fieldArray} 
          focusInput={focusInput} 
        />

        <Tooltip 
          isOpen={Boolean(error) || isNewEmailExist} 
          tooltip={isNewEmailExist ? "Email already exists" : error} 
          maxWidthCss='max-w-[20.625rem]' 
          className='!bg-red-600' 
          delayHide={0}
          delayShow={0}
          place='top'
          style={{ transition: 'none'}}
        >
          {/* Input */}
          <AdaptiveInput
            value={value}
            type='text'
            className={styles.input}
            ref={inputRef}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            tabIndex={1}
          />
        </Tooltip>

        {/* Clear button */}
        {hasEmails && (
          <button
            type='button'
            className={styles.clearButton}
            onClick={handleClearClick}
          >
            <XMarkIcon width={20} />
          </button>
        )}
      </div>
    </>
  )
}
