import { useContext, useEffect, useState } from 'react';
import { AtsIndicator, createInferenceKey, DataContext, Inference, Side } from "./dataProvider";
import { InferenceRequestItem } from './api';
import { PriceType } from '@/types/types';

const useAllInferences = (figi: string | undefined, atsIndicator: AtsIndicator, quantity: number, side: Side, rfq_label: PriceType) => {
  const { inferences: allInferences, send } = useContext(DataContext);


  useEffect(() => {
    
    if (figi && send) {
      const requestItem: InferenceRequestItem = { figi, ats_indicator: atsIndicator, quantity, side, rfq_label }
      send({ inference: [{...requestItem, subscribe: true }]});
      return () => { send({ inference: [{ ...requestItem, unsubscribe: true}]}, false); }
    }
  }, [atsIndicator, figi, quantity, send, side, rfq_label]);

  const [inferences, setInferences] = useState<Inference[]>([]);
  useEffect(() => {
    const newInferences = (figi && allInferences[createInferenceKey(figi, atsIndicator, quantity, side, rfq_label)]) || [];

    setInferences(prev => prev.length === newInferences.length &&
      prev.reduce((a, c, i) => a &&
        (Object.getOwnPropertyNames(c) as (keyof Inference)[]).reduce((a, p) => a && c[p] === newInferences[i][p],
        true),
      true)
      ? prev : newInferences);
  }, [allInferences, atsIndicator, figi, quantity, side, rfq_label]);

  return inferences;
}

export default useAllInferences;
