import { useEffect, useState } from "react";
import { getSelectedColumnId } from "../excel.utils";

// components that will display selected excel column -> for example `A`, `B`, `C`, `AA` etc.
export const SelectedColumn = () => {
  const [column, setColumn] = useState<string | null>(null);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const columnLetter = await getSelectedColumnId();
      setColumn(columnLetter);
    }, 250)

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  return (
    <>{column || '-'}</>
  )
}