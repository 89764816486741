import { Sort, SortItem } from "./hooks/useTableSort";

export const getInitialSort = <Id extends string>(initialSort: SortItem<Id>[] | undefined, initialSortColumn: Id | undefined, initialSortDirection: Sort | undefined ) => {
  return initialSort || (
    initialSortColumn 
      ? [{ 
          id: initialSortColumn, 
          column: initialSortColumn, 
          direction: initialSortDirection || 'ascending' 
        }] 
      : []
  );
}