import clsx from "clsx"
import { useState } from "react";
import { FaPercent } from "react-icons/fa6"
import { DataItem, ProbabilityModal } from "../components/modal/probabilityModal";
import { AtsIndicator, Side } from "../data/api";
import { PriceType } from "@/types/types";
import { GetInference } from "../data/useInferences";
import { useUiMode } from "@/hooks/useUiMode";
import { CircleButton } from "../components/buttons/CircleButton";

export const BondProbabilityDetails = ({
  figi,
  quantity,
  getInference,
}: {
  figi: string,
  quantity: number,
  getInference: GetInference
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { isIgUiMode } = useUiMode();

  const data: DataItem[] = isIgUiMode
    ? [
        {
          title: 'Bid',
          side: Side.bid,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.bid, PriceType.Spread)?.spread,
          priceType: PriceType.Spread,
        },
        {
          title: 'Dealer',
          side: Side.dealer,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.dealer, PriceType.Spread)?.spread,
          priceType: PriceType.Spread,
        },
        {
          title: 'Offer',
          side: Side.offer,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.offer, PriceType.Spread)?.spread,
          priceType: PriceType.Spread,
        },
        {
          title: 'Bid YTM',
          side: Side.bid,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.bid, PriceType.Ytm)?.ytm,
          priceType: PriceType.Ytm,
        },
        {
          title: 'Bid Spread',
          side: Side.bid,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.bid, PriceType.Price)?.price,
          priceType: PriceType.Price,
        },
      ]
    : [
        {
          title: 'Bid',
          side: Side.bid,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.bid, PriceType.Price)?.price,
          priceType: PriceType.Price,
        },
        {
          title: 'Dealer',
          side: Side.dealer,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.dealer, PriceType.Price)?.price,
          priceType: PriceType.Price,
        },
        {
          title: 'Offer',
          side: Side.offer,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.offer, PriceType.Price)?.price,
          priceType: PriceType.Price,
        },
        {
          title: 'Bid YTM',
          side: Side.bid,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.bid, PriceType.Ytm)?.ytm,
          priceType: PriceType.Ytm,
        },
        {
          title: 'Bid Spread',
          side: Side.bid,
          quantiles: getInference(figi, AtsIndicator.N, quantity, Side.bid, PriceType.Spread)?.spread,
          priceType: PriceType.Spread,
        },
      ]

  return (
    <>
      <div className="h-[2.6875rem] flex items-center">
        <CircleButton
          onClick={() => setModalOpen(true)}
        >
          <FaPercent fontSize={14} />
          </CircleButton>
      </div>

      <ProbabilityModal
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        data={data}
      />
    </>
  )
}