import { getAppState } from "@/store";
import { selectAddInId } from "@/store/slices/excel.slice";

/** 
 * 
 * Use addInId to partition local storage keys so add-ins don't overwrite each other's data
 * 
 */

const getKey = (key: string) => {
  const addInId = selectAddInId(getAppState());
  const partitionKey = `deepmm-${addInId}`;
  const lsKey = `${partitionKey}-${key}`;
  return lsKey;
}

export function setInLocalStorage(key: string, value: string) {
  const _key = getKey(key);
  localStorage.setItem(_key, value);
}

export function getFromLocalStorage(key: string) {
  const _key = getKey(key);
  return localStorage.getItem(_key);
}