import { UseFieldArrayReturn } from 'react-hook-form'
import { EmailListItem } from '../EmailListItem/EmailListItem'
import { FormValues } from '@/screens/modals/ShareModal/ShareModal.types'

export const EmailList = ({
  fieldArray,
  focusInput,
}: {
  fieldArray: UseFieldArrayReturn<FormValues>
  focusInput: () => void;
}) => {
  const { fields, remove } = fieldArray

  return (
    <>
      {fields.map((field, index) => {
        return (
          <EmailListItem
            key={field.id}
            field={field}
            index={index}
            onRemove={() => {
              remove(index)
              focusInput();
            }}
          />
        )
      })}
    </>
  )
}
