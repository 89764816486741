import { TextareaHTMLAttributes, useLayoutEffect } from "react"
import { useUpdateTextAreaHeight } from "./hooks/useUpdateTextAreaHeight"
import clsx from "clsx"

export const TextareaField = ({ 
  additionalHeight,
  className,
  resizeTrigger,
  autoFocus,
  ...rest
}: TextareaHTMLAttributes<HTMLTextAreaElement> & {
  additionalHeight?: number; // height which is added to the input after height calculation
  resizeTrigger?: any; // trigger for height recalculation. You can pass some state dependency
}) => {
  const { updateTextareHeight, ref } = useUpdateTextAreaHeight({ additionalHeight, resizeTrigger })

  useLayoutEffect(() => {
    const element = ref.current;
    if (autoFocus && element) {
      ref.current?.focus();
      element.setSelectionRange(element.value.length,element.value.length);
    }
  }, [autoFocus])

  return (
    <textarea 
      ref={ref}
      onInput={updateTextareHeight}
      {...rest}
      className={clsx('w-full', className)}
    />
  )
}

