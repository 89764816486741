import { MIN_POSITION_SIZE } from "@/app/portfolio/portfolio.constants";
import { MAX_POSITION_SIZE } from "@/constants";

export const getInferencePositionSize = (size: number) => {
  if (size === 0) {
    return MIN_POSITION_SIZE;
  }

  let s = Math.min(MAX_POSITION_SIZE, Math.abs(size))
  s = s < 0 ? -s : s

  return s;
}