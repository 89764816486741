// TODO: check if this function works correctly!!
// TODO: add tests

export function throttleAsync<T>(func: (...args: any[]) => Promise<T>, delay: number) {
  let isExecuting = false;
  let lastArgs: any[] | null = null;

  return async function (...args: any[]): Promise<T | undefined> {
      // If the function is already executing, store the latest arguments and return
      if (isExecuting) {
          lastArgs = args;
          return;
      }

      isExecuting = true;
      try {
          const result = await func(...args);  // Execute the passed async function
          await new Promise(resolve => setTimeout(resolve, delay));  // Wait for the throttle delay
          isExecuting = false;

          // If there are saved arguments (from another call), call the function again with those
          if (lastArgs) {
              const nextArgs = lastArgs;
              lastArgs = null;  // Clear the lastArgs to avoid repeated calls
              return await throttleAsync(func, delay)(...nextArgs);
          }

          return result;  // Return the result of the async function
      } catch (error) {
          isExecuting = false;
          throw error;
      }
  };
}