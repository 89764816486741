import { dispatcher } from "@/store";
import { excelAddInActions } from "@/store/slices/excel.slice";
import { SettingsKey } from "../excel.constants";
import { v4 as uuidv4 } from "uuid";
import { getActiveWorksheetId } from "../excel.utils";
import { retry } from "@/utils/retry";

export const initExcelApp = () => {
  retry(initFunc, 60, 1000); // retry initFunc 60 times with 1s delay, just in case as we don't know if app is rendered in excel context or in the browser
}

const initFunc = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      Office.onReady(async ({ host }) => {

        if (host !== Office.HostType.Excel) {
          return;
        }

        let addInId: string = Office.context.document.settings.get(SettingsKey.AddInId);
        let worksheetId: string = Office.context.document.settings.get(SettingsKey.WorksheetId);
        let shouldSaveSettings = false;

        
        if (!addInId) {
          // generate unique add-in id. As excel does not have any unique id we can use to later on store data
          addInId =  uuidv4();
          Office.context.document.settings.set(SettingsKey.AddInId, addInId);
          shouldSaveSettings = true;
        }

        if (!worksheetId) {
          // we want to save worksheet id as add-in does not have a method to get worksheet where it is added (only active worksheet) -> who came up with this idea? :(
          worksheetId = await getActiveWorksheetId();
          Office.context.document.settings.set(SettingsKey.WorksheetId, worksheetId);
        }

        if (shouldSaveSettings) {
          // TODO: handle errors
          Office.context.document.settings.saveAsync(); 
        }

        // save initial data in redux store 
        dispatcher(excelAddInActions.setAddInId(addInId));
        dispatcher(excelAddInActions.setWorksheetId(worksheetId));
        dispatcher(excelAddInActions.setIsExcelAddIn(true))

        resolve(true);
      });
    } catch (error) {
      // TODO: capture exception only if env variable is correct!!!
      // captureException(error);
      resolve(false)
    }
  })
}