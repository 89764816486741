import { PriceType, UIMode, UserPreferences } from "@/types/types";
import { CustomNumericFormatProps } from "@/utils/number.utils";
import { NumericFormatProps } from "react-number-format";

// export const MIN_INFERENCES_DATE = new Date(1546300800000); // Tue Jan 01 2019 01:00:00 GMT+0100 (Central European Standard Time)
export const MIN_INFERENCES_DATE = new Date(1546470000000); // Thu Jan 03 2019 00:00:00 GMT+0100 (Central European Standard Time)

export const ROUTE = {
  Alert: 'alert',
}

export const APP_ENV = process.env.REACT_APP_ENV; // dev | prod | stage
export const APP_RELEASE = process.env.REACT_APP_RELEASE;

export const SEARCH_BAR_ID = 'search';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const PORTFOLIO_NAV_DEFAULT_VALUE = null;

export const TWO_DECIMALS: CustomNumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: true,
  prefix: '',
}

export const NAV_FORMAT: CustomNumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: true, // do we allow negative?
  prefix: '',
  parenthesis: true,
}

export const POSITION_BPS_FORMAT: CustomNumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  decimalScale: 0,
  fixedDecimalScale: true,
  allowNegative: true, // do we allow negative?
  prefix: '',
  parenthesis: true,
}

export const POSITION_VALUE_FORMAT: CustomNumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: true, // do we allow negative?
  prefix: '$ ',
  parenthesis: true,
}

export const POSITION_SIZE_FORMAT: NumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  decimalScale: 3,
  fixedDecimalScale: false,
  allowNegative: true,
  prefix: '',
  suffix: ' MM',
}

export const DEFAULT_PRICE_FORMAT: NumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  allowedDecimalSeparators: ['.', ','],
  decimalScale: 3,
  fixedDecimalScale: true,
  prefix: '$',
}

export const DEFAULT_YIELD_FORMAT: NumericFormatProps = {
  allowLeadingZeros: true,
  thousandSeparator: ",",
  decimalSeparator: '.',
  allowedDecimalSeparators: ['.', ','],
  decimalScale: 2,
  fixedDecimalScale: true,
  suffix: '%',
  prefix: '',
}

export const DEFAULT_SPREAD_FORMAT: NumericFormatProps = {
  allowLeadingZeros: false,
  fixedDecimalScale: true,
  decimalScale: 0,
  prefix: '',
}

export const NUMBER_FORMAT_MAP: Record<PriceType, NumericFormatProps> = {
  [PriceType.Price]: DEFAULT_PRICE_FORMAT,
  [PriceType.Ytm]: DEFAULT_YIELD_FORMAT,
  [PriceType.Spread]: DEFAULT_SPREAD_FORMAT,
}

export const PROBABILITY_FORMAT: NumericFormatProps = {
  decimalScale: 2,
  suffix: '%'
}

export const TOKEN_REFRESH_WINDOW = {
  earliest: 90, // 90 seconds before expiration
  latest: 30 // 30 seconds before expiration
}

export const WARN_ABOUT_MANY_ITEMS_LIMIT = 100; // if user tries to add more than this number of alerts/portfolio/runs we show a warning

// TODO: Create alert channel instead and use broadcast events instead of channels
export enum BroadcastChannelName {
  AlertUpdate = 'alert-update',
  PortfolioUpdate = 'portfolio-update',
  BondObjectUpdate = 'bond-object-update',
  UserPreferencesUpdate = 'user-preferences-update',
}

export const LocalStorageKey = {
  AertSnoozeDefaultMS: 'dmm-alert-snooze-default-ms',
  UserPreferences: 'dmm-user-preferences',
  BondsChartType: 'dmm-bonds-chart-type',
}

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;

export const MAX_POSITION_SIZE = 5_000_000; // 5 million

export const QueryParam = {
  RoundPrice: 'roundPrice',

  // filter
  FilterTenorBuckets: 'f-tb',
  FilterTicker: 'f-t',
  FilterCoupon: 'f-c',
  FilterSeries: 'f-s',
  FilterRating: 'f-r',
  FilterBid: 'f-b',
  FilterOffer: 'f-o',
  FilterBidSpread: 'f-bg',
  FilterOfferSpread: 'f-og',
  FilterBidYtm: 'f-by',
  FilterOfferYtm: 'f-oy',

  // previous
  Previous: 'previous',

  // alert
  AlertId: 'alertId',
  AlertPageSize: 'a-pageSize',

  // portfolio
  PortfolioPageSize: 'p-pageSize',

  // run
  RunPageSize: 'r-pageSize',

  // issuer
  IssuerPageSize: 'i-pageSize',
}

export const DEFAULT_UI_MODE = UIMode.HighYield; // UIMode.InvestmentGrade

// User Preferences
export const DEFAULT_USER_PREFERENCES: UserPreferences = {
  uiMode: DEFAULT_UI_MODE,
  version: undefined as any, // adding version just to match ts type
}



export const ONE_MILLION = 1_000_000;
export const ONE_THOUSAND = 1_000;

export const percentileOptions = [
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
  '65',
  '70',
  '75',
  '80',
  '85',
  '90',
  '95'
] as const;

export type Percentile = typeof percentileOptions[number];

export const SAndPRating = {
  'AAA': 22,
  'AA+': 21,
  'AA': 20,
  'AA-': 19,
  'A+': 18,
  "A+/A-1": 18, // treat `"A+/A-1"` as `"A+"`
  'A': 17,
  'A-': 16,
  'BBB+': 15,
  'BBB': 14,
  'BBB-': 13,
  'BB+': 12,
  'BB': 11,
  'BB-': 10,
  'B+': 9,
  'B': 8,
  'B-': 7,
  'CCC+': 6,
  'CCC': 5,
  'CCC-': 4,
  'CC': 3,
  'C': 2,
  'D': 1,
  'NR': 0,
  'NR/NR': 0,
}