import { openConfirmationDialogPromise } from "@/app/components/confirmationDialog/confirmationDialog.utils";
import { ReactNode } from "react";
import { SelectedColumn } from "../components/SelectedColumn";

export function showSelectColumnDialog({ 
  title, 
  content, 
  buttonText = 'Next' 
}: {
  title: string, 
  content: ReactNode, 
  buttonText?: string 
}): Promise<unknown> {
  return openConfirmationDialogPromise({
    title,
    content: <>
      {content}

      <div className="flex items-center gap-3">
        Selected column -
        <div className="font-bold text-[1.25rem] p-2 min-w-[2.5rem] text-center bg-[#5D5F9D] rounded-md text-[#FBFBFD] mx-auto">
          <SelectedColumn />
        </div>
      </div>
    </>,
    buttonText,
  })
}