import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ActionModal from "../components/modal/actionModal";
import AlertTable, { AlertTableItem, cell, getTdCssFunc, header } from "./alertsTable";
import { AlertTargetType, Side } from "../data/api";
import { AlertItem, AlertState, AlertType, useCreateAlerts } from "@data/alerts";
import { useDataContext } from "../data/dataProvider";
import { getAlertTypeLabel, isAlertTableItem, isSameAlertConfig, isTargetAlert, isVolatilityAlert, stopPropagation, waitForElement } from "./alert.utils";
import { useGetAlertInference } from "./hooks/useGetAlertInference";
import { AlertColumn, DEFAULT_VOLATILITY_CONDITION, MAX_ALERTS } from "./alert.constants";
import clsx from "clsx";
import { useAlertObject } from "./hooks/useAlertObject";
import { useAlertInferenceData } from "./hooks/useAlertInferenceData";
import { Column, TableRefData } from "@components/table/table";
import LoadingModal from "../components/modal/loadingModal";
import { useLinkedAlerts } from "./hooks/useLinkedAlerts";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useUiMode } from "@hooks/useUiMode";
import { WarningTooltip } from "../components/warningTooltip";

const ONE_MILLION = 1000000;

// columns to show in the table
const whitelistColumns = [
  AlertColumn.Link,
  AlertColumn.Cusip,
  AlertColumn.Ticker,
  AlertColumn.Size,
  AlertColumn.Side,
  AlertColumn.TargetType,
  AlertColumn.CurrentLevel,
  AlertColumn.TargetValue,
  AlertColumn.Distance,
  AlertColumn.MinProbabilityPctg,
  AlertColumn.CurrentProbability,
  AlertColumn.VolatilityCondition,
  AlertColumn.VolatilityTodayMax,
  AlertColumn.VolatilityTodayMin,
  AlertColumn.VolatilityPrevMax,
  AlertColumn.VolatilityPrevMin,
  AlertColumn.Note,
  AlertColumn.Remove,
]

const generateDefaultTableAlert = (figi: string, type: AlertType, targetType: AlertTargetType): AlertTableItem['alert'] => {
  return {
    id: uuidv4(),
    figi,
    side: type === AlertType.Volatility ? Side.dealer : Side.offer,
    size: ONE_MILLION,
    targetType,
    targetValue: undefined,
    minProbabilityPctg: 50,
    state: AlertState.Active,
    type,
    condition: DEFAULT_VOLATILITY_CONDITION,
  }
}

// <AddAlertsModal />
const AddAlertsModal = ({
  alertType,
  figisToAdd,
  onClose,
  show,
  onSuccess,
}: {
  alertType: AlertType;
  figisToAdd: Set<string>
  onClose: () => void;
  show: boolean;
  onSuccess: () => void;
}) => {
  const { getBond } = useDataContext();
  const createAlertsApi = useCreateAlerts();
  const [createLoading, setCreateLoading] = useState(false);
  const [showLimitReachedWarning, setShowLimitReachedWarning] = useState(false);
  const [linkedAlerts, setLinkedAlerts] = useLinkedAlerts([alertType, show]);
  const { uiMode, isIgUiMode } = useUiMode();

  const {
    alertList,
    alertListLoading,
    alertObjectDetails,
    alertObjectDetailsLoading,
    refetchAlertList,
    refetchAlertObjectDetails,
    loading,
  } = useAlertObject();
  const alertObject = alertList?.[0];

  const existingAlerts = alertObjectDetails?.value.alerts;
  const totalExistingAlerts = existingAlerts?.length || 0;
  const totalAlertsAfterUpdate = totalExistingAlerts + figisToAdd.size;
  const isTooManyAlerts = totalAlertsAfterUpdate > MAX_ALERTS;
  const reachedAlertsLimit = totalExistingAlerts >= MAX_ALERTS;

  const figisToAddRef = useRef(figisToAdd); // keep ref because we will also delete alerts and we need to keep the original figis
  const tableRef = useRef<TableRefData<AlertTableItem, AlertColumn> | null>(null);
  const [alerts, setAlerts] = useState<AlertTableItem[]>([]);
  const getInference = useGetAlertInference(alerts);
  const inferenceData = useAlertInferenceData(alerts, getInference);

  const alertsMap = useMemo(() => {
    if (!show) {
      return {};
    }

    return [...figisToAdd].reduce((acc, figi) => {
      const targetType = isIgUiMode ? AlertTargetType.spread : AlertTargetType.price;
      acc[figi] = generateDefaultTableAlert(figi, alertType, targetType);
      return acc
    }, {} as Record<string, AlertTableItem['alert']>)
  }, [figisToAdd, alertType, isIgUiMode, show])

  useEffect(() => {
    if (!show) {
      setAlerts([]);
    }    
  }, [uiMode, setAlerts])

  useEffect(() => {
    // make sure that this hook is before the one which calculates alerts state
    if (show) {
      figisToAddRef.current = figisToAdd;
    }
  }, [show])

  useEffect(() => {
    if (!show || !getBond) {
      return;
    }

    // reset alerts array when alert type has changed
    const currAlert = alerts[0]?.alert?.type === alertType
      ? alerts
      : [];

    // configure table items
    const tableItems = [...figisToAddRef.current].map((figi, idx) => {
      const alert = currAlert.find(a => a.alert.figi === figi)?.alert || alertsMap[figi]; // TODO: refactor if possible move to other use effect
      const bond = getBond(alert.figi);

      if (!bond) {
        return null;
      }


      const alertIndex = totalExistingAlerts + idx;
      const disabled = totalAlertsAfterUpdate > MAX_ALERTS && alertIndex >= MAX_ALERTS;

      const alertTableItem: AlertTableItem = {
        alert,
        bond,
        disabled,
        targetInvalid: false,
      };

      return alertTableItem;
    }).filter(isAlertTableItem)

    setAlerts(tableItems);
  }, [figisToAdd, alertsMap, show, getBond, alertObjectDetails, totalExistingAlerts])

  // refetch alerts when modal is opened
  useEffect(() => {
    if (!show) return;

    if (!alertObject && !alertListLoading) {
      refetchAlertList();
      return;
    }

    // refetch alert objects every time modal is opened so we have up to date data
    if (alertObject && !alertObjectDetailsLoading) {
      refetchAlertObjectDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  function validateAlert(a: AlertTableItem) {
    if (isVolatilityAlert(a.alert)) {
      return a;
    }

    return { ...a, targetInvalid: a.alert.targetValue === undefined }
  }

  // handleAlertChange
  const handleAlertChange = useCallback((alertData: Omit<Partial<AlertItem>, 'type'> & { id: string }) => {
    const { id, ...upd } = alertData; 
    // partial update of alert configuration
    setAlerts(prev => {
      return prev.map(a => {
        const shouldUpdate = linkedAlerts.size
          ? linkedAlerts.has(a.alert.id)
          : a.alert.id === alertData.id;

        if (shouldUpdate) {
          const newAlert = {
            ...a,
            alert: {
              ...a.alert,
              ...upd,
              id: a.alert.id // make sure that it's the last line so id is not overwritten
            }
          };

          // reset target value if target type has changed
          if (a.alert.targetType !== newAlert.alert.targetType) {
            newAlert.alert.targetValue = undefined;
          }

          return validateAlert(newAlert);
        }
        return a;
      })
    })
  }, [linkedAlerts])

  // handleAlertDelete
  async function handleAlertDelete(alertId: string) {
    // delete alert(s)
    let newAlerts = alerts
      .filter(a => {
        return linkedAlerts.has(alertId)
          ?  !linkedAlerts.has(a.alert.id)
          : a.alert.id !== alertId
      })

    // update disabled alerts if needed
    newAlerts = newAlerts.map((a, idx) => {
      const alertIndex = totalExistingAlerts + idx;
      const disabled = totalAlertsAfterUpdate > MAX_ALERTS && alertIndex >= MAX_ALERTS;

      return {
        ...a,
        disabled,
      }
    })

    figisToAddRef.current = new Set(newAlerts.map(a => a.alert.figi));
    setAlerts(newAlerts)

    if (linkedAlerts.has(alertId)) {
      setLinkedAlerts(new Set());
    }

    if (!newAlerts.length) {
      onClose();
    }
  }

  function filterInvalidAlerts(a: AlertTableItem) {
    if (a.disabled) {
      return false
    }

    return isVolatilityAlert(a.alert)
      ? true
      : typeof a.alert.targetValue === 'number';
  }

  async function createAlerts() {
    const _alerts = alerts
      .filter(filterInvalidAlerts)
      .map(a => a.alert as AlertItem)

    setCreateLoading(true)
    try {
      const result = await createAlertsApi(_alerts);

      if (!result) {
        setCreateLoading(false)
        return false; // keep modal open
      }

      onSuccess();
    } catch (error) {
      toast.error('Failed to create alerts')
      setCreateLoading(false)
      return false; // keep modal open
    }
  }

  const handleSubmit = (confirmed: boolean) => async () => {
    // validate data
    if (!validateAlerts()) {
      return false; // keep modal open
    }

    if (!confirmed && isTooManyAlerts) {
      setShowLimitReachedWarning(true);
      return false;  // keep modal open
    } else {
      setShowLimitReachedWarning(false)
    }

    return createAlerts();
  }


  function validateAlerts() {

    // validation for target alerts
    const invalidAlert = alerts.find(a => {
      if (isVolatilityAlert(a.alert)) {
        return false;
      }
      return !a.alert.targetValue && !a.disabled
    });

    if (invalidAlert) {
      // switch table to the page with invalid alert
      tableRef.current?.goToItem?.(invalidAlert);

      // validate all alerts and mark invalid ones
      const newAlerts = alerts.map(a => {
        return a.disabled ? a : validateAlert(a);
      });
      setAlerts(newAlerts);

      // focus first invalid alert target value
      // wait is needed to make sure that switching to the page is done
      waitForElement(`[data-target-input="${invalidAlert.alert.id}"]`, 10, 50)
        .then(input => {
          if (input) {
            (input as HTMLElement).focus();
          }
        })

      return false;
    }

    return true;
  }

  // show an error when user already has an allert for figi/side 
  const rowHasError = useCallback((item: AlertTableItem) => {
    return existingAlerts?.some(a => isSameAlertConfig(item.alert, a)) || false;
  }, [existingAlerts])

  const configureColumns = useCallback((c: Column<AlertTableItem, AlertColumn>[]) => {
    const filteredColumns = c.filter(c => whitelistColumns.includes(c.id as AlertColumn));
    const columns: Column<AlertTableItem, AlertColumn>[] = [
      // warning column
      {
        id: AlertColumn.Warning,
        Cell: (item, {selected}) => {
          return cell(
            <div className={clsx('flex gap-1', {
              'min-w-[4.5rem]': rowHasError(item) && item.disabled,
            })}>
              {rowHasError(item) && (
                <AlertWarning id={`alert-warning-same-${item.alert.id}`}>
                  {isTargetAlert(item.alert)
                    ? <>You already have an alert for this bond and side.<br /> Adding it will replace the existing alert.</>
                    : <>You already have a volatility alert for this bond.<br /> Adding it will replace the existing alert.</>
                  }
                </AlertWarning>
              )}
              {item.disabled && (
                <AlertWarning id={`alert-warning-disabled-${item.alert.id}`}>
                  You already have {totalExistingAlerts} alerts. You can add up to {MAX_ALERTS} alerts. <br />
                  This alert will not be added. Please remove some alerts to add more.
                </AlertWarning>
              )}
            </div>,
            selected
          )
        },
        Header: header(''),
        tdCss: getTdCssFunc(rowHasError, true),
        onCellClick: stopPropagation,
      },

      // rest table columns
      ...filteredColumns,
    ]

    return columns.map((c, idx) => ({ ...c, index: idx }));
  }, [rowHasError, isTooManyAlerts, totalExistingAlerts])



  const showOverwriteAlertWarning = useMemo(() => {
    return alerts.some(a => alertObjectDetails?.value.alerts.some(b => isSameAlertConfig(a.alert, b)))
  }, [alertObjectDetails, alerts])


  const title = (
    <>
      <span className="uppercase font-[500] text-[1rem] leading-[1.1875rem]">
        Add {getAlertTypeLabel(alertType)} Alerts
      </span>
      <div className="text-[0.75rem] font-[500] mt-[0.1875rem] leading-[0.875rem]">
        Enter the appropreiate information.
      </div>

      {showOverwriteAlertWarning && !loading && (
        <div className="text-[#F5416D] text-[0.75rem] font-[500] mt-[0.1875rem]">
          {alertType === AlertType.Target
            ? 'You have a bond already added to Target. Adding it will replace the existing alert for this bond.'
            : 'You already have volatiilty alert for this bond. Adding it will replace the existing alert for this bond.'
          }

        </div>
      )}
      {isTooManyAlerts && (
        <div className="text-[#F5416D] text-[0.75rem] font-[500] mt-[0.1875rem]">
          You already have {totalExistingAlerts} alerts. You can add up to {MAX_ALERTS} alerts. Some of the alerts will not be added
        </div>
      )}
    </>
  )

  const addingAlertsCount = Math.min(totalAlertsAfterUpdate, MAX_ALERTS) - totalExistingAlerts;
  const createAlertsInProgressText = (
    <>
      Adding {addingAlertsCount} {getAlertTypeLabel(alertType)} alert{addingAlertsCount > 1 ? 's' : ''}...
    </>
  )

  return (
    <>
      {/* Add alerts modal */}
      <ActionModal
        show={show}
        title={title}
        action={handleSubmit(false)}
        disabled={reachedAlertsLimit}
        actionName="Add"
        size="content"
        onClose={onClose}
        loading={loading}
        // body 
        body={
          <div className="w-full">
            <AlertTable
              alerts={alerts}
              data={inferenceData}
              onAlertChange={handleAlertChange}
              onDelete={handleAlertDelete}
              scrollerCss="!max-h-[60vh]"
              configureColumns={configureColumns}
              rowHasError={rowHasError}
              tableRef={tableRef}
              alertType={alertType}
              linkedAlerts={linkedAlerts}
              setLinkedAlerts={setLinkedAlerts}
              useSearchPagination={false}
            />
          </div>
        }
      />
      
      {/* Loading Modal */}
      <LoadingModal 
        title={createAlertsInProgressText} 
        show={createLoading} 
        zIndex="z-[1000]"  
      />

      {/* Confirmation Modal */}
      <ActionModal
        show={showLimitReachedWarning}
        title="Too many alerts"
        action={handleSubmit(true)}
        actionName="Continue"
        size="content"
        onClose={() => setShowLimitReachedWarning(false)}
        body={(
          <>
            You already have {totalExistingAlerts} alerts. You can add up to {MAX_ALERTS} alerts. 
            <br />
            {totalAlertsAfterUpdate - MAX_ALERTS} alert(s) will not be added.
            
             <br /> <br />
            Do you want to proceed?
          </>
        )}
      />
    </>
  )
}

// <AlertWarning />
function AlertWarning({ children, id }: { children: React.ReactNode, id: string }) {
  return (
    <WarningTooltip
      className="text-[#FB275a] flex-[0_0_1.5rem]"
      id={id}
      children={children}
    />
  )
}

export default AddAlertsModal;