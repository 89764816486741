import { memo } from "react";
import { NumericFormat } from "react-number-format";

type Props = {
  value: string | number | null | undefined;
  className?: string,
  decimalScale?: number
}

export const FormattedSpread = (props: Props) => {
  return (
    <Format {...props} />
  )
}

const Format = memo(({
  value,
  className,
  decimalScale = 0
}: Props) => {
  return (
    <NumericFormat
      allowLeadingZeros={false}
      value={value}
      displayType='text'
      defaultValue='-'
      thousandSeparator=","
      decimalScale={decimalScale}
      className={className}
    />
  )
})