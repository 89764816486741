import { Bond, spreadOptions, priceOptions, tenorBucketsOptions, ytmOptions } from "@/app/data/dataProvider";
import { QueryParam } from "@/constants";
import notUndefined from "@/utils/notUndefined";
import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import { DateTime } from "luxon";
import { useFilterOptions } from "./useFilterOptions";

// provides filters values from search params and allows to set/clear them
// single value     &param1=1&param2=2
// multi value      &param1=1,2,3&parm2=1,2
// range value      &param1=1-2&param2=1-2,3-4
export const useFilters = <T extends Bond>(bonds: T[]) => {
  const [, setSearchParams] = useSearchParams();

  const { 
    tickerOptions, 
    couponOptions, 
    seriesOptions,
    ratingOptions,
  } = useFilterOptions(bonds)

  const getArrayValueFromParam = useCallback((paramValue: string) => paramValue.split('-').map(parseFloat), []); // transforms range value from query param `80-90` to [80, 90]

  // TODO: combine all filter states into one state if it will not decrease performance
  
  // ticker
  const [tickerFilter, setTickerFilter] = useMultiValueParam(QueryParam.FilterTicker, tickerOptions);

  // tenor buckets
  const [tenorFilter, setTenorFilter] = useMultiValueParam(QueryParam.FilterTenorBuckets, tenorBucketsOptions, getArrayValueFromParam);

  // coupon
  const [couponFilter, setCouponFilter] = useMultiValueParam(QueryParam.FilterCoupon, couponOptions, parseFloat);

  // series
  const [seriesFilter, setSeriesFilter] = useMultiValueParam(QueryParam.FilterSeries, seriesOptions);

  // rating
  const [ratingFilter, setRatingFilter] = useMultiValueParam(QueryParam.FilterRating, ratingOptions);

  // bid buckets
  const [bidFilter, setBidFilter] = useMultiValueParam(QueryParam.FilterBid, priceOptions, getArrayValueFromParam);

  // offer buckets
  const [offerFilter, setOfferFilter] = useMultiValueParam(QueryParam.FilterOffer, priceOptions, getArrayValueFromParam);

  // bid spread
  const [bidSpreadFilter, setBidSpreadFilter] = useMultiValueParam(QueryParam.FilterBidSpread, spreadOptions, getArrayValueFromParam);

  // offer spread
  const [offerSpreadFilter, setOfferSpreadFilter] = useMultiValueParam(QueryParam.FilterOfferSpread, spreadOptions, getArrayValueFromParam);

  // bid ytm
  const [bidYtmFilter, setBidYtmFilter] = useMultiValueParam(QueryParam.FilterBidYtm, ytmOptions, getArrayValueFromParam);

  // offer ytm
  const [offerYtmFilter, setOfferYtmFilter] = useMultiValueParam(QueryParam.FilterOfferYtm, ytmOptions, getArrayValueFromParam);

  // previous 
  const { previousMs, setPreviousMs, getFormattedPreviousDate } = usePreviousMs();

  // clear all filters
  const clearAll = useCallback(() => {
    setSearchParams(prev => {
      // Delete all filters from the search params
      [
        QueryParam.FilterTenorBuckets,
        QueryParam.FilterTicker,
        QueryParam.FilterCoupon,
        QueryParam.FilterSeries,
        QueryParam.FilterRating,
        QueryParam.FilterBid,
        QueryParam.FilterOffer,
        QueryParam.FilterBidSpread,
        QueryParam.FilterOfferSpread,
        QueryParam.FilterBidYtm,
        QueryParam.FilterOfferYtm,
        QueryParam.Previous,
      ]
      .forEach(p => prev.delete(p));

      return prev;
    })
  }, [setSearchParams])

  const hasActiveFilters = [tenorFilter, tickerFilter, couponFilter, seriesFilter, bidFilter, offerFilter, bidSpreadFilter, offerSpreadFilter, bidYtmFilter, offerYtmFilter, ratingFilter].some(f => f.length > 0) || previousMs !== null;

  const filtersMemo = useMemo(() => ({
    hasActiveFilters,
    clearAll,

    tickerFilter,
    tickerOptions,
    setTickerFilter,

    tenorFilter,
    tenorBucketsOptions,
    setTenorFilter,

    couponOptions,
    couponFilter,
    setCouponFilter,
     
    seriesOptions,
    seriesFilter,
    setSeriesFilter,
    
    ratingOptions,
    ratingFilter,
    setRatingFilter,

    bidFilter,
    setBidFilter,

    offerFilter,
    setOfferFilter,

    bidSpreadFilter,
    setBidSpreadFilter,

    offerSpreadFilter,
    setOfferSpreadFilter,

    bidYtmFilter,
    setBidYtmFilter,

    offerYtmFilter,
    setOfferYtmFilter,

    previousMs,
    setPreviousMs,
    getFormattedPreviousDate,

    values: {
      ticker: tickerFilter,
      tenor: tenorFilter,
      coupon: couponFilter,
      series: seriesFilter,
      bid: bidFilter,
      offer: offerFilter,
      bidSpread: bidSpreadFilter,
      offerSpread: offerSpreadFilter,
      bidYtm: bidYtmFilter,
      offerYtm: offerYtmFilter,
      rating: ratingFilter,
    }
  }), [
    hasActiveFilters,
    clearAll,

    tickerFilter,
    tickerOptions,
    setTickerFilter,

    tenorFilter,
    setTenorFilter,

    couponOptions,
    couponFilter,
    setCouponFilter,
     
    seriesOptions,
    seriesFilter,
    setSeriesFilter,

    ratingOptions,
    ratingFilter,
    setRatingFilter,

    bidFilter,
    setBidFilter,

    offerFilter,
    setOfferFilter,

    bidSpreadFilter,
    setBidSpreadFilter,

    offerSpreadFilter,
    setOfferSpreadFilter,

    bidYtmFilter,
    setBidYtmFilter,

    offerYtmFilter,
    setOfferYtmFilter,

    previousMs,
    setPreviousMs,
    getFormattedPreviousDate,
  ]);

  return filtersMemo;
}

// provides `previous` filter value from search params
export const usePreviousMs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const previousParam = searchParams.get(QueryParam.Previous);
  const previousMs = previousParam ? +previousParam : null;

  const setPreviousMs = useCallback((ms: number | null) => {
    setSearchParams(prev => {
      if (ms) {
        prev.set(QueryParam.Previous, encodeURIComponent(ms));
      } else {
        prev.delete(QueryParam.Previous);
      }
      return prev;
    })
  }, [setSearchParams]);

  const getFormattedPreviousDate = useCallback(() => {
    if (!previousMs) {
      return 'EoD';
    }
    return DateTime.fromMillis(previousMs, { zone: 'America/New_York' }).toLocaleString({ year: '2-digit', month: '2-digit', day: '2-digit' });
  }, [previousMs])

  return {
    previousMs,
    setPreviousMs,
    getFormattedPreviousDate,
  }
}

// filter option type
export type Option<T = unknown> = {
  value: T;
  label: string
} 

type MaybeOption<T = unknown> = Option<T> | undefined | null

// provides values of one filter (for example QueryParam.FilterTicker) from search params and setter for this filter param
// supports string, string[], number, number[] 
const useMultiValueParam = <T>(
  paramName: string, 
  options: Option<T>[], 
  getValue?: (paramValue: string) => T,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const param = searchParams.get(paramName) || ''
  const value = useMemo(() => {
    const values = param.split(',').map(v => {
      if (getValue) {
        return getValue(v);
      }
      return v;
    });
    return values
      .map(v => options.find(o => isEqual(o.value, v)))
      .filter(notUndefined) as Option<T>[];
  }, [param, options, getValue,])

  const setValue = useCallback((newValue: MultiValue<MaybeOption<T>> | SingleValue<MaybeOption<T>>) => {
    setSearchParams(prev => {
      const v = Array.isArray(newValue) ? newValue : [newValue];

      const newSearchParm = v
        .map(v => {
          if (!v) return undefined;
          if (Array.isArray(v.value)) {
            return v.value.join('-');
          }
          return v.value;;
        })
        .filter(notUndefined)
        .join(',');

      if (!newSearchParm) {
        prev.delete(paramName);
      } else {
        prev.set(paramName, newSearchParm);
      }

      return prev;
    })
  }, [setSearchParams, paramName])

  return [value, setValue] as const;
}

// checks if value matches selected filter type (exact value)
export const filterByExactValue = <T>(filters: Option<T>[], value: T) => {
  if (filters.length === 0) { // filter is not applied as it's empty
    return true;
  }

  if (typeof value === 'number') {
    return filters.some(f => f.value === value);
  } else if (typeof value === 'string') {
    const lcValue = value.toLowerCase();
    return filters.some(f => (f.value as string).toLowerCase() === lcValue);
  } else  {
    return filters.some(f => isEqual(f.value, value));
  }
}

// checks if value in inside of selected range filter
export const filterByRange = (filters: Option<number[]>[], value: number | undefined) => {
  if (filters.length === 0) { // filter is not applied as it's empty
    return true;
  }

  if (typeof value !== 'number') {
    return false;
  }

  return filters.some(({ value: [min, max = Infinity ]}) => value >= min && value < max);
}

// filter by range [min, max] - min exclusive, max inclusive. Min/Max - years -> [3, 5] in 3 to 5 years
export const filterByMaturity = (filters: Option<number[]>[], maturity: string) => {
  if (filters.length === 0) { // filter is not applied as it's empty
    return true;
  }

  if (typeof maturity !== 'string') {
    return false;
  }

  const maturityTime = new Date(maturity).getTime();
  // TODO: add memoization here
  const normalizedFilters = filters.map(f => ({
    min: DateTime.now().plus({ years: f.value[0] }).toMillis(),
    max: f.value[1] ? DateTime.now().plus({ years: f.value[1] }).toMillis() : Infinity,
  }))

  return normalizedFilters.some(({ min, max }) => maturityTime > min && maturityTime <= max);
}