import { AlertTableItem } from "../../alertsTable";
import { AlertState } from "../../../data/alerts";
import { useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { useSyncAlerts } from "../hooks/useSyncAlerts";
import { AlertSnoozeButton } from "./alertSnoozeButton";
import { isTargetAlert, isVolatilityAlert } from "../../alert.utils";
import { FaArrowUp} from 'react-icons/fa6'
import { useSelector } from "react-redux";
import { selectAlertInferenceData } from "../../../../store/slices/alert.slice";
import clsx from "clsx";
import { FormattedPrice } from "@/app/components/formatNumber/formattedPrice";
import { FormatNumber } from "@/app/components/formatNumber/formatNumber";
import { AlertTargetTypeToPriceType } from "../../alert.constants";

export function AlertToast({
  alert,
  triggeredTimeMs,
}: {
  alert: AlertTableItem;
  triggeredTimeMs: number;
}) {
  const { updateAlert } = useSyncAlerts();
  const inferenceData = useSelector(selectAlertInferenceData(alert.alert.id));

  function handleDisableAlert(e: React.MouseEvent) {
    e.stopPropagation();

    updateAlert({
      id: alert.alert.id,
      state: AlertState.Disabled,
    })
  }

  const handleSnoozeAlert = useCallback((durationMs: number) => {
    updateAlert({
      id: alert.alert.id,
      snoozedUntil: Date.now() + durationMs,
    })
  }, [alert.alert.id, updateAlert])

  // date
  const date = useMemo(() => {
    return DateTime.fromMillis(triggeredTimeMs).toLocaleString({ month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
  }, [triggeredTimeMs])

  const priceType = AlertTargetTypeToPriceType[alert.alert.targetType];
  const colorStyles = isTargetAlert(alert.alert) 
    ? 'text-[#ffeb3b]' 
    : inferenceData?.isGapUp
      ? 'text-[#7FB247]'
      : 'text-[#FB275A]'

  return (
    <div className="bg-[#0A0B11] font-medium flex items-center flex-col sm:flex-row">

      <div className="flex gap-2.5 whitespace-nowrap sm:mr-[2rem] flex-1">
        <span>{date}</span>

        <span className={clsx('cursor-pointer', colorStyles)}>{alert.bond.cusip}</span>
        {isTargetAlert(alert.alert) && (
          <span className={clsx('flex items-center ml-auto', colorStyles)}>
            <FormatNumber type={priceType} value={inferenceData?.current} className="min-w-[4.875rem] text-right" />
          </span>
        )}

        {isVolatilityAlert(alert.alert) && (
          <span className='ml-auto'>
            <FaArrowUp
              className={clsx(colorStyles, 'text-[1rem]', {
                'rotate-180': inferenceData?.isGapDown,
              })}
            />
          </span>
        )}
      </div>

      <div className="sm:ml-auto flex items-center sm:pr-2.5 gap-2 w-full sm:w-auto justify-between sm:justify-normal">
        <button type="button" className="p-2.5 text-[#FB275A] hover:text-[#ff4c76]" onClick={handleDisableAlert}>Disable</button>
        <AlertSnoozeButton onSnooze={handleSnoozeAlert} />
      </div>
    </div>
  )
}

